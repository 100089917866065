import GenericCard from "../../Cards/GenericCard";
import { Stack, Carousel } from "react-bootstrap";
import { getBaseUrl } from "../../../config/environment";
import Loader from "../../Loader/Loader";
import ImageSvgs from "../../ImageSvgs";
import OwnerProfile from "../../CommonComponents/OwnerProfile";
import { getSharePointUrl } from "../../../config/environment";
import "./FinergyLearningSession.scss";

const FinergyLearningSession = (props) => {
  const tzLookup = require("tz-lookup");
  const { DateTime } = require("luxon");

  const footer = (owner) => {
    return (
      owner?.length > 0 && (
        <div className="my-auto pt-2">
          <OwnerProfile owner={owner?.[0]} isOwnerHasLink={true} />
        </div>
      )
    );
  };

  const handleCardClick = (urlId) => {
    const url = `${getSharePointUrl()}/sites/Finergy_Academy_Networking/_layouts/15/Event.aspx?ItemId=${urlId}`;
    window.open(url, "_blank");
  };

  function removeHtmlTags(data) {
    if (data != null) {
      return data.replace(/<\/?[^>]+(>|$)/g, "");
    } else {
      return <div style={{ height: "10px" }}></div>;
    }
  }

  const courseDetails = (start, end) => {
    const locationData = JSON.parse(localStorage.getItem("userData"));

    const coordinates =
      locationData?.location &&
      locationData?.location?.data.attributes?.["office-gps"];

    let localStart,
      localEnd,
      durationMinutes = "-";

    if (coordinates) {
      const [latitude, longitude] = coordinates
        .split(";")
        .map((part) => part.trim());

      const latValue = parseFloat(latitude.split(" ")[1]);
      const lonValue = parseFloat(longitude.split(" ")[1]);

      const lat = latitude.startsWith("N") ? latValue : -latValue;
      const long = longitude.startsWith("E") ? lonValue : -lonValue;

      const timeZone = tzLookup(lat?.toString(), long?.toString());

      const [date, time] = start.split("T");
      const [endDate, endTime] = end.split("T");

      const startDateTime = new Date(`${date}T${time}`);
      const endDateTime = new Date(`${endDate}T${endTime}`);
      localStart = DateTime.fromJSDate(startDateTime).setZone(timeZone);
      localEnd = DateTime.fromJSDate(endDateTime).setZone(timeZone);

      const durationMs = localEnd - localStart;
      durationMinutes = durationMs / (1000 * 60);
    }

    return (
      <div className="d-flex flex-column course-details-container">
        <div className="text-muted session-details">
          Start Date & Time:{" "}
          {localStart ? localStart.toFormat("yyyy-LL-dd & HH:mm") : "-"}
        </div>
        <span className="text-muted session-details">
          Duration: {durationMinutes !== "-" ? `${durationMinutes} mins` : "-"}
        </span>
      </div>
    );
  };

  return (
    <>
      <div className="d-flex fs-4 fw-bold pb-3">{props.title}</div>

      <div className="newscomp-carousel banner-carousel" id="news-carousel">
        {props?.data?.length === 0 || props?.data === undefined ? (
          <div className="carousel-loader ">
            <Loader />
          </div>
        ) : props?.data.length === 0 ? (
          <div className="d-flex justify-content-center pt-2">
            <ImageSvgs name="no_data" />
          </div>
        ) : (
          <Carousel
            interval={null}
            wrap={false}
            controls={false}
            className="learning-container"
          >
            {props?.data?.map((group, i) => (
              <Carousel.Item key={i}>
                <Stack
                  direction="horizontal"
                  gap={2}
                  className="carousel-item-stack w-100"
                >
                  {group?.map((record, j) => (
                    <GenericCard
                      key={j}
                      header={record?.Title}
                      tags={courseDetails(record?.EventDate, record.EndDate)}
                      description={removeHtmlTags(record?.Description)}
                      overlayTriggers={{
                        header: record?.Title,
                        description: removeHtmlTags(record?.Description),
                      }}
                      imageSrc={
                        record?.BannerImageUrl
                          ? record?.BannerImageUrl
                          : `${getBaseUrl()}/images/default-images/default-news.jpg`
                      }
                      footer={footer(
                        record?.Author?.["db_data"] !== null
                          ? record?.Author?.["db_data"]?.data
                          : null
                      )}
                      onClick={() => handleCardClick(record?.Id)}
                    />
                  ))}
                </Stack>
              </Carousel.Item>
            ))}
          </Carousel>
        )}
      </div>
    </>
  );
};

export default FinergyLearningSession;
