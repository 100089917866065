import React from "react";
import { Modal, Button } from "react-bootstrap";

const FinergyOverviewModal = ({ show, onHide, data }) => (
  <Modal show={show} onHide={onHide} centered size="lg">
    <Modal.Header closeButton>
      <Modal.Title>FINergy Academy Introduction</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {data?.split("\n").map((line, index) => (
        <p key={index}>{line}</p>
      ))}
    </Modal.Body>
    <Modal.Footer>
      <Button
        variant="secondary"
        className="app-secondary-btn"
        onClick={onHide}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
);

export default FinergyOverviewModal;
