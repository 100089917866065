import React from "react";
import { useEffect, useState } from "react";
import { MultipleSelectDropdown } from "../CommonComponents/MultipleSelect";
import { Carousel, Stack } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { chunk } from "lodash";
import { getData } from "../../services/apiService";
import { getBaseUrl } from "../../config/environment";
import { getHostUrl } from "../../config/environment";
import { patchData } from "../../services/apiService";
import { nextArrow, prevArrow } from "../../utils/iconUtils";
import "./YourLunch.scss";
import { defaultUserConfig } from "../../config/Constants";

const MainLunchComp = ({ fullSize }) => {
  const colorOptions = [
    {
      head: "rgb(5, 55, 30, 1)",
      prim: "rgb(5, 55, 30, 0.2)",
      sec: "rgb(5, 55, 30, 0.4)",
    },
    {
      head: "rgb(39, 182, 109 , 1)",
      prim: "rgb(39, 182, 109, 0.2)",
      sec: "rgb(39, 182, 109, 0.4)",
    },
    {
      head: "rgb(198, 174, 0 , 1)",
      prim: "rgb(198, 174, 0, 0.2)",
      sec: "rgb(198, 174, 0, 0.4)",
    },
    {
      head: "rgb(167, 7, 15, 1)",
      prim: "rgb(167, 7, 15, 0.2)",
      sec: "rgb(167, 7, 15, 0.4)",
    },
    {
      head: "rgb(38, 44, 48, 1)",
      prim: "rgb(38, 44, 48, 0.2)",
      sec: "rgb(38, 44, 48, 0.4)",
    },
    {
      head: "rgb(105, 83, 83, 1)",
      prim: "rgb(105, 83, 83, 0.2)",
      sec: "rgb(105, 83, 83, 0.4)",
    },
  ];

  const [dropOptions, setDropOptions] = useState([]);
  const [currentDay, setCurrentDay] = useState();
  const [location, setLocation] = useState();
  const [loading, setLoading] = useState();
  const [menuData, setMenuData] = useState();
  const [showBtn, setShowBtn] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  let curr = new Date();
  let week = [];

  const options = {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  for (let i = 1; i <= 7; i++) {
    let first = curr.getDate() - (curr.getDay() + 1) + i;
    let day = new Date(curr.setDate(first));
    week.push({ id: `${i - 1}`, day: day });
  }

  const handleLocationChange = (value) => {
    const tempDate = new Date();
    let selectedVal = location?.[0]?.name;

    // eslint-disable-next-line
    value?.filter((options) => {
      if (selectedVal?.toString() === options?.name?.toString()) {
        setShowBtn(false);
      } else {
        setShowBtn(true);
        setLocation(value);
      }
    });

    week.map((elem) => {
      if (
        elem.day.toLocaleDateString().toString() ===
        tempDate.toLocaleDateString().toString()
      ) {
        setCurrentDay(elem);
      }
      return null;
    });
  };

  useEffect(() => {
    (async () => {
      const tempDate = new Date();
      let headers = { "Cache-Control": "no-cache" };
      const res1 = await getData(
        `${getBaseUrl()}/lunchdata/lunchcatering.json`,
        "idToken",
        headers
      );

      const res2 = await getData(
        `${getBaseUrl()}/lunchdata/lunchshops.json`,
        "idToken",
        headers
      );
      const lunchRes = JSON.parse(localStorage.getItem("userConfig"));
      const lunchDataRes = lunchRes?.lunchData;
      const tempLunchData = res2?.data
        .map((elem) => {
          return {
            location_name: elem.location_name.toUpperCase(),
            data: elem.data,
          };
        })
        .concat(res1?.data)
        .map((elem, index) => {
          return {
            id: index + 1,
            name: decodeURI(elem.location_name),
            data: elem.data,
          };
        });

      // eslint-disable-next-line
      const tempLunchVal = tempLunchData?.filter((options, index) => {
        let selectedVal = lunchDataRes;
        if (selectedVal?.length > 0) {
          if (selectedVal?.toString() === options["name"]?.toString()) {
            return {
              id: parseInt(options.id),
              name: options.name,
              data: options.data,
            };
          }
        } else {
          return (
            index === 0 && {
              id: parseInt(options?.id),
              name: options?.name,
              data: options.data,
            }
          );
        }
      });

      setDropOptions(tempLunchData);
      setLocation(tempLunchVal);

      week.map((elem) => {
        if (
          elem.day.toLocaleDateString().toString() ===
          tempDate.toLocaleDateString().toString()
        ) {
          setCurrentDay(elem);
        }
        return null;
      });
    })();
    // eslint-disable-next-line
  }, [localStorage.getItem("userConfig")]);

  useEffect(() => {
    if (menuData && menuData.length > 0) {
      setActiveIndex(0);
    }
  }, [menuData]);

  useEffect(() => {
    (async () => {
      if (location?.length > 0 && typeof location[0]?.data === "object") {
        let tempList = [];
        if (location?.length > 0 && location[0]?.data?.length > 0) {
          location[0]?.data?.map((elem) => {
            tempList.push(
              elem?.menu_data?.filter(
                (item) =>
                  new Date(item.date).toLocaleDateString().toString() ===
                  currentDay.day.toLocaleDateString().toString()
              )[0].day_wise_menu
            );
            return false;
          });
        }
        setMenuData(
          location?.length > 0 && location[0]?.data?.length > 0
            ? chunk(tempList.flat(1), fullSize ? 6 : 3)
            : []
        );
      } else {
        setMenuData(location[0]?.data);
      }
    })();
    // eslint-disable-next-line
  }, [currentDay, location]);

  const rowValue = JSON.parse(localStorage.getItem("userConfig"));
  const userId = JSON.parse(localStorage.getItem("userData"));

  const payLoad = {
    data: {
      type: "user",
      id: userId.userId,
      attributes: {
        configuration: {
          row: rowValue?.row
            ? rowValue?.row
            : defaultUserConfig?.configuration?.row,
          homeConfig: rowValue?.homeConfig
            ? rowValue?.homeConfig
            : defaultUserConfig?.configuration?.homeConfig,
          lunchData: location?.[0]?.name
            ? location[0]?.name
            : defaultUserConfig?.configuration?.lunchData,
          sacLinks: rowValue?.sacLinks
            ? rowValue?.sacLinks
            : defaultUserConfig?.configuration?.sacLinks,
        },
      },
    },
  };

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  const saveLunchData = async (value) => {
    setLoading(true);
    const url = `${getHostUrl()}user/config`;
    const jsonObject = JSON.stringify(value.data.attributes.configuration);

    try {
      const homeConfig = await patchData(url, value);
      localStorage.setItem("userConfig", jsonObject);
      if (homeConfig.status === 200) {
        setLoading(false);
        setShowBtn(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="w-100 main-lunch-comp">
      <div
        className="d-flex flex-column mt-3 mb-3"
        style={{ marginLeft: "1rem", marginRight: "1rem" }}>
        {fullSize ? (
          <div className="d-flex w-100 justify-content-between mb-2">
            <div>
              <h4 className="fw-bold" style={{ color: "#1E1E1E" }}>
                Your Lunch
              </h4>
            </div>
            <div
              className="d-flex fw-bold justify-content-center"
              style={{ width: "23%" }}>
              <button
                className="border-0 bg-transparent mx-1 my-auto fw-bold"
                style={{ color: "#8A00E5" }}
                disabled={
                  currentDay?.id === "0" ||
                  currentDay?.id === "1" ||
                  currentDay?.id === "6" ||
                  currentDay?.day.toDateString() === new Date().toDateString()
                    ? true
                    : false
                }
                onClick={() => {
                  setCurrentDay(week[parseInt(currentDay?.id) - 1]);
                }}>
                {prevArrow}
              </button>
              <p className="my-auto fw-bold" style={{ color: "#8A00E5" }}>
                {currentDay
                  ? `${currentDay?.day.toLocaleDateString("en-us", options)}`
                  : "null"}
              </p>
              <button
                className="border-0 bg-transparent mx-1 my-auto fw-bold"
                style={{ color: "#8A00E5" }}
                disabled={
                  currentDay?.id === "0" ||
                  currentDay?.id === "5" ||
                  currentDay?.id === "6"
                    ? true
                    : false
                }
                onClick={() => {
                  setCurrentDay(week[parseInt(currentDay?.id) + 1]);
                }}>
                {nextArrow}
              </button>
            </div>

            <div className="d-flex flex-row">
              {showBtn && (
                <div style={{ marginRight: "1rem" }}>
                  <Button
                    className="app-primary-btn"
                    onClick={() => saveLunchData(payLoad)}>
                    {loading ? "Saving..." : "Save"}
                  </Button>
                </div>
              )}
              <div
                className="app-primary-dropdown"
                style={{ width: "16.5rem", marginTop: "7px" }}>
                <MultipleSelectDropdown
                  disabled={
                    currentDay?.id === "0" || currentDay?.id === "6"
                      ? true
                      : false
                  }
                  multi={false}
                  style={{ width: "16rem" }}
                  color="#8A00E5"
                  placeholder="Select Location..."
                  className="dropdown-select mb-2"
                  keepSelectedInList={true}
                  options={
                    dropOptions &&
                    dropOptions
                      ?.sort((a, b) => a.name.localeCompare(b.name))
                      ?.map((elem) => ({
                        id: elem.id,
                        name: elem.name,
                        data: elem.data,
                      }))
                  }
                  onChange={handleLocationChange}
                  values={location?.length > 0 && location}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="w-100 d-flex flex-column">
            <div className="d-flex w-100 justify-content-between mb-0">
              <div>
                <h4 className="fw-bold" style={{ color: "#1E1E1E" }}>
                  Your Lunch
                </h4>
              </div>

              <div className="d-flex flex-row">
                {showBtn && (
                  <div style={{ marginRight: "1rem" }}>
                    <Button
                      className="app-primary-btn"
                      onClick={() => saveLunchData(payLoad)}>
                      {loading ? "Saving..." : "Save"}
                    </Button>
                  </div>
                )}
                <div
                  className="app-primary-dropdown"
                  style={{ width: "16.5rem", marginTop: "7px" }}>
                  <MultipleSelectDropdown
                    disabled={
                      currentDay?.id === "0" || currentDay?.id === "6"
                        ? true
                        : false
                    }
                    multi={false}
                    color="#8A00E5"
                    placeholder="Select Location..."
                    className="dropdown-select mb-2"
                    keepSelectedInList={true}
                    options={
                      dropOptions &&
                      dropOptions
                        ?.sort((a, b) => a.name.localeCompare(b.name))
                        ?.map((elem) => ({
                          id: elem.id,
                          name: elem.name,
                          data: elem.data,
                        }))
                    }
                    onChange={handleLocationChange}
                    values={location?.length > 0 && location}
                  />
                </div>
              </div>
            </div>
            <div
              className="d-flex fw-bold d-flex justify-content-between mb-2"
              style={{ width: "45%" }}>
              <button
                className="border-0 bg-transparent mx-1 my-auto fw-bold"
                style={{ color: "#8A00E5" }}
                disabled={
                  currentDay?.id === "0" ||
                  currentDay?.id === "1" ||
                  currentDay?.id === "6" ||
                  currentDay?.day.toDateString() === new Date().toDateString()
                    ? true
                    : false
                }
                onClick={() => {
                  setCurrentDay(week[parseInt(currentDay?.id) - 1]);
                }}>
                {prevArrow}
              </button>
              <p
                className="mb-0 mt-1 fw-bold"
                style={{
                  color: "#8A00E5",
                  fontWeight: "500",
                  fontSize: "18px ",
                }}>
                {currentDay
                  ? `${currentDay?.day.toLocaleDateString("en-us", options)}`
                  : "null"}
              </p>
              <button
                className="border-0 bg-transparent mx-1 my-auto fw-bold"
                style={{ color: "#8A00E5" }}
                disabled={
                  currentDay?.id === "0" ||
                  currentDay?.id === "5" ||
                  currentDay?.id === "6"
                    ? true
                    : false
                }
                onClick={() => {
                  setCurrentDay(week[parseInt(currentDay?.id) + 1]);
                }}>
                {nextArrow}
              </button>
            </div>
          </div>
        )}
        {menuData ? (
          <>
            {menuData && typeof menuData === "object" ? (
              <>
                {menuData?.length > 0 ? (
                  <div className="w-100 d-flex banner-carousel lunch-carousel">
                    <Carousel
                      className="w-100"
                      interval={null}
                      wrap={false}
                      controls={false}
                      activeIndex={activeIndex}
                      onSelect={handleSelect}>
                      {menuData?.map((record, i) => (
                        <Carousel.Item key={i}>
                          <Stack
                            direction="horizontal"
                            className="h-100 align-items-center"
                            gap={2}>
                            {record?.map((elem, index) => (
                              <div
                                className="d-flex flex-column"
                                style={{
                                  height: "22rem",
                                  borderRadius: "5px",
                                  width: fullSize ? "16.5%" : "33.3%",
                                }}>
                                <div
                                  className="p-2"
                                  style={{
                                    height: "18rem",
                                    borderTopRightRadius: "5px",
                                    borderTopLeftRadius: "5px",
                                    backgroundColor: `${colorOptions[index].prim}`,
                                  }}>
                                  <p
                                    className="fw-bold mb-1"
                                    style={{
                                      color: `${colorOptions[index].head}`,
                                    }}>
                                    {elem.items_headers}
                                  </p>
                                  <div style={{ height: "15.5rem" }}>
                                    {" "}
                                    {elem.products_name.map(
                                      (product, index) => (
                                        <p className="mb-0">
                                          - {elem.products_name[index]}
                                        </p>
                                      )
                                    )}
                                  </div>
                                </div>
                                <div
                                  className="p-2"
                                  style={{
                                    height: "4rem",
                                    borderBottomRightRadius: "5px",
                                    borderBottomLeftRadius: "5px",
                                    backgroundColor: `${colorOptions[index].sec}`,
                                  }}>
                                  <span className="d-flex mt-1">
                                    <p className="me-2 mb-0">Internal:</p>
                                    <p className="mb-0 fw-bold">
                                      {elem.products_price[0]}
                                    </p>
                                  </span>
                                  <span className="d-flex">
                                    <p className="me-2 mb-0">External:</p>
                                    <p className="mb-0 fw-bold">
                                      {elem.products_price[1]}
                                    </p>
                                  </span>
                                </div>
                              </div>
                            ))}
                          </Stack>
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </div>
                ) : (
                  <div
                    className="d-flex border w-100"
                    style={{ height: "22rem" }}>
                    <p className="my-auto mx-auto fw-bold fs-6">
                      No data available.
                    </p>
                  </div>
                )}
              </>
            ) : (
              <div
                className="d-flex border w-100"
                style={{ height: "22.5rem" }}>
                <p className="d-flex my-auto mx-auto fw-bold fs-6">
                  <p className="mb-0 me-1">Visit link:</p>
                  <a
                    href={menuData}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#8a00e5" }}>
                    {menuData}
                  </a>
                </p>
              </div>
            )}
          </>
        ) : (
          <div className="d-flex border w-100" style={{ height: "22.5rem" }}>
            <p className="my-auto mx-auto fw-bold fs-6">
              Select location to view menu.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default MainLunchComp;
