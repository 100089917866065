import { useState } from "react";
import Button from "react-bootstrap/Button";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ImageSvgs from "../ImageSvgs";
import { getHostUrl } from "../../config/environment";
import { patchData } from "../../services/apiService";

const DashboardEditModal = (props) => {
  const setSacLinks = props?.setLinks;
  const sacLinks = props.links;

  const [formValues, setFormValues] = useState(sacLinks);
  const [loading, setLoading] = useState(false);

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([...formValues, { name: "", link: "" }]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);

    setFormValues(newFormValues);
  };

  const saveSacLinks = async (links) => {
    const rowValue = JSON.parse(localStorage.getItem("userConfig"));
    const userId = JSON.parse(localStorage.getItem("userData"));
    const payLoad = {
      data: {
        type: "user",
        id: userId.userId,
        attributes: {
          configuration: {
            row: rowValue.row,
            homeConfig: rowValue?.homeConfig,
            lunchData: rowValue.lunchData,
            sacLinks: links,
          },
        },
      },
    };

    const url = `${getHostUrl()}user/config`;

    try {
      // eslint-disable-next-line
      const sacLinks = await patchData(url, payLoad);
      if (sacLinks?.status === 200) {
        setSacLinks(sacLinks?.data?.data?.attributes?.configuration?.sacLinks);
        setLoading(false);
        localStorage.setItem(
          "userConfig",
          JSON.stringify(sacLinks?.data?.data?.attributes?.configuration)
        );
        props?.onHide();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Dashboard Links and Names
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="trainings-links-modal">
        {formValues?.length > 0 ? (
          <Form>
            {formValues?.map((elem, index) => {
              return (
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label className="w-100">
                      <span className="w-100 d-flex justify-content-between">
                        <p className="mb-0">{index + 1}.Dashboard Name</p>

                        <p
                          className="app-tertiary-btn"
                          onClick={() => {
                            removeFormFields(index);
                          }}>
                          Remove
                        </p>
                      </span>
                    </Form.Label>
                    <div className="app-primary-input-field">
                      <Form.Control
                        placeholder="Enter Dashboard Name"
                        name="name"
                        value={elem.name}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>

                    <Form.Control.Feedback type="invalid">
                      Required*
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>Dashboard Link</Form.Label>
                    <div className="app-primary-input-field">
                      <Form.Control
                        required
                        placeholder="Enter Dashboard Link"
                        name="link"
                        value={elem.link}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      Required*
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
              );
            })}
          </Form>
        ) : (
          <p className="mb-0 fs-5">Add Dashboard Links here.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-between w-100">
          <div
            onClick={() => {
              addFormFields();
            }}
            className="pt-1">
            <ImageSvgs name="add_icon" />
          </div>
          <div>
            <Button className="app-secondary-btn me-2" onClick={props.onHide}>
              Close
            </Button>
            <Button
              className="app-primary-btn"
              onClick={async () => {
                let linksList = formValues?.map((elem, index) => {
                  return {
                    name: elem.name,
                    link: elem.link,
                    id: index + 1,
                  };
                });

                if (linksList.length > 0) {
                  const iniLink = linksList[0];
                  props?.setIframeSrc([iniLink]);
                  props?.setActiveLink(iniLink?.id);
                } else {
                  props?.setIframeSrc([]);
                  props?.setActiveLink(null);
                }
                setLoading(true);
                saveSacLinks(linksList);
              }}>
              {loading ? "Adding Links..." : "Add Links"}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DashboardEditModal;
