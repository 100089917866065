import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/cjs/styles/prism";

const FormatString = (response) => {
  const parts = response?.split("```");

  const boldPattern = /\*\*(.*?)\*\*/g;
  const boldUnderlinePattern = /###(.*?)###/g;

  // Helper function to format text with bold and bold-underline styles
  const formatText = (text) => {
    return text
      .split(boldPattern)
      .map((part, index) => {
        if (index % 2 === 1) {
          return <strong key={index}>{part}</strong>;
        }

        return part.split(boldUnderlinePattern).map((subPart, subIndex) => {
          if (subIndex % 2 === 1) {
            return (
              <strong
                key={`${index}-${subIndex}`}
                style={{ textDecoration: "underline" }}
              >
                {subPart}
              </strong>
            );
          }
          return subPart;
        });
      })
      .flat(); // Flatten the nested array structure
  };

  let accumulatedText = "";
  // eslint-disable-next-line
  const components = parts.map((part, index) => {
    if (part.includes("links_ref")) {
      const contentBeforeLinksRef = part.split("links_ref")[0];
      accumulatedText += contentBeforeLinksRef;

      return <p className="m-0">{formatText(accumulatedText)}</p>;
    } else {
      if (index % 2 === 0) {
        const lines = part.split("\n").map((line, lineIndex) => (
          <p className="m-0" key={`${index}-${lineIndex}`}>
            {formatText(line)}
          </p>
        ));

        return <div key={index}>{lines}</div>;
      } else {
        // Code section, render in SyntaxHighlighter
        return (
          <SyntaxHighlighter
            key={index}
            style={vscDarkPlus}
            language="javascript"
            wrapLongLines={true}
          >
            {part.trim()}
          </SyntaxHighlighter>
        );
      }
    }
  });
  return <>{components}</>;
};

export default FormatString;
