import React from "react";
import ImageSvgs from "../ImageSvgs";
import ExcelJS from "exceljs";
import Papa from "papaparse";
import { chunk } from "lodash";
import { useEffect, useState, useRef } from "react";
import { Stack, Card, Nav } from "react-bootstrap";
import { cardArrow } from "../../utils/iconUtils";
import image from "../../assets/images/subscribeImage.svg";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import downloadImg from "../../assets/images/downloadMembers.svg";
import noConnections from "../../assets/images/noConnections.svg";
import MyVerticallyCenteredModal from "../CommonComponents/ReadMore";
import { EmployeeShowModal } from "../CommonComponents/EmployeeShowModal";
import CarouselConnections from "../CommonComponents/CarouselConnections";
import CarouselExpertComponent from "../CommonComponents/CarouselExpertComponent";
import { getHostUrl, getBaseUrl, getYammerUrl } from "../../config/environment";
import { getData, postData } from "../../services/apiService";
import Loader from "../Loader/Loader";
import "./CommunityUserPages.scss";
import "../../assets/scss/common.scss";

export default function CommunityUserPage({ setLevel, hide, comId }) {
  const base64 = require("base-64");
  const [loaded, setLoaded] = useState(false);
  const [commData, setcommData] = useState();
  const [appCons, setAppCons] = useState([]);
  const [proCons, setProCons] = useState([]);
  const [toolCons, setToolCons] = useState([]);
  const [heads, setHeads] = useState([]);
  const [feed, setFeed] = useState();
  const [showModal, setShowModal] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [copyPageUrl, setCopyPageUrl] = useState(false);
  const pageUrl = window.location.href;
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [includedData, setIncludedData] = useState();
  const [channelInfo, setChannelInfo] = useState([]);
  const [activeButton, setActiveButton] = useState("1");

  const widthRef = useRef(null);

  useEffect(() => {
    (async () => {
      window.scrollTo(0, 0);
      setLoaded(false);
      const res = await getData(
        `${getHostUrl()}community/${comId}?include=apps,communities_process,tools,channel_info,heads&use_pagination=false`
      );

      setIncludedData(res?.data?.included);
      if (res?.data.data.attributes["community-desc"]?.length > 300) {
        setIsOverflowing(true);
      }
      await setcommData(res.data);

      if (res.data.data.attributes["community-type"].toString() === "0") {
        const url = `${getHostUrl()}yammer/community_message`;

        if (
          commData &&
          commData?.data.attributes["community-desc"]?.length > 300
        ) {
          setIsOverflowing(true);
        }
        let comLink = res.data.data.attributes["community-link"];

        const tempId = comLink.substring(
          comLink.indexOf("groups") + 7,
          comLink.lastIndexOf("/")
        );

        const body = {
          communityid: [
            {
              limit: 5,
              id: JSON.parse(base64.decode(tempId)).id,
            },
          ],
        };

        const yammer = await postData(url, body);

        if (yammer?.data.length.toString() === "0") {
          await setFeed([]);
          await setIsPrivate(true);
        } else if (
          yammer?.data.length > 0 &&
          yammer?.data[0]?.messages.length.toString() === "0"
        ) {
          await setFeed([]);
          await setIsPrivate(true);
        } else if (
          yammer?.data.length > 0 &&
          yammer?.data[0]?.messages.length > 0
        ) {
          await setFeed(yammer.data[0].messages);
          await setIsPrivate(false);
        }
      }
      res.data.data.relationships.apps.data.map((elem) => {
        let temp = res.data.included?.filter(
          (app) =>
            app.type === "application" &&
            app.id.toString() === elem.id.toString()
        )[0].attributes["app-master"].data;
        setAppCons((appCons) => [...appCons, temp]);
        return null;
      });

      res.data.data.relationships["communities-process"].data.map((elem) => {
        let temp = res.data.included?.filter(
          (process) =>
            process.type === "process" &&
            process.id.toString() === elem.id.toString()
        )[0].attributes.process.data;
        setProCons((proCons) => [...proCons, temp]);
        return null;
      });

      res.data.data.relationships.tools.data.map((elem) => {
        let temp = res.data.included?.filter(
          (tool) =>
            tool.type === "tools" && tool.id.toString() === elem.id.toString()
        )[0].attributes.tools.data;
        setToolCons((toolCons) => [...toolCons, temp]);
        return null;
      });

      const ownerData =
        res?.data?.data?.relationships?.heads?.data?.length > 0
          ? res?.data?.data?.relationships?.heads?.data?.map((elem) => {
              return (
                res?.data?.included?.length > 0 &&
                res?.data?.included?.filter(
                  (head) =>
                    head.type === "heads" &&
                    head.id.toString() === elem.id.toString()
                )[0].attributes.employee.data
              );
            })
          : [];

      let tempOwnerData = chunk(
        ownerData
          .sort((a, b) =>
            a.attributes["preferred-name"].localeCompare(
              b.attributes["preferred-name"]
            )
          )
          .sort(
            (a, b) =>
              Number(
                includedData?.filter(
                  // eslint-disable-next-line
                  (owners) =>
                    owners.type === "heads" &&
                    owners.attributes["employee-id"].toString() ===
                      b.id.toString()
                )[0].attributes["is-head"]
              ) -
              Number(
                includedData?.filter(
                  // eslint-disable-next-line
                  (owners) =>
                    owners.type === "heads" &&
                    owners.attributes["employee-id"].toString() ===
                      a.id.toString()
                )[0].attributes["is-head"]
              )
          ),
        4
      );
      setHeads(tempOwnerData);

      let tempChannelInfo = res?.data?.data?.relationships?.[
        "channel-info"
      ]?.data?.map((elem) => {
        let tempData = res?.data?.included?.filter(
          (info) =>
            info.type === "community-channel-info" &&
            info.id.toString() === elem.id.toString()
        )[0];

        return {
          name: tempData?.attributes["channel-title"],
          link: tempData?.attributes["channel-URL"],
          id: tempData?.id.toString(),
        };
      });
      setChannelInfo(tempChannelInfo);

      if (commData?.data.attributes["community-desc"]?.length > 300) {
        setIsOverflowing(true);
      }
      setLoaded(true);
    })();

    // eslint-disable-next-line
  }, []);

  let tempChannelInfo = commData?.data?.relationships?.[
    "channel-info"
  ]?.data?.map((elem) => {
    let tempData = includedData?.filter(
      (info) =>
        info.type === "community-channel-info" &&
        info.id.toString() === elem.id.toString()
    )[0];

    return {
      name: tempData?.attributes["channel-title"],
      link: tempData?.attributes["channel-URL"],
      id: tempData?.id.toString(),
    };
  });

  useEffect(() => {
    (async () => {
      const e = widthRef.current;
      if (e?.offsetHeight < e?.scrollHeight) {
        setIsOverflowing(true);
        setChannelInfo(tempChannelInfo);
      } else {
        setIsOverflowing(false);
        setChannelInfo(tempChannelInfo);
      }
    })();
    // eslint-disable-next-line
  }, [commData]);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
  };

  const getFilteredData = () => {
    switch (activeButton) {
      case "1":
        return appCons;
      case "2":
        return proCons;
      case "3":
        return toolCons;
      default:
        return [];
    }
  };

  const downloadExcel = async (data, fileName) => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Sheet1");

    data.forEach((row, rowIndex) => {
      sheet.addRow(row);

      if (rowIndex === 0) {
        sheet.getRow(1).eachCell({ includeEmpty: true }, (cell, colNumber) => {
          if (colNumber <= 5) {
            cell.font = { bold: true };
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFFF00" },
            };
          }
        });
      }
    });

    sheet.columns.forEach((col, colIndex) => {
      if (colIndex === 0) {
        col.width = 25;
      } else if (colIndex === 1) {
        col.width = 25;
      } else if (colIndex === 2) {
        col.width = 35;
      } else if (colIndex === 3) {
        col.width = 45;
      } else if (colIndex === 4) {
        col.width = 35;
      }
    });

    const buffer = await workbook.xlsx.writeBuffer();

    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownload = async () => {
    try {
      const param = `${getHostUrl()}community/${comId}/members/download`;
      const response = await getData(param);
      const csvData = response.data;
      const parsedData = Papa.parse(csvData, { skipEmptyLines: true });
      const interpretedData = parsedData.data.map((row) => Object.values(row));

      await downloadExcel(
        interpretedData,
        `${commData?.data?.attributes["community-name"]}_Members`
      );
    } catch (error) {
      console.error("Error downloading data:", error);
    }
  };
  const handleOpenModalWithData = (data) => {
    handleOpenModal();
    setcommData(data);
  };
  return (
    <>
      {loaded ? (
        <div className="community-user-page pr-2">
          <div>
            <Link
              className="app-table-name"
              style={{ textDecoration: "none" }}
              to={`/landscape/communities?page=communities`}
              state={{ prevPath: window.location.href }}>
              <button
                className="fw-bold border-0 bg-transparent mb-1"
                onClick={() => {
                  setLevel("");
                  hide();
                }}>
                <ImageSvgs name="goBack" /> Back
              </button>
            </Link>
            <div className="mb-3">
              <span className="main-community-title fs-5 fw-bold">
                {" "}
                Community Details
              </span>
            </div>

            <div className="d-flex justify-content-between pb-1">
              <div className="d-flex flex-row">
                {commData.data.attributes["community-image"] ? (
                  <span>
                    <img
                      className="app-image"
                      src={
                        commData.data.attributes["community-image"] ||
                        commData.data.attributes["community-image"] !== null ? (
                          `${getBaseUrl()}${
                            commData.data.attributes["community-image"]
                          }`
                        ) : (
                          <span>
                            <ImageSvgs name="default_img" />
                          </span>
                        )
                      }
                      alt={`${commData.data.attributes["community-name"]}`}
                    />
                  </span>
                ) : (
                  <span>
                    <ImageSvgs name="default_img" />
                  </span>
                )}
                <span className="pb-2 d-flex flex-column px-3">
                  <span className="d-flex flex-row">
                    <span className="pb-1 fw-bold" style={{ fontSize: "17px" }}>
                      {commData.data.attributes["community-name"]}
                    </span>
                    <span className="px-1">
                      <button
                        className=" bg-transparent border-0"
                        data-toggle="tooltip"
                        data-placement="top"
                        title={!copyPageUrl ? "Copy Page Url" : "Copied"}
                        disabled={copyPageUrl}
                        onClick={() => {
                          navigator.clipboard.writeText(pageUrl);
                          setCopyPageUrl(true);
                          setTimeout(() => {
                            setCopyPageUrl(false);
                          }, 300);
                        }}>
                        {copyPageUrl === false ? (
                          <>
                            <ImageSvgs name="expertCopy" />
                          </>
                        ) : (
                          <>
                            <ImageSvgs name="tickMark" />
                          </>
                        )}
                      </button>
                    </span>
                  </span>

                  <p className="limited-space mx-0" ref={widthRef}>
                    <b>Description: </b>

                    <div style={{ whiteSpace: "pre-line" }}>
                      {commData.data.attributes["community-desc"]?.replace(
                        /\\n/g,
                        "\n"
                      )}
                    </div>
                  </p>
                  <p
                    className="mx-0 mb-0 read-more-btn"
                    style={{ cursor: "pointer", color: "#8a00e5" }}
                    onClick={() => {
                      setShowInfoModal(true);
                    }}>
                    {isOverflowing ? "Read more" : null}
                  </p>
                </span>
              </div>
              {isPrivate ? (
                <div>
                  <span>
                    <ImageSvgs name="privateIcon" style={{ float: "right" }} />
                  </span>
                </div>
              ) : null}
            </div>
            <div className="experts-section mt-3">
              {heads.length > 0 ? (
                <span className="d-flex justify-content-between">
                  <span>
                    <h6 className="fw-bold d-flex" style={{ fontSize: "18px" }}>
                      Owners of
                      {" " + commData.data.attributes["community-name"] + " "}
                      are:-
                    </h6>
                  </span>
                  <span className="d-flex">
                    <h6
                      className="fw-bold app-new-btn mt-2"
                      style={{ fontSize: "18px" }}
                      onClick={() => handleOpenModalWithData(commData)}>
                      {commData.data.attributes["community-type"] === 0 ? (
                        <>
                          {commData.data.attributes["members-count"] + " "}
                          {commData.data.attributes["members-count"] === 1
                            ? "Member"
                            : "Members"}
                        </>
                      ) : (
                        <>
                          {commData.data.relationships.members.data.length +
                            " "}
                          {commData.data.relationships.members.data.length === 1
                            ? "Member"
                            : "Members"}
                        </>
                      )}
                    </h6>
                    <span
                      className="members-download ms-3 me-3"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Download Members List">
                      <img
                        src={downloadImg}
                        alt="Download"
                        onClick={() => {
                          handleDownload();
                        }}
                      />
                    </span>
                  </span>
                </span>
              ) : (
                <span className="d-flex justify-content-between">
                  <span>
                    <h6 className="fw-bold d-flex" style={{ fontSize: "18px" }}>
                      Owners of
                      {" " + commData.data.attributes["community-name"] + " "}
                      are:-
                      <p className="mb-0 ms-2 text-muted">N/A</p>
                    </h6>
                  </span>
                  <span className="d-flex">
                    <h6
                      className="fw-bold app-new-btn mt-2"
                      style={{ fontSize: "18px" }}
                      onClick={() => handleOpenModalWithData(commData)}>
                      {commData.data.attributes["community-type"] === 0 ? (
                        <>
                          {commData.data.attributes["members-count"] + " "}
                          {commData.data.attributes["members-count"] === 1
                            ? "Member"
                            : "Members"}
                        </>
                      ) : (
                        <>
                          {commData.data.relationships.members.data.length +
                            " "}
                          {commData.data.relationships.members.data.length === 1
                            ? "Member"
                            : "Members"}
                        </>
                      )}
                    </h6>
                    <span
                      className="members-download ms-3 me-3"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Download Members List">
                      <img
                        src={downloadImg}
                        alt="Download"
                        onClick={() => {
                          handleDownload();
                        }}
                      />
                    </span>
                  </span>
                </span>
              )}
              <div className="d-flex flex-row justify-content-between">
                {heads?.length > 0 ? (
                  <CarouselExpertComponent data={heads} owner={true} />
                ) : null}
              </div>
            </div>
            <div className="d-flex flex-column mt-3">
              <h5 style={{ fontSize: "18px" }}>
                <b>
                  {commData.data.attributes["community-name"]} is connected to:-
                </b>
              </h5>
              <div>
                <div className="button-group-container">
                  <ButtonGroup className="my-2 w-100">
                    <Button
                      className={
                        activeButton === "1"
                          ? "form-select-btn form-select-activeBtn"
                          : "form-select-btn "
                      }
                      style={{ width: "33.33%" }}
                      onClick={() => handleButtonClick("1")}>
                      Applications
                    </Button>

                    <Button
                      className={
                        activeButton === "2"
                          ? "form-select-btn form-select-activeBtn "
                          : "form-select-btn "
                      }
                      style={{ width: "33.33%" }}
                      onClick={() => handleButtonClick("2")}>
                      Processes
                    </Button>

                    <Button
                      className={
                        activeButton === "3"
                          ? "form-select-btn form-select-activeBtn "
                          : "form-select-btn"
                      }
                      style={{ width: "33.33%" }}
                      onClick={() => handleButtonClick("3")}>
                      Tools
                    </Button>
                  </ButtonGroup>
                </div>

                <div className="banner-carousel">
                  <div className="connections-change-section mt-2">
                    {getFilteredData().length === 0 ? (
                      <div
                        className="d-flex justify-content-center"
                        style={{ height: "12rem" }}>
                        <div className="d-flex flex-column mt-2">
                          <img
                            className="mx-auto"
                            style={{ height: "8rem" }}
                            src={noConnections}
                            alt="No Data"
                          />
                          <p className="mb-0 fw-bold">No connections found!</p>
                        </div>
                      </div>
                    ) : (
                      <CarouselConnections data={chunk(getFilteredData(), 5)} />
                    )}
                  </div>
                </div>
              </div>
            </div>
            {commData.data.attributes["community-type"].toString() === "0" ? (
              <>
                <div className="pb-2 pt-3 d-flex flex-column">
                  <div className="my-auto d-flex">
                    <h6
                      className="me-2 my-auto fw-bold"
                      style={{ fontSize: "18px" }}>
                      Community Channels:-
                    </h6>
                  </div>

                  <div className="text-muted pb-2 pt-2">
                    Please click on a community channels to get redirected.
                  </div>

                  <div className="grid-container">
                    <div>
                      <div key={commData?.data.id} className="grid-item">
                        <div className="d-flex flex-column">
                          <div className="d-flex justify-content-center">
                            <ImageSvgs name="vivaengage_img" />
                          </div>
                          <div className="d-flex justify-content-center">
                            <a
                              rel="noreferrer"
                              href={commData?.data.attributes["community-link"]}
                              target="_blank"
                              className="app-new-btn desc-ellipses pt-2"
                              style={{
                                display: "inline-block",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}>
                              {commData?.data.attributes["community-name"]}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    {channelInfo?.length > 0
                      ? channelInfo?.map((item) => (
                          <div>
                            <div key={item.id} className="grid-item">
                              {item?.link?.includes("teams.microsoft.com") && (
                                <div className="d-flex flex-column">
                                  <div className="d-flex justify-content-center">
                                    <ImageSvgs name="teams_icon" />
                                  </div>
                                  <div className="d-flex justify-content-center">
                                    <a
                                      rel="noreferrer"
                                      href={item.link}
                                      // eslint-disable-next-line
                                      target="_blank"
                                      className="app-new-btn desc-ellipses pt-2"
                                      style={{
                                        display: "inline-block",
                                        // maxWidth: "50%",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                      }}>
                                      {item.name}
                                    </a>
                                  </div>
                                </div>
                              )}
                              {item?.link?.includes("sharepoint.com") && (
                                <div className="d-flex flex-column">
                                  <div className="d-flex justify-content-center">
                                    <ImageSvgs name="sharepoint_img" />
                                  </div>
                                  <div className="d-flex justify-content-center">
                                    <a
                                      rel="noreferrer"
                                      href={item.link}
                                      // eslint-disable-next-line
                                      target="_blank"
                                      className="app-new-btn  pt-2"
                                      style={{
                                        display: "inline-block",
                                        // maxWidth: "50%",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                      }}>
                                      {item.name}
                                    </a>
                                  </div>
                                </div>
                              )}
                              {item?.link?.includes("web.yammer.com") && (
                                <div className="d-flex flex-column">
                                  <div className="d-flex justify-content-center">
                                    <ImageSvgs name="vivaengage_img" />
                                  </div>
                                  <div className="d-flex justify-content-center">
                                    <a
                                      rel="noreferrer"
                                      href={item.link}
                                      // eslint-disable-next-line
                                      target="_blank"
                                      className="app-new-btn desc-ellipses pt-2"
                                      style={{
                                        display: "inline-block",
                                        // maxWidth: "50%",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                      }}>
                                      {item.name}
                                    </a>
                                  </div>
                                </div>
                              )}
                              {!item?.link?.includes("teams.microsoft.com") &&
                                !item?.link?.includes("sharepoint.com") &&
                                !item?.link?.includes("web.yammer.com") && (
                                  <div className="d-flex flex-column">
                                    <div className="d-flex justify-content-center">
                                      <ImageSvgs name="others_img" />
                                    </div>
                                    <div className="d-flex justify-content-center">
                                      <a
                                        rel="noreferrer"
                                        href={item.link}
                                        target="_blank"
                                        className="app-new-btn pt-2"
                                        style={{
                                          display: "inline-block",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                        }}>
                                        {item.name}
                                      </a>
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
                {feed ? (
                  <div className="mt-4">
                    <div
                      className="news-header d-flex flex-row justify-content-between mx-auto my-2"
                      style={{ width: "100%" }}>
                      <div>
                        <h4
                          className="sec-title fw-bold mb-0"
                          style={{ color: "black", fontSize: "18px" }}>
                          Community Newsfeed
                        </h4>
                      </div>
                    </div>
                    <div className="community-newscomp-carousel">
                      {feed?.length <= 0 && isPrivate === true ? (
                        <img
                          src={image}
                          alt="subscribeImage"
                          className="w-100"
                        />
                      ) : (
                        <>
                          {chunk(feed, 5)?.map((record, i) => {
                            return (
                              <Stack
                                key={i}
                                direction="horizontal"
                                className="h-100 align-items-center"
                                gap={2}>
                                {record.map((comMsg, i) => {
                                  const date = comMsg.created_at.split(" ");
                                  return (
                                    <Card key={i}>
                                      <Nav.Link
                                        href={`${getYammerUrl()}threads/${base64.encode(
                                          JSON.stringify({
                                            _type: "Thread",
                                            id: `${comMsg["thread_id"]}`,
                                          })
                                        )}`}
                                        target="_blank">
                                        <div className="img-hover-zoom">
                                          <Card.Img
                                            variant="bottom"
                                            src={`${getBaseUrl()}${
                                              commData.data.attributes[
                                                "community-image"
                                              ]
                                            }`}
                                            alt={`${commData.data.attributes["community-name"]}`}
                                          />
                                        </div>
                                        <Card.Body>
                                          <Card.Title className="mb-2 fw-bold fs-5">
                                            {
                                              commData?.data.attributes[
                                                "community-name"
                                              ]
                                            }
                                          </Card.Title>
                                          <Card.Subtitle className="mb-2 text-muted">
                                            Date: {date[0]}
                                          </Card.Subtitle>

                                          <Card.Text className="yammer-card-text">
                                            {comMsg.body.rich.replace(
                                              /(<([^>]+)>)/gi,
                                              ""
                                            )}
                                          </Card.Text>
                                          <div className="yammer-card-arrow card-arrow">
                                            {cardArrow}
                                          </div>
                                        </Card.Body>
                                      </Nav.Link>
                                    </Card>
                                  );
                                })}
                              </Stack>
                            );
                          })}
                        </>
                      )}
                    </div>
                  </div>
                ) : null}
              </>
            ) : (
              <div className="pb-2 pt-2 d-flex flex-column">
                <div className="my-auto d-flex">
                  <h6
                    className="me-2 my-auto fw-bold"
                    style={{ fontSize: "18px" }}>
                    Community Channels:-
                  </h6>
                </div>

                <div className="text-muted pb-2 pt-2">
                  Please click on a community channel to get redirected.
                </div>

                <div className="grid-container">
                  <div>
                    <div key={commData?.data.id} className="grid-item">
                      <div className="d-flex flex-column">
                        <div className="d-flex justify-content-center">
                          <ImageSvgs
                            name={
                              commData?.data.attributes[
                                "community-link"
                              ].includes("teams.microsoft.com")
                                ? "teams_icon"
                                : commData?.data.attributes[
                                    "community-link"
                                  ].includes("sharepoint.com")
                                ? "sharepoint_img"
                                : commData?.data.attributes[
                                    "community-link"
                                  ].includes("web.yammer.com")
                                ? "vivaengage_img"
                                : "others_img"
                            }
                          />
                        </div>
                        <div className="d-flex justify-content-center">
                          <a
                            rel="noreferrer"
                            href={commData?.data.attributes["community-link"]}
                            target="_blank"
                            className="app-new-btn desc-ellipses pt-2"
                            style={{
                              display: "inline-block",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}>
                            {commData?.data.attributes["community-name"]}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  {channelInfo?.length > 0
                    ? channelInfo.map((item) => (
                        <div>
                          <div key={item.id} className="grid-item">
                            {item?.link?.includes("teams.microsoft.com") && (
                              <div className="d-flex flex-column">
                                <div className="d-flex justify-content-center">
                                  <ImageSvgs name="teams_icon" />
                                </div>
                                <div className="d-flex justify-content-center">
                                  <a
                                    rel="noreferrer"
                                    href={item.link}
                                    target="_blank"
                                    className="app-new-btn desc-ellipses pt-2"
                                    style={{
                                      display: "inline-block",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}>
                                    {item.name}
                                  </a>
                                </div>
                              </div>
                            )}
                            {item?.link?.includes("sharepoint.com") && (
                              <div className="d-flex flex-column">
                                <div className="d-flex justify-content-center">
                                  <ImageSvgs name="sharepoint_img" />
                                </div>
                                <div className="d-flex justify-content-center">
                                  <a
                                    rel="noreferrer"
                                    href={item.link}
                                    target="_blank"
                                    className="app-new-btn  pt-2"
                                    style={{
                                      display: "inline-block",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}>
                                    {item.name}
                                  </a>
                                </div>
                              </div>
                            )}
                            {item?.link?.includes("web.yammer.com") && (
                              <div className="d-flex flex-column">
                                <div className="d-flex justify-content-center">
                                  <ImageSvgs name="vivaengage_img" />
                                </div>
                                <div className="d-flex justify-content-center">
                                  <a
                                    rel="noreferrer"
                                    href={item.link}
                                    target="_blank"
                                    className="app-new-btn desc-ellipses pt-2"
                                    style={{
                                      display: "inline-block",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}>
                                    {item.name}
                                  </a>
                                </div>
                              </div>
                            )}
                            {!item?.link?.includes("teams.microsoft.com") &&
                              !item?.link?.includes("sharepoint.com") &&
                              !item?.link?.includes("web.yammer.com") && (
                                <div className="d-flex flex-column">
                                  <div className="d-flex justify-content-center">
                                    <ImageSvgs name="others_img" />
                                  </div>
                                  <div className="d-flex justify-content-center">
                                    <a
                                      rel="noreferrer"
                                      href={item.link}
                                      target="_blank"
                                      className="app-new-btn pt-2"
                                      style={{
                                        display: "inline-block",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                      }}>
                                      {item.name}
                                    </a>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      ))
                    : null}
                </div>
              </div>
            )}
            {showModal === true ? (
              <EmployeeShowModal
                show={showModal}
                handleClose={handleCloseModal}
                title={"View Members"}
                communityData={commData}
              />
            ) : (
              ""
            )}
            {showInfoModal ? (
              <MyVerticallyCenteredModal
                show={showInfoModal}
                onHide={() => setShowInfoModal(false)}
                data={commData?.data}
              />
            ) : null}
          </div>
        </div>
      ) : (
        <div style={{ maxWidth: "100%", height: "100vh" }}>
          <Loader />
        </div>
      )}
    </>
  );
}
