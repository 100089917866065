import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

export const useQueryParams = (dropdowns) => {
  const location = useLocation();
  const navigate = useNavigate();

  // Parse query params on first load
  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    const filters = {};

    dropdowns.forEach((dropdown) => {
      const selectedValue = params.get(dropdown.queryKey);

      if (selectedValue) {
        try {
          filters[dropdown.queryKey] = JSON.parse(
            decodeURIComponent(selectedValue)
          );
        } catch (error) {
          filters[dropdown.queryKey] = decodeURIComponent(selectedValue);
        }
      }
    });

    return filters;
  };

  const initialFilters = getQueryParams();

  dropdowns.forEach((dropdown) => {
    if (
      dropdown.queryKey === "language" &&
      !initialFilters.language &&
      dropdown.defaultValue
    ) {
      initialFilters.language = [dropdown.defaultValue];
      const params = new URLSearchParams(location.search);
      params.set(
        "language",
        encodeURIComponent(JSON.stringify([dropdown.defaultValue]))
      );
      navigate({ search: params.toString() }, { replace: true });
    }
  });

  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    setFilters(getQueryParams());
    // eslint-disable-next-line
  }, [location.search]);

  const setFiltersInUrl = (newFilters) => {
    const params = new URLSearchParams(location.search);

    Object.entries(newFilters).forEach(([key, value]) => {
      if (Array.isArray(value) && value.length > 0) {
        params.set(key, encodeURIComponent(JSON.stringify(value)));
      } else if (value !== undefined && value !== null) {
        params.set(key, encodeURIComponent(JSON.stringify(value)));
      } else {
        params.delete(key);
      }
    });

    navigate({ search: params.toString() });
  };

  return {
    filters,
    setFilters: setFiltersInUrl,
  };
};
