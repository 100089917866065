import { Modal, Button } from "react-bootstrap";
import { postData } from "../services/apiService";
// eslint-disable-next-line
import { getHostUrl, getBaseUrl, getRedirectUrl } from "../config/environment";
import { patchData } from "../services/apiService";
import { deleteData } from "../services/apiService";
import { ListGroupItem } from "react-bootstrap";
import { ListGroup } from "react-bootstrap";
import ImageSvgs from "../components/ImageSvgs";
import Avatar from "react-avatar";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const ProcessDeleteModal = (props) => {
  const navigate = useNavigate();
  const [flag, setFlag] = useState(false);

  const deleteProcess = async () => {
    setFlag(true);
    props.setDeleteFlag(true);
    const deleteProcessId = props.deleteProcessData.id;

    // eslint-disable-next-line
    const res = await deleteData(`${getHostUrl()}process/${deleteProcessId}`);
    if (res.status === 204) {
      const currentUrl = window.location.href;
      if (currentUrl.includes("manage_process")) {
        navigate("/manage_process?page=process");
        props.setTableLoad(true);
      } else {
        navigate("/landscape/process?process_id=0&view=cardView");
      }
      props.onHide();
      setFlag(false);
      props.setDeleteFlag(false);
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="delete-modal my-auto">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Delete Process
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className="pt-1 text-capitalize">
          <strong>
            Process Name : {props.deleteProcessData.attributes.title}{" "}
          </strong>
          <br></br>
        </span>
        <span className="pt-1">
          <span className="d-flex flex-column">
            <span>Are you sure you want to delete this Process?</span>
          </span>
        </span>
      </Modal.Body>
      <Modal.Footer>
        <Button
          id="common-favbutton"
          onClick={() => {
            deleteProcess();
          }}>
          {props.deleteFlag || flag ? "Deleting..." : "Delete"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const addProcess = async ({
  processData,
  processConfig,
  setPageLevel,
  setFlag,
  setLoading,
  setTableLoad,
  navigate,
}) => {
  const attributes = processData?.data?.attributes;
  const relationships = processData?.data?.relationships;

  const finalPayload = {
    data: {
      type: "process",
      attributes: {
        title: attributes?.title,
        desc: attributes?.desc,
        handbook_link_url: attributes["handbook_link_url"],
        wiki_link_url: attributes["wiki_link_url"],
        reference_urls: attributes?.["reference-urls"]
          ? attributes?.["reference-urls"]
          : null,
        template_urls: attributes?.["template-urls"],
        process_config: processConfig && processConfig,
        parent_process_id: attributes["parent_process_id"]
          ? attributes["parent_process_id"]
          : null,
        periodicity_period: attributes["periodicity_period"]
          ? attributes["periodicity_period"][0]?.id
          : null,
        process_day: attributes?.["process_day"]
          ? attributes?.["process_day"]?.toString()
          : null,
        process_time: attributes?.["process_time"]
          ? attributes?.["process_time"]?.toString()
          : null,
        end_day: attributes?.["end_day"]
          ? attributes?.["end_day"]?.toString()
          : null,
        end_time: attributes?.["end_time"]
          ? attributes?.["end_time"]?.toString()
          : null,
        group: attributes?.group,
      },
      relationships: {
        owners: {
          data: relationships.owners ? relationships.owners : [],
        },
        communities: {
          data: relationships.communities ? relationships.communities : [],
        },
        apps: {
          data: relationships.apps ? relationships.apps : [],
        },
        orgs: {
          data: relationships.orgs ? relationships.orgs : [],
        },
        trainings: {
          data: relationships.trainings ? relationships.trainings : [],
        },
        "parent-processes": {
          data: relationships.parentCons ? relationships.parentCons : [],
        },
        "child-processes": {
          data: relationships.childCons ? relationships.childCons : [],
        },
      },
    },
  };

  const url = `${getHostUrl()}process`;
  try {
    // eslint-disable-next-line
    const processRes = await postData(url, finalPayload);

    if (processRes.status === 200) {
      if (
        sessionStorage.getItem("processFlag") &&
        sessionStorage.getItem("processFlag") === "true"
      ) {
        sessionStorage.removeItem("processPayload");
        sessionStorage.removeItem("processConfig");
        sessionStorage.removeItem("processFlag");
        sessionStorage.removeItem("processEditData");
        sessionStorage.removeItem("processIncluded");
        sessionStorage.removeItem("applicationAll");
        sessionStorage.removeItem("trainingAll");
        sessionStorage.removeItem("communityAll");
        sessionStorage.removeItem("orgAll");
        sessionStorage.removeItem("allProcessGroups");
        navigate("/landscape/process?process_id=0&view=cardView", {
          replace: true,
        });
      } else {
        setPageLevel("process");
        sessionStorage.removeItem("processPayload");
        sessionStorage.removeItem("processConfig");
        setFlag(true);
        setLoading(false);
        setTableLoad(true);
      }
    }
  } catch (error) {
    console.error(error);
    setLoading(false);
  }
};

export const updateProcessData = async ({
  processData,
  processConfig,
  setPageLevel,
  setFlag,
  setLoading,
  setTableLoad,
  navigate,
}) => {
  const updateId = parseInt(sessionStorage.getItem("updateId"));
  const attributes = processData?.data?.attributes;
  const relationships = processData?.data?.relationships;

  const finalPayload = {
    data: {
      type: "process",
      attributes: {
        title: attributes?.title,
        desc: attributes?.desc ? attributes?.desc : null,
        reference_urls: attributes?.["reference-urls"]
          ? attributes?.["reference-urls"]
          : null,
        template_urls: attributes?.["template-urls"],
        handbook_link_url: attributes["handbook_link_url"],
        wiki_link_url: attributes["wiki_link_url"],
        process_config: processConfig && processConfig,
        parent_process_id: attributes["parent_process_id"]
          ? attributes["parent_process_id"]
          : null,
        periodicity_period:
          attributes["periodicity_period"] != null
            ? attributes["periodicity_period"][0]?.id
            : null,
        process_day: attributes?.["process_day"]
          ? attributes?.["process_day"]
          : null,
        process_time: attributes?.["process_time"]
          ? attributes?.["process_time"]
          : null,
        end_day: attributes?.["end_day"]
          ? attributes?.["end_day"]?.toString()
          : null,
        end_time: attributes?.["end_time"]
          ? attributes?.["end_time"]?.toString()
          : null,
        group: attributes?.group,
      },
      relationships: {
        owners: {
          data: relationships.owners ? relationships.owners : [],
        },
        communities: {
          data: relationships.communities ? relationships.communities : [],
        },
        apps: {
          data: relationships.apps ? relationships.apps : [],
        },
        orgs: {
          data: relationships.orgs ? relationships.orgs : [],
        },
        trainings: {
          data: relationships.trainings ? relationships.trainings : [],
        },
        "parent-processes": {
          data: relationships.parentCons ? relationships.parentCons : [],
        },
        "child-processes": {
          data: relationships.childCons ? relationships.childCons : [],
        },
      },
    },
  };

  try {
    const res = await patchData(
      `${getHostUrl()}process/${updateId}`,
      finalPayload
    );

    if (res.status === 200) {
      if (
        sessionStorage.getItem("processFlag") &&
        sessionStorage.getItem("processFlag") === "true"
      ) {
        sessionStorage.removeItem("processPayload");
        sessionStorage.removeItem("processConfig");
        sessionStorage.removeItem("processFlag");
        sessionStorage.removeItem("updateId");
        sessionStorage.removeItem("processEditData");
        navigate("/landscape/process?process_id=0&view=cardView", {
          replace: true,
        });
      } else {
        setPageLevel("process");
        sessionStorage.removeItem("processPayload");
        sessionStorage.removeItem("processConfig");
        sessionStorage.removeItem("processEditData");
        sessionStorage.removeItem("processIncluded");
        sessionStorage.removeItem("updateId");
        setFlag(true);
        setLoading(false);
        setTableLoad(true);
      }
    }
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
};

export const UserModal = (props) => {
  const [copy, setCopy] = useState(false);
  const [copyMail, setCopyMail] = useState(false);
  const [id, setId] = useState(null);
  const listdata = props.listdata;
  const navigate = useNavigate();

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="user-modal my-auto">
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="user-modal-title">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.heading}
        <ListGroup>
          {listdata?.map((value) => {
            let imageUrl =
              value.attributes.employee.data.attributes["profile-picture"];
            return (
              <ListGroupItem key={value.id} className="user-modal-user-list">
                <span className="d-flex justify-content-between">
                  <span>
                    <span>
                      {imageUrl ? (
                        <img
                          className="my-auto"
                          src={`${getBaseUrl()}${imageUrl}`}
                          style={{
                            height: "2rem",
                            width: "2rem",
                            border: "1.5px solid black",
                            borderRadius: "7rem",
                            objectFit: "contain",
                          }}
                          alt="User Img"
                        />
                      ) : (
                        <Avatar
                          name={
                            value.attributes.employee.data.attributes[
                              "preferred-name"
                            ]
                          }
                          size="32"
                          round={true}
                        />
                      )}
                    </span>
                    <span className="px-2 user-name">
                      {" "}
                      {
                        value.attributes.employee.data.attributes[
                          "preferred-name"
                        ]
                      }{" "}
                    </span>
                  </span>
                  <span className="d-flex justify-content-between">
                    <span>
                      <button
                        className=" bg-transparent border-0"
                        data-toggle="tooltip"
                        data-placement="top"
                        title={!copy ? "Copy email" : "Copied"}
                        disabled={copy}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            value.attributes.employee.data.attributes[
                              "email-id"
                            ]
                          );
                          setCopy(true);
                          setId(value.id);
                          setTimeout(() => {
                            setCopy(false);
                            setId(null);
                          }, 1500);
                        }}>
                        {id !== value.id ? (
                          <>
                            <ImageSvgs name="expertCopy" />
                          </>
                        ) : (
                          <>
                            <ImageSvgs name="tickMark" />
                          </>
                        )}
                      </button>
                    </span>
                    <span>
                      <button
                        className="ms-2 bg-transparent border-0"
                        data-toggle="tooltip"
                        data-placement="top"
                        title={!copyMail ? "Mail to" : "Mail to:"}
                        disabled={copyMail}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            value.attributes.employee.data.attributes[
                              "email-id"
                            ]
                          );
                          setCopyMail(true);
                        }}>
                        {value.id && (
                          <>
                            <a
                              href={`mailto:${value.attributes.employee.data.attributes["email-id"]}`}>
                              <ImageSvgs name="expertMail" />
                            </a>
                          </>
                        )}
                      </button>
                    </span>

                    <span>
                      <button
                        className="bg-transparent border-0"
                        onClick={() => {
                          navigate(
                            `/employee_relation?id=${value.attributes["employee-id"]}`
                          );
                        }}>
                        <ImageSvgs name="relationIcon" />
                      </button>
                    </span>
                  </span>
                </span>
              </ListGroupItem>
            );
          })}
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
};
