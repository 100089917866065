import { getHostUrl } from "../config/environment";
import { getData } from "./apiService";

let sharePointAccessToken = "";

export const configureTokens = async () => {
  if (localStorage.getItem("idToken")) {
    sharePointAccessToken = await getData(`${getHostUrl()}sharepoint/token`);
    localStorage.setItem(
      "sharePointAccessToken",
      sharePointAccessToken?.data["access_token"]
    );
  } else {
    console.log("idToken not found");
  }

  const chatHistory = localStorage.getItem("chatHistory");
  if (!chatHistory) {
    localStorage.setItem(
      "chatHistory",
      JSON.stringify({
        data: [
          {
            id: "1",
            sender: "bot",
            message:
              "Welcome to the **SE Finance Center!** How may I help you?",
            links: [],
            reference: null,
          },
        ],
      })
    );
  }

  const feedbackList = localStorage.getItem("feedbackList");
  if (!feedbackList) {
    localStorage.setItem(
      "feedbackList",
      JSON.stringify({
        data: [],
      })
    );
  }
};
