import React, { useEffect, useState, useRef } from "react";
import FinergyAnnouncements from "./OverviewWidgets/FinergyAnnouncements";
import MainComp from "../TrainingsDashboard/MainComp";
import FinergyLearningSession from "./OverviewWidgets/FinergyLearningSession";
import TrendingLearnings from "./OverviewWidgets/TrendingLearnings";
import { getBaseUrl, getHostUrl } from "../../config/environment";
import { getData } from "../../services/apiService";
import { Button } from "react-bootstrap";
import { chunk } from "lodash";
import "./FinergyOverviewPage.scss";
import overview from "../../assets/Video.svg";
import FinergyOverviewModal from "./FinergyOverviewModal";
import { getSharePointUrl } from "../../config/environment";
import "./FinergyOverviewPage.scss";

const URL_TO_FETCH_FINERGY_INTRODUCTION_JSON = `${getBaseUrl()}/configurations/FiNergy/OverviewIntroduction.json`;
const URL_TO_FETCH_FINERGY_INTRODUCTION_VIDEO = `${getBaseUrl()}/configurations/FiNergy/FINergy_Introduction.mp4`;

const FinergyOverviewPage = () => {
  const [finergyIntroduction, setFinergyIntroduction] = useState("");
  const [announcements, setAnnouncements] = useState([]);
  const [learningSessions, setLearningSessions] = useState([]);
  const [trendingLearnings, setTrendingLearnings] = useState([]);
  const [showInfoModal, setShowInfoModal] = useState(false);
  // eslint-disable-next-line
  const [loading, setLoading] = useState([]);
  const videoRef = useRef(null);

  const handlePlayVideo = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const checkImagePath = (data) => {
    setLoading(true);
    const finNews = "sites/Finergy_Academy_Networking/_api/Web";
    if (data) {
      let propsData = data.value;
      let urlPromises = [];
      let blobPromises = [];
      let blobUrlArray = [];

      let finalUrl = "";

      propsData.forEach((element) => {
        //Sharepoint Image URL
        if (element["BannerImageUrl"]?.includes(`${getSharePointUrl()}`)) {
          if (element["BannerImageUrl"]?.includes("path")) {
            const imageUrl = decodeURI(element["BannerImageUrl"])
              .split("path=")
              ?.pop();

            const decodeUrl = decodeURIComponent(imageUrl);

            //Final Url to fetch the image ( sharepoint url + relative path + image path after for exapmple "https://siemensenergyag.sharepoint.com/_layouts/15/getpreview.ashx?path=%2Fsites%2FFinergy_Academy_Networking%2FSiteAssets%2FSitePages%2FEvent%2885%29%2F8429-Article--Whom-do-I-handover-the-baton--%E2%80%94-People-Matters.jpeg" )
            finalUrl = `${getSharePointUrl()}/${finNews}/GetFileByServerRelativeUrl(%27${decodeUrl}%27)/$value`;

            try {
              urlPromises.push(
                fetch(finalUrl, {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                      "sharePointAccessToken"
                    )}`,
                    method: "GET",
                  },
                }).catch((err) => {
                  return err;
                })
              );
            } catch (err) {
              return err;
            }
          } else if (element["BannerImageUrl"]?.includes("guidSite")) {
            const queryString = element?.BannerUrl?.Description?.split("?")[1]; // Get the part after '?'
            const params = new URLSearchParams(queryString);
            const itemId = params.get("Id");
            const listId = params.get("listId");
            const siteGuid = element?.uuid;
            const constructUrl = `sites/Finergy_Academy_Networking/_api/v2.1/sites/siemensenergyag.sharepoint.com,${siteGuid}/lists/${listId}/items/${itemId}/driveItem/thumbnails/0/c1736x228/content`;
            finalUrl = `${getSharePointUrl()}/${constructUrl}`;
            try {
              urlPromises.push(
                fetch(finalUrl, {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                      "sharePointAccessToken"
                    )}`,
                  },
                }).catch((err) => {
                  return err;
                })
              );
            } catch (err) {
              return err;
            }
          } else {
            const imageUrl = element["BannerImageUrl"]
              .split("sharepoint.com")
              ?.pop();
            finalUrl = `${getSharePointUrl()}${imageUrl}`;

            try {
              urlPromises.push(
                fetch(finalUrl, {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                      "sharePointAccessToken"
                    )}`,
                  },
                }).catch((err) => {
                  return err;
                })
              );
            } catch (err) {
              return err;
            }
          }
        } else {
          urlPromises.push(element["BannerImageUrl"]);
        }
      });

      Promise.allSettled(urlPromises)
        .then((results) => {
          createBlobForEachUrl(results)
            .then(function (res) {
              res.forEach((elem) => {
                if (typeof elem === "object") {
                  blobUrlArray.push(URL.createObjectURL(elem));
                } else {
                  blobUrlArray.push(elem);
                }
              });

              if (blobUrlArray.length > 0) {
                propsData.forEach((elem) => {
                  elem["BannerImageUrl"] = blobUrlArray.shift();
                });

                data.value = [...propsData];
                setLoading(false);
              }
            })
            .catch((err) => {
              return err;
            });
        })
        .catch((err) => {
          return err;
        });

      async function createBlobForEachUrl(blobResp) {
        for (var i = 0; i <= blobResp.length - 1; i++) {
          if (
            blobResp[i].status === "fulfilled" &&
            typeof blobResp[i].value === "object" &&
            blobResp[i].value.status === 200
          ) {
            blobPromises.push(await blobResp[i].value.blob());
          } else if (
            blobResp[i].value &&
            typeof blobResp[i].value === "string"
          ) {
            blobPromises.push(blobResp[i].value);
          } else {
            blobPromises.push(
              `${getBaseUrl()}/images/default-images/default-news.jpg`
            );
          }
        }

        return blobPromises;
      }
      data.value = [...propsData];

      setLearningSessions(chunk(data?.value, 3));
    }
  };

  useEffect(() => {
    const fetchIntroduction = async () => {
      try {
        const response = await fetch(URL_TO_FETCH_FINERGY_INTRODUCTION_JSON);
        const data = await response.json();
        setFinergyIntroduction(data || "");
      } catch (error) {
        setFinergyIntroduction("Error fetching the introduction.");
      }
    };

    fetchIntroduction();

    const fetchAnnoucemnets = async () => {
      const yammerUrl = `${getHostUrl()}yammer/finergy`;
      const res = await getData(yammerUrl, "idToken");
      setAnnouncements(chunk(res?.data?.data, 3));
    };
    fetchAnnoucemnets();
    //! sharepoint access token
    //! accept applicatiomn.json
    //! fetch sharepoint data using access token
    //! get data with current date

    const fetchLearningSessions = async () => {
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().split("T")[0];
      // eslint-disable-next-line
      const formattedDateTime =
        formattedDate +
        "T" +
        currentDate.toISOString().split("T")[1].substring(0, 8);
      const idToken = localStorage.getItem("idToken");
      const sharePointToken = localStorage.getItem("sharePointAccessToken");
      const url = `${getHostUrl()}sharepoint/token/finenergy`;

      fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${idToken}`,
          sharepointtoken: sharePointToken,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          checkImagePath(data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };
    fetchLearningSessions();

    const fetchTrendingData = async () => {
      const url = `${getHostUrl()}training/trendinglearnings`;
      const res = await getData(url);
      setTrendingLearnings(chunk(res?.data?.data, 3));
      // setAnnouncements(chunk(res?.data?.data, 3));
    };
    fetchTrendingData();
  }, []);

  const firstParagraph =
    typeof finergyIntroduction?.data === "string"
      ? finergyIntroduction?.data.split("\n")[0]
      : "";

  return (
    <div className="overview">
      <div className="row">
        <div className="col-md-12">
          <div className="row flex-wrap align-items-start">
            <div className="col-6" data-testid="content-container">
              <div className="d-flex title heading">
                <p className="fw-bold fs-4 mb-2">About FINergy Academy </p>
              </div>
              <p>{firstParagraph}</p>
              <Button className="app-primary-btn" onClick={handlePlayVideo}>
                Play Video
              </Button>
              <Button
                className="app-secondary-btn ms-2"
                onClick={() => setShowInfoModal(true)}>
                View Details
              </Button>
            </div>

            <div className="col-6">
              <video
                controls
                ref={videoRef}
                data-testid="video-element"
                className="video responsive-video"
                poster={overview}>
                <source
                  src={URL_TO_FETCH_FINERGY_INTRODUCTION_VIDEO}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="overview-comp">
            <FinergyAnnouncements title="Announcements" data={announcements} />
          </div>
        </div>
        <div className="col-md-6 ">
          <div className="overview-comp">
            <FinergyLearningSession
              title="Upcoming Learning Sessions"
              data={learningSessions}
            />
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md-6">
          <div className="overview-comp trainings-data">
            <MainComp height={"28rem"} />
          </div>
        </div>
        <div className="col-md-6">
          <div className="overview-comp">
            <TrendingLearnings data={trendingLearnings && trendingLearnings} />
          </div>
        </div>
      </div>
      <FinergyOverviewModal
        show={showInfoModal}
        onHide={() => setShowInfoModal(false)}
        data={finergyIntroduction?.data}
      />
    </div>
  );
};

export default FinergyOverviewPage;
