import React from "react";

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import "./TanstackReactFinTable.scss";

export function TanstackReactFinTable({
  data,
  columns,
  onRowClick,
  isManualPagination,
  columnFilters,
  setColumnFilters,
}) {
  isManualPagination = isManualPagination ?? false;

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
    },
    manualPagination: isManualPagination,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleRowClick = (row) => {
    onRowClick(row);
  };

  return (
    <div>
      <table>
        <thead className="vertical-line-container">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    {...{
                      key: header.id,
                      colSpan: header.colSpan,
                    }}>
                    <div className="vertical-line">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </div>
                    <div className="py-2">
                      {header.column.getCanFilter() ? (
                        <input
                          value={header.column.getFilterValue() || ""}
                          placeholder={`Search by ${
                            flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            ).props.header.id
                          }...`}
                          disabled={!header.column.getCanFilter()}
                          onChange={(e) =>
                            header.column.setFilterValue(e.target.value)
                          }
                        />
                      ) : null}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              onClick={() => handleRowClick(row)}
              className="table-row">
              {row.getVisibleCells().map((cell) => (
                <td
                  {...{
                    key: cell.id,
                    style: {
                      width: cell.column.getSize(),
                    },
                  }}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TanstackReactFinTable;
