import React, { useEffect } from "react";
import { EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "../authConfig";

export const AuthProvider = ({ children }) => {
  useEffect(() => {
    let eventCallbackId;

    // Initialize MSAL and handle account selection
    const initializeMsal = async () => {
      await msalInstance.initialize();

      if (
        !msalInstance.getActiveAccount() &&
        msalInstance.getAllAccounts().length > 0
      ) {
        msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
      }

      msalInstance.enableAccountStorageEvents();

      eventCallbackId = msalInstance.addEventCallback((event) => {
        if (
          event.eventType === EventType.LOGIN_SUCCESS &&
          event.payload.account
        ) {
          msalInstance.setActiveAccount(event.payload.account);
        }
      });
    };

    initializeMsal();

    return () => {
      if (eventCallbackId) {
        msalInstance.removeEventCallback(eventCallbackId);
      }
    };
  }, []);

  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
};
