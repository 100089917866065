import { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import ImageSvgs from "../components/ImageSvgs";
import { MultipleSelectDropdown } from "../components/CommonComponents/MultipleSelect";
import { getData, deleteData } from "../services/apiService";
import { getBaseUrl } from "../config/environment";
import { getHostUrl } from "../config/environment";
import { ImageUpload } from "../components/AdminComponents/ImageUpload";
import { Modal } from "react-bootstrap";
import { now } from "lodash";
import { EmployeeModal } from "../components/CommonComponents/EmployeeModal";
import validator from "validator";

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      console.log("error");
      reject(error);
    };
  });
};

export const EditApp = ({
  users,
  appList,
  comData,
  trainData,
  proData,
  setLevel,
  included,
}) => {
  const editData = JSON.parse(sessionStorage.getItem("editData"));
  const hash = require("object-hash");
  const [isDeleted, setIsDeleted] = useState(false);
  const [isPreviewDeleted, setIsPreviewDeleted] = useState(false);
  const tempAppImg = useRef(editData?.attributes["app-image"]);
  const tempPreviewImg = useRef(editData?.attributes["app-preview-image"]);
  const [nameErr, setNameErr] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [orgsList, setOrgsList] = useState();
  const [appOrg, setAppOrg] = useState(editData?.attributes["app-org"].appOrg);
  const appAccess = useRef(null);
  const [folderLinks, setFolderLinks] = useState(
    editData?.relationships["app-info"].data.length > 0
      ? editData?.relationships["app-info"].data.map((elem) => {
          let tempData = included?.filter(
            (info) =>
              info.type === "app-additional-info" &&
              info.id.toString() === elem.id.toString()
          )[0];
          return {
            name: tempData.attributes["info-title"],
            link: tempData.attributes["info-URL"],
            id: tempData.id.toString(),
          };
        })
      : []
  );

  const appNodes = editData?.attributes["app-nodes"].data.map((elem) => {
    return {
      type: "nodes",
      attributes: {
        "layer-id": elem.attributes["layer-id"].toString(),
        "tool-id": elem.attributes["tool-id"].toString(),
        "node-description": elem.attributes["node-description"],
      },
      id: elem.id.toString(),
    };
  });
  const imageData = useRef(null);
  const previewData = useRef(null);
  const sendImageUrl = useRef(null);
  const base64Img = useRef(null);
  const sendPreviewUrl = useRef(null);
  const base64Preview = useRef(null);

  const tempComs =
    editData?.relationships.communities.data.length > 0
      ? editData?.relationships.communities.data.map((elem) => {
          let commData = included?.filter(
            (comm) =>
              comm.type === "community" &&
              comm.id.toString() === elem.id.toString()
          )[0];
          return {
            type: "community",
            name: `${commData?.attributes["communities-master"].data.attributes["community-name"]}`,
            attributes: {
              "community-id": commData?.attributes["community-id"].toString(),
            },
            id: commData?.id.toString(),
          };
        })
      : [];

  const commOptions = comData?.map((item, el) =>
    tempComs?.filter(
      (comm) =>
        comm.type === "community" &&
        comm.attributes["community-id"].toString() === item.id.toString()
    )[0]
      ? tempComs?.filter(
          (comm) =>
            comm.type === "community" &&
            comm.attributes["community-id"].toString() === item.id.toString()
        )[0]
      : {
          type: "community",
          name: `${item.attributes["community-name"]}`,
          attributes: {
            "community-id": item.id.toString(),
          },
          id: null,
        }
  );

  const [appComs, setAppComs] = useState(tempComs);
  const tempTrainings =
    editData?.relationships.trainings.data.length > 0
      ? editData?.relationships.trainings.data.map((elem) => {
          let tempData = included?.filter(
            (train) =>
              train.type === "training" &&
              train.id.toString() === elem.id.toString()
          )[0];
          return {
            type: "training",
            name: `${tempData?.attributes["trainings-master"].data.attributes["training-name"]}`,
            attributes: {
              "training-id": tempData?.attributes["training-id"].toString(),
            },
            id: tempData?.id.toString(),
          };
        })
      : [];

  const trainOptions = trainData?.map((item, el) =>
    tempTrainings?.filter(
      (train) =>
        train.type === "training" &&
        train.attributes["training-id"].toString() === item.id.toString()
    )[0]
      ? tempTrainings?.filter(
          (train) =>
            train.type === "training" &&
            train.attributes["training-id"].toString() === item.id.toString()
        )[0]
      : {
          type: "training",
          name: `${item.attributes["training-name"]}`,
          attributes: {
            "training-id": item.id.toString(),
          },
          id: null,
        }
  );

  const [appTrainings, setAppTrainings] = useState(tempTrainings);

  const tempProcess =
    editData?.relationships.process.data.length > 0
      ? editData?.relationships.process.data.map((elem) => {
          let tempData = included?.filter(
            (process) =>
              process.type === "process" &&
              process.id.toString() === elem.id.toString()
          )[0];
          return {
            type: "process",
            name: `${tempData?.attributes["process-master"].data.attributes["title"]}`,
            attributes: {
              "process-id": tempData?.attributes["process-id"].toString(),
            },
            id: tempData?.id.toString(),
          };
        })
      : [];

  const processOptions = proData?.map((item, el) =>
    tempProcess?.filter(
      (process) =>
        process.type === "process" &&
        process.attributes["process-id"].toString() === item.id.toString()
    )[0]
      ? tempProcess?.filter(
          (process) =>
            process.type === "process" &&
            process.attributes["process-id"].toString() === item.id.toString()
        )[0]
      : {
          type: "process",
          name: `${item.attributes["title"]}`,
          attributes: {
            "process-id": item.id.toString(),
          },
          id: null,
        }
  );

  const [appProcess, setAppProcess] = useState(tempProcess);

  const tempOwners =
    editData?.relationships.owners.data.length > 0
      ? editData?.relationships.owners.data
          .filter(
            (elem) =>
              included?.filter(
                (owner) =>
                  owner.type === "owners" &&
                  owner.attributes["is-owners"] === true &&
                  owner.id.toString() === elem.id.toString()
              )[0]
          )
          .map((elem) => {
            let ownerData = included?.filter(
              (owner) =>
                owner.type === "owners" &&
                owner.id.toString() === elem.id.toString()
            )[0];
            return {
              type: "owners",
              name: `${ownerData?.attributes.employee.data.attributes["f-name"]} ${ownerData?.attributes.employee.data.attributes["l-name"]}`,
              attributes: {
                "employee-id": ownerData?.attributes["employee-id"],
                "is-owners": true,
                "email-id":
                  ownerData?.attributes.employee.data.attributes["email-id"],
                "profile-picture":
                  ownerData?.attributes.employee.data.attributes[
                    "profile-picture"
                  ],
              },
              id: ownerData?.id,
            };
          })
      : [];

  const [appOwners, setAppOwners] = useState(tempOwners);

  const tempDeputy =
    editData?.relationships.owners.data.length > 0
      ? editData?.relationships.owners.data
          .filter(
            (elem) =>
              included?.filter(
                (owner) =>
                  owner.type === "owners" &&
                  owner.attributes["is-owners"] === false &&
                  owner.id.toString() === elem.id.toString()
              )[0]
          )
          .map((elem) => {
            let ownerData = included?.filter(
              (owner) =>
                owner.type === "owners" &&
                owner.id.toString() === elem.id.toString()
            )[0];
            return {
              type: "owners",
              name: `${ownerData?.attributes.employee.data.attributes["f-name"]} ${ownerData?.attributes.employee.data.attributes["l-name"]}`,
              attributes: {
                "employee-id": ownerData?.attributes["employee-id"],
                "is-owners": false,
                "email-id":
                  ownerData?.attributes.employee.data.attributes["email-id"],
                "profile-picture":
                  ownerData?.attributes.employee.data.attributes[
                    "profile-picture"
                  ],
              },
              id: ownerData?.id,
            };
          })
      : [];
  const [appDeputy, setAppDeputy] = useState(tempDeputy);

  const [showModal, setShowModal] = useState(false);
  const [deputyModal, setDeputyModal] = useState(false);

  const [errors, setErrors] = useState({});

  const [fieldName, setFieldValue] = useState({
    app_name: editData?.attributes["app-title"],
    app_link: editData?.attributes["app-link"],
    app_desc: editData?.attributes["app-description"],
    app_img: "",
    img64: "",
    app_preview_img: "",
    preview64: "",
    app_owner: "",
    app_deputy: "",
    app_access: editData?.attributes["access-link"],
    app_org: editData?.attributes["app-org"].appOrg,
    app_info: "",
  });

  const handleComChange = async (value) => {
    let finalValue = [];
    value?.map((item) => {
      let newElem = {
        type: "community",
        name: item.name,
        attributes: {
          "community-id": item.attributes["community-id"].toString(),
        },
        id: item.id.toString() <= "0" ? null : item.id.toString(),
      };
      finalValue.push(newElem);
      return null;
    });

    setAppComs(finalValue);
  };
  const handleTrainChange = async (value) => {
    let finalValue = [];
    value?.map((item) => {
      let newElem = {
        type: "training",
        name: item.name,
        attributes: {
          "training-id": item.attributes["training-id"].toString(),
        },
        id: item.id.toString() <= "0" ? null : item.id.toString(),
      };
      finalValue.push(newElem);
      return null;
    });

    setAppTrainings(finalValue);
  };
  const handleProChange = async (value) => {
    let finalValue = [];
    value?.map((item) => {
      let newElem = {
        type: "process",
        name: item.name,
        attributes: {
          "process-id": item.attributes["process-id"].toString(),
        },
        id: item.id.toString() <= "0" ? null : item.id.toString(),
      };
      finalValue.push(newElem);
      return null;
    });

    setAppProcess(finalValue);
  };

  const handleAppOrgChange = (value) => {
    let finalValue = [];
    value?.map((item) => {
      let newElem = {
        name: item.name,
        id: item.id.toString(),
      };
      finalValue.push(newElem);
      return null;
    });

    setAppOrg(finalValue);
  };

  useEffect(() => {
    if (isDeleted) {
      imageData.current = null;
      tempAppImg.current = null;
      setIsDeleted(false);
    }
    if (isPreviewDeleted) {
      previewData.current = null;
      tempPreviewImg.current = null;
      setIsPreviewDeleted(false);
    }
  }, [isDeleted, isPreviewDeleted]);

  useEffect(() => {
    (async () => {
      const appData = JSON.parse(sessionStorage.getItem("appDetails"));
      if (appData) {
        let tempData = {
          app_name: appData?.app_title,
          app_link: appData?.app_link,
          app_desc: appData?.app_description,
          app_img: appData?.app_image,
          img64: appData?.image,
          app_preview_img: appData?.app_preview_image,
          preview64: appData?.preview_image,
          app_owner: JSON.parse(sessionStorage.getItem("appOwners")),
          app_deputy: JSON.parse(sessionStorage.getItem("appDeputy")),
          app_access: appData.access_link,
          app_org: appData?.app_org,
          app_info: appData?.app_info,
        };
        setFieldValue(tempData);
      }
      const res = await getData(
        `${getBaseUrl()}/configurations/orgLookup.json`
      );
      await setOrgsList(res.data.orgs);
    })();
  }, []);

  const getAppName = (name) => {
    appList?.map((element) => {
      if (
        element?.attributes["app-title"]?.toLowerCase() ===
        name?.trim()?.toLowerCase()
      ) {
        setNameErr(true);
      }
      return null;
    });
  };

  const findFormErrors = () => {
    const app_name = fieldName.app_name;
    const app_desc = fieldName.app_desc;
    const app_link = fieldName.app_link;
    const app_org = appOrg;
    const app_owners = appOwners;

    const newErrors = {};

    if (!app_name || app_name === "") newErrors.app_name = "Required*";
    if (!app_desc || app_desc === "") newErrors.app_desc = "Required*";
    if (!app_link || app_link === "") newErrors.app_link = "Required*";
    if (app_org.length === 0) newErrors.app_org = "Required*";
    if (app_owners.length === 0) newErrors.app_owners = "Required*";

    return newErrors;
  };

  const handleChange = (e) => {
    setFieldValue({
      ...fieldName,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = findFormErrors();

    if (fieldName.app_access === "" || fieldName.app_access === null) {
      appAccess.current = "";
    } else if (validator.isURL(fieldName.app_access)) {
      appAccess.current =
        fieldName.app_access.includes("https:") ||
        fieldName.app_access.includes("http:")
          ? fieldName.app_access
          : `https://${fieldName.app_access}`;
    } else {
      appAccess.current = fieldName.app_access;
    }

    const appInfoList =
      folderLinks.length > 0
        ? folderLinks.map((elem) => {
            return {
              type: "app-additional-info",
              attributes: {
                "info-title": elem.name,
                "info-URL": elem.link,
              },
              id: elem.id ? elem.id.toString() : null,
            };
          })
        : [];

    if (tempAppImg.current !== null && !imageData.current) {
      sendImageUrl.current = editData?.attributes["app-image"];
    } else if (imageData.current) {
      const base64 = await getBase64(imageData.current);
      base64Img.current = base64.split(",")?.pop();
      const imageHash = hash({ file: imageData.current, timestamp: now() });
      const extension = imageData.current.type?.split("/")?.pop();
      sendImageUrl.current = `/images/applications/app-image/${imageHash}.${extension}`;
    } else {
      base64Img.current = null;
      sendImageUrl.current = null;
    }

    if (tempPreviewImg.current !== null && !previewData.current) {
      sendPreviewUrl.current = editData?.attributes["app-preview-image"];
    } else if (previewData.current) {
      const base64 = await getBase64(previewData.current);
      base64Preview.current = base64.split(",")?.pop();
      const imageHash = hash({ file: previewData.current, timestamp: now() });
      const extension = previewData.current.type?.split("/")?.pop();
      sendPreviewUrl.current = `/images/applications/preview-image/${imageHash}.${extension}`;
    } else {
      base64Preview.current = null;
      sendPreviewUrl.current = null;
    }

    if (Object.keys(newErrors).length > 0 || nameErr === true) {
      setErrors(newErrors);
    } else {
      let finalPayload = {
        data: {
          type: "application",
          attributes: {
            "app-config": editData?.attributes["app-config"],
            "access-link": appAccess.current,
            "app-link":
              fieldName.app_link.includes("https:") ||
              fieldName.app_link.includes("http:")
                ? fieldName.app_link
                : `https://${fieldName.app_link}`,
            "app-org": { appOrg: appOrg },
            "app-title": fieldName.app_name,
            "app-description": fieldName.app_desc,
            "access-info": "",
            image: base64Img.current,
            "app-image": sendImageUrl.current,
            "preview-image": base64Preview.current,
            "app-preview-image": sendPreviewUrl.current,
          },

          relationships: {
            communities: {
              data: appComs && appComs,
            },

            owners: {
              data: appOwners && appDeputy && appOwners.concat(appDeputy),
            },

            "app-nodes": { data: appNodes },

            "app-info": {
              data: appInfoList,
            },
            trainings: {
              data: appTrainings && appTrainings,
            },
            process: {
              data: appProcess && appProcess,
            },
          },
        },
      };

      sessionStorage.setItem("newEditData", JSON.stringify(finalPayload));
      setLevel("editLayer");
    }

    return false;
  };

  const FolderLinksModal = (props) => {
    const [formValues, setFormValues] = useState(folderLinks);

    let handleChange = (i, e) => {
      let newFormValues = [...formValues];
      newFormValues[i][e.target.name] = e.target.value;
      setFormValues(newFormValues);
    };

    let addFormFields = () => {
      setFormValues([...formValues, { name: "", link: "" }]);
    };

    let removeFormFields = (i) => {
      let newFormValues = [...formValues];
      newFormValues.splice(i, 1);
      setFormValues(newFormValues);
    };

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Further Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="trainings-links-modal">
          {formValues.length > 0 ? (
            <Form>
              {formValues?.map((elem, index) => {
                return (
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label className="w-100">
                        <span className="w-100 d-flex justify-content-between">
                          <p className="mb-0">{index + 1}.Platform Name</p>

                          <p
                            className="app-tertiary-btn"
                            onClick={() => {
                              removeFormFields(index);
                            }}>
                            Remove
                          </p>
                        </span>
                      </Form.Label>
                      <div className="app-primary-input-field">
                        <Form.Control
                          placeholder="Enter Platform Name"
                          name="name"
                          value={elem.name}
                          onChange={(e) => handleChange(index, e)}
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        Required*
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Link</Form.Label>
                      <div className="app-primary-input-field">
                        <Form.Control
                          placeholder="Enter Platform Link"
                          name="link"
                          value={elem.link}
                          onChange={(e) => handleChange(index, e)}
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        Required*
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                );
              })}
            </Form>
          ) : (
            <p className="mb-0 fs-5">Add links to view them here.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-between w-100">
            <div>
              <Button
                className="app-primary-btn me-2"
                onClick={() => {
                  addFormFields();
                }}>
                Add
              </Button>
            </div>
            <div>
              <Button className="app-secondary-btn me-2" onClick={props.onHide}>
                Close
              </Button>
              <Button
                className="app-primary-btn"
                onClick={async () => {
                  let linksList = formValues?.map((elem) => {
                    return {
                      name: elem.name,
                      link:
                        elem.link.includes("https:") ||
                        elem.link.includes("http:")
                          ? elem.link
                          : `https://${elem.link}`,
                    };
                  });

                  await setFolderLinks(linksList);
                  setFieldValue({
                    ...fieldName,
                    app_info: linksList,
                  });
                  props.onHide();
                }}>
                Add Links
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <div className="add-app-form">
      <span className="w-100 d-flex justify-content-between mb-3">
        <h4 className="fw-bold mt-2">Edit Application</h4>
      </span>
      <Form>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Application Name</Form.Label>
            <div className="app-primary-input-field">
              <Form.Control
                className="form-text-field"
                placeholder="Enter Application Name"
                value={fieldName.app_name}
                name="app_name"
                onChange={(e) => (
                  // eslint-disable-next-line
                  setNameErr(false),
                  getAppName(e.target.value),
                  handleChange(e),
                  !!errors ? setErrors(fieldName.app_name) : ""
                )}
                isInvalid={!!errors.app_name}
              />
            </div>
            {errors.app_name ? (
              <p className="m-0 text-danger" style={{ fontSize: "14.5px" }}>
                Required*
              </p>
            ) : nameErr === true ? (
              <p className="m-0 text-danger" style={{ fontSize: "14.5px" }}>
                Name already exists
              </p>
            ) : null}
          </Form.Group>

          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>Application Link</Form.Label>
            <div className="app-primary-input-field">
              <Form.Control
                className="form-text-field"
                placeholder="Enter Application Link"
                value={fieldName.app_link}
                name="app_link"
                onChange={(e) => (
                  // eslint-disable-next-line
                  handleChange(e), !!errors ? setErrors(fieldName.app_link) : ""
                )}
                isInvalid={!!errors.app_link}
              />
            </div>
            {errors.app_link ? (
              <p className="m-0 text-danger" style={{ fontSize: "14.5px" }}>
                Required*
              </p>
            ) : null}
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Application Description</Form.Label>
            <div className="app-primary-input-field">
              <Form.Control
                className="form-text-field"
                as="textarea"
                rows={5}
                placeholder="Enter Application Description"
                value={fieldName.app_desc}
                name="app_desc"
                onChange={(e) => (
                  // eslint-disable-next-line
                  handleChange(e), !!errors ? setErrors(fieldName.app_desc) : ""
                )}
                isInvalid={!!errors.app_desc}
              />
            </div>
            {errors.app_desc ? (
              <p className="m-0 text-danger" style={{ fontSize: "14.5px" }}>
                Required*
              </p>
            ) : null}
          </Form.Group>

          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Select Corporate Function</Form.Label>
            <div className="app-primary-dropdown">
              <MultipleSelectDropdown
                multi={true}
                options={
                  orgsList &&
                  orgsList?.map((item) => ({
                    name: item.name,
                    id: item.id.toString(),
                  }))
                }
                onChange={handleAppOrgChange}
                values={appOrg}
                className="dropdown-select"
              />
            </div>
            {errors.app_org ? (
              <p className="m-0 text-danger" style={{ fontSize: "14.5px" }}>
                Required*
              </p>
            ) : null}

            <Form.Label className="w-100">
              <span className="w-100 d-flex justify-content-between mt-4">
                <p className="mb-0">Further Information</p>
                <p
                  className="app-tertiary-btn"
                  onClick={() => {
                    setModalShow(true);
                  }}>
                  Add New
                </p>
              </span>
            </Form.Label>
            <div className="app-primary-input-field">
              <Form.Control
                disabled
                value={folderLinks && folderLinks.map((link) => link.name)}
                placeholder="Further Information"
              />
            </div>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} className="mb-4" controlId="validationCustom01">
            <Form.Label className="d-flex justify-content-between">
              <p className="my-auto">Select Owners</p>
              <Button
                className="app-icon-primary-btn"
                onClick={() => {
                  setShowModal(true);
                }}>
                +
              </Button>
              Button
            </Form.Label>
            <div className="app-primary-input-field">
              <Form.Control
                required
                disabled
                type="input"
                placeholder="Select..."
                className="text-field"
                value={
                  appOwners?.length === 0
                    ? ""
                    : appOwners?.length > 0 && appOwners?.length <= 3
                    ? `${appOwners && appOwners?.map((item) => item.name)}`
                    : appOwners?.length > 3
                    ? `${
                        appOwners &&
                        appOwners?.slice(0, 3)?.map((item) => item.name)
                      } + ${appOwners?.length - 3} more...`
                    : null
                }
              />
            </div>
            {errors.app_owners ? (
              <p className="m-0 text-danger" style={{ fontSize: "14.5px" }}>
                Required*
              </p>
            ) : null}
          </Form.Group>

          <Form.Group as={Col} className="mb-4" controlId="validationCustom01">
            <Form.Label className="d-flex justify-content-between">
              <p className="my-auto">Select Deputies</p>
              <Button
                className="app-icon-primary-btn"
                onClick={() => {
                  setDeputyModal(true);
                }}>
                +
              </Button>
            </Form.Label>
            <div className="app-primary-input-field">
              <Form.Control
                disabled
                required
                type="input"
                placeholder="Select..."
                className="text-field"
                value={
                  appDeputy?.length === 0
                    ? ""
                    : appDeputy?.length > 0 && appDeputy?.length <= 3
                    ? `${appDeputy && appDeputy?.map((item) => item.name)}`
                    : appDeputy?.length > 3
                    ? `${
                        appDeputy &&
                        appDeputy?.slice(0, 3)?.map((item) => item.name)
                      } + ${appDeputy?.length - 3} more...`
                    : null
                }
              />
            </div>
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Upload Application Image</Form.Label>
            <ImageUpload
              className="main-container"
              editImage={editData.attributes["app-image"]}
              setIsDeleted={setIsDeleted}
              isAppImage={true}
              onDrop={(files) => {
                imageData.current = files[0];
              }}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Upload Dashboard Image</Form.Label>
            <ImageUpload
              className="main-container"
              editImage={editData?.attributes["app-preview-image"]}
              setIsPreviewDeleted={setIsPreviewDeleted}
              isAppImage={false}
              onDrop={(files) => {
                previewData.current = files[0];
              }}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>Access Authorization</Form.Label>
            <div className="app-primary-input-field">
              <Form.Control
                className="form-text-field"
                placeholder="Enter Access Authorization Link"
                value={fieldName.app_access}
                name="app_access"
                onChange={(e) => (
                  // eslint-disable-next-line
                  handleChange(e),
                  !!errors ? setErrors(fieldName.app_access) : ""
                )}
              />
            </div>
          </Form.Group>

          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Processes Connections</Form.Label>
            <div className="app-primary-dropdown">
              <MultipleSelectDropdown
                multi={true}
                options={
                  processOptions &&
                  processOptions
                    ?.sort((a, b) => a.name.localeCompare(b.name))
                    .map((item, index) => ({
                      type: "process",
                      name: item.name,
                      attributes: {
                        "process-id": item.attributes["process-id"].toString(),
                      },
                      id: item.id
                        ? item.id.toString()
                        : (index - index * 2).toString(),
                    }))
                }
                onChange={handleProChange}
                values={appProcess}
                className="dropdown-select"
              />
            </div>
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Community Connections</Form.Label>
            <div className="app-primary-dropdown">
              <MultipleSelectDropdown
                multi={true}
                options={
                  commOptions &&
                  commOptions
                    ?.sort((a, b) => a.name.localeCompare(b.name))
                    .map((item, index) => ({
                      type: "community",
                      name: item.name,
                      attributes: {
                        "community-id":
                          item.attributes["community-id"].toString(),
                      },
                      id: item.id
                        ? item.id.toString()
                        : (index - index * 2).toString(),
                    }))
                }
                onChange={handleComChange}
                values={appComs}
                className="dropdown-select"
              />
            </div>
          </Form.Group>

          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>Training Connections</Form.Label>
            <div className="app-primary-dropdown">
              <MultipleSelectDropdown
                multi={true}
                options={
                  trainOptions &&
                  trainOptions
                    ?.sort((a, b) => a.name.localeCompare(b.name))
                    .map((item, index) => ({
                      type: "training",
                      name: item.name,
                      attributes: {
                        "training-id":
                          item.attributes["training-id"].toString(),
                      },
                      id: item.id
                        ? item.id.toString()
                        : (index - index * 2).toString(),
                    }))
                }
                onChange={handleTrainChange}
                values={appTrainings}
                className="dropdown-select"
              />
            </div>
          </Form.Group>
        </Row>

        <div className="w-100 d-flex justify-content-end">
          <Button
            className="app-primary-btn"
            variant="primary"
            type="submit"
            onClick={async (e) => {
              await handleSubmit(e);
            }}>
            Next Step
          </Button>
        </div>
      </Form>

      {modalShow ? (
        <FolderLinksModal show={modalShow} onHide={() => setModalShow(false)} />
      ) : null}

      {deputyModal === true ? (
        <EmployeeModal
          show={setDeputyModal}
          handleClose={() => {
            setDeputyModal(false);
          }}
          getMembers={appDeputy}
          getExisting={appOwners}
          title="Select Application Deputies"
          setMember={setAppDeputy}
          type="owners"
          buttonTitle={"Add Deputies"}
          is_owners={false}
          role="is-owners"
        />
      ) : null}

      {showModal === true ? (
        <EmployeeModal
          show={showModal}
          handleClose={() => {
            setShowModal(false);
          }}
          getMembers={appOwners}
          getExisting={appDeputy}
          title="Select Application Owners"
          setMember={setAppOwners}
          type="owners"
          buttonTitle={"Add Owners"}
          is_owners={true}
          role="is-owners"
        />
      ) : null}
    </div>
  );
};

export const AddApp = ({ appList, comData, trainData, proData, setLevel }) => {
  const appData = JSON.parse(sessionStorage.getItem("appDetails"));

  const hash = require("object-hash");
  const [modalShow, setModalShow] = useState(false);
  const [folderLinks, setFolderLinks] = useState([]);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isPreviewDeleted, setIsPreviewDeleted] = useState(false);
  const [orgsList, setOrgsList] = useState();
  const [nameErr, setNameErr] = useState(false);
  const imageData = useRef(null);
  const previewData = useRef(null);
  const sendImageUrl = useRef(null);
  const base64Img = useRef(null);
  const sendPreviewUrl = useRef(null);
  const base64Preview = useRef(null);
  const appAccess = useRef(null);
  const [appOrg, setAppOrg] = useState(appData ? appData.app_org : []);
  const [appComs, setAppComs] = useState(
    JSON.parse(sessionStorage.getItem("appComs"))
      ? JSON.parse(sessionStorage.getItem("appComs"))
      : []
  );
  const [appTrainings, setAppTrainings] = useState(
    JSON.parse(sessionStorage.getItem("appTrainings"))
      ? JSON.parse(sessionStorage.getItem("appTrainings"))
      : []
  );
  const [appProcess, setAppProcess] = useState(
    JSON.parse(sessionStorage.getItem("appProcess"))
      ? JSON.parse(sessionStorage.getItem("appProcess"))
      : []
  );

  const [appOwners, setAppOwners] = useState(
    JSON.parse(sessionStorage.getItem("appOwners"))
      ? JSON.parse(sessionStorage.getItem("appOwners"))
      : []
  );

  const [appDeputy, setAppDeputy] = useState(
    JSON.parse(sessionStorage.getItem("appDeputy"))
      ? JSON.parse(sessionStorage.getItem("appDeputy"))
      : []
  );

  const [showModal, setShowModal] = useState(false);
  const [deputyModal, setDeputyModal] = useState(false);

  const [errors, setErrors] = useState({});

  const [fieldName, setFieldValue] = useState({
    app_name: "",
    app_link: "",
    app_desc: "",
    app_img: "",
    img64: "",
    app_preview_img: "",
    preview64: "",
    app_owner: "",
    app_deputy: "",
    app_access: "",
    app_org: appOrg,
    app_info: {
      wiki_link: "",
      digi_link: "",
      yammer_link: "",
    },
  });

  useEffect(() => {
    if (isDeleted) {
      imageData.current = null;
      setIsDeleted(false);
    }
    if (isPreviewDeleted) {
      previewData.current = null;
      setIsPreviewDeleted(false);
    }
  }, [isDeleted, isPreviewDeleted]);

  const handleComChange = async (value) => {
    let finalValue = [];
    value?.map((item) => {
      let newElem = {
        type: "community",
        name: item.name,
        attributes: {
          "community-id": item.id.toString(),
        },
        id: null,
      };
      finalValue.push(newElem);
      return null;
    });

    setAppComs(finalValue);
  };
  const handleTrainChange = async (value) => {
    let finalValue = [];
    value?.map((item) => {
      let newElem = {
        type: "training",
        name: item.name,
        attributes: {
          "training-id": item.id.toString(),
        },
        id: null,
      };
      finalValue.push(newElem);
      return null;
    });

    setAppTrainings(finalValue);
  };
  const handleProChange = async (value) => {
    let finalValue = [];
    value?.map((item) => {
      let newElem = {
        type: "process",
        name: item.name,
        attributes: {
          "process-id": item.id.toString(),
        },
        id: null,
      };
      finalValue.push(newElem);
      return null;
    });

    setAppProcess(finalValue);
  };

  const handleAppOrgChange = (value) => {
    let finalValue = [];
    value?.map((item) => {
      let newElem = {
        name: item.name,
        id: item.id.toString(),
      };
      finalValue.push(newElem);
      return null;
    });

    setAppOrg(finalValue);
  };

  useEffect(() => {
    (async () => {
      if (appData) {
        let tempData = {
          app_name: appData?.app_title,
          app_link: appData?.app_link,
          app_desc: appData?.app_description,
          app_img: appData?.app_image,
          img64: appData?.image,
          app_preview_img: appData?.app_preview_image,
          preview64: appData?.preview_image,
          app_owner: JSON.parse(sessionStorage.getItem("appOwners")),
          app_deputy: JSON.parse(sessionStorage.getItem("appDeputy")),
          app_access: appData.access_link,
          app_org: appData?.app_org,
          app_info: appData?.app_info,
        };
        setFieldValue(tempData);
      }
      const res = await getData(
        `${getBaseUrl()}/configurations/orgLookup.json`
      );
      await setOrgsList(res.data.orgs);
    })();
    // eslint-disable-next-line
  }, []);

  const getAppName = (name) => {
    appList?.map((element) => {
      if (
        element?.attributes["app-title"]?.toLowerCase() ===
        name?.trim()?.toLowerCase()
      ) {
        setNameErr(true);
      }
      return null;
    });
  };

  const findFormErrors = () => {
    const app_name = fieldName.app_name;
    const app_desc = fieldName.app_desc;
    const app_link = fieldName.app_link;
    const app_org = appOrg;
    const app_owners = appOwners;

    const newErrors = {};

    if (!app_name || app_name === "") newErrors.app_name = "Required*";
    if (!app_desc || app_desc === "") newErrors.app_desc = "Required*";
    if (!app_link || app_link === "") newErrors.app_link = "Required*";
    if (app_org.length === 0) newErrors.app_org = "Required*";
    if (app_owners.length === 0) newErrors.app_owners = "Required*";

    return newErrors;
  };

  const handleChange = (e) => {
    setFieldValue({
      ...fieldName,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = findFormErrors();

    if (isDeleted) {
      imageData.current = null;
    }
    if (isPreviewDeleted) {
      previewData.current = null;
    }

    if (fieldName.app_access === "" || fieldName.app_access === null) {
      appAccess.current = "";
    } else if (validator.isURL(fieldName.app_access)) {
      appAccess.current =
        fieldName.app_access.includes("https:") ||
        fieldName.app_access.includes("http:")
          ? fieldName.app_access
          : `https://${fieldName.app_access}`;
    } else {
      appAccess.current = fieldName.app_access;
    }

    const appInfoList =
      folderLinks.length > 0
        ? folderLinks.map((elem) => {
            return {
              type: "app-additional-info",
              attributes: {
                "info-title": elem.name,
                "info-URL": elem.link,
              },

              id: null,
            };
          })
        : [];

    if (imageData.current) {
      const base64 = await getBase64(imageData.current);
      base64Img.current = base64.split(",")?.pop();
      const imageHash = hash({ file: imageData.current, timestamp: now() });
      const extension = imageData.current.type?.split("/")?.pop();
      sendImageUrl.current = `/images/applications/app-image/${imageHash}.${extension}`;
    } else {
      base64Img.current = null;
      sendImageUrl.current = null;
    }
    if (previewData.current) {
      const base64 = await getBase64(previewData.current);
      base64Preview.current = base64.split(",")?.pop();
      const imageHash = hash({ file: previewData.current, timestamp: now() });
      const extension = previewData.current.type?.split("/")?.pop();
      sendPreviewUrl.current = `/images/applications/preview-image/${imageHash}.${extension}`;
    } else {
      base64Preview.current = null;
      sendPreviewUrl.current = null;
    }
    if (Object.keys(newErrors).length > 0 || nameErr === true) {
      setErrors(newErrors);
    } else {
      const finalData = {
        app_title: fieldName.app_name,
        app_org: appOrg,
        app_description: fieldName.app_desc,
        access_info: "",
        access_link: appAccess.current,
        app_link:
          fieldName.app_link.includes("https:") ||
          fieldName.app_link.includes("http:")
            ? fieldName.app_link
            : `https://${fieldName.app_link}`,
        app_config: fieldName.app_config,
        app_image: sendImageUrl.current,
        image: base64Img.current,
        app_preview_image: sendPreviewUrl.current,
        preview_image: base64Preview.current,
        owners: appOwners && appDeputy && appOwners.concat(appDeputy),
        app_info: appInfoList,
      };

      sessionStorage.setItem("appDetails", JSON.stringify(finalData));
      sessionStorage.setItem("appOwners", JSON.stringify(appOwners));
      sessionStorage.setItem("appDeputy", JSON.stringify(appDeputy));
      sessionStorage.setItem("appComs", JSON.stringify(appComs));
      sessionStorage.setItem("appTrainings", JSON.stringify(appTrainings));
      sessionStorage.setItem("appProcess", JSON.stringify(appProcess));
      setLevel("addLayer");
    }

    return false;
  };

  const FolderLinksModal = (props) => {
    const [formValues, setFormValues] = useState(folderLinks);

    let handleChange = (i, e) => {
      let newFormValues = [...formValues];
      newFormValues[i][e.target.name] = e.target.value;
      setFormValues(newFormValues);
    };

    let addFormFields = () => {
      setFormValues([...formValues, { name: "", link: "" }]);
    };

    let removeFormFields = (i) => {
      let newFormValues = [...formValues];
      newFormValues.splice(i, 1);
      setFormValues(newFormValues);
    };

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Further Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="trainings-links-modal">
          {formValues.length > 0 ? (
            <Form>
              {formValues?.map((elem, index) => {
                return (
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label className="w-100">
                        <span className="w-100 d-flex justify-content-between">
                          <p className="mb-0">{index + 1}.Platform Name</p>

                          <p
                            className="app-tertiary-btn"
                            onClick={() => {
                              removeFormFields(index);
                            }}>
                            Remove
                          </p>
                        </span>
                      </Form.Label>
                      <div className="app-primary-input-field">
                        <Form.Control
                          placeholder="Enter Platform Name"
                          name="name"
                          value={elem.name}
                          onChange={(e) => handleChange(index, e)}
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        Required*
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Link</Form.Label>
                      <div className="app-primary-input-field">
                        <Form.Control
                          placeholder="Enter Platform Link"
                          name="link"
                          value={elem.link}
                          onChange={(e) => handleChange(index, e)}
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        Required*
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                );
              })}
            </Form>
          ) : (
            <p className="mb-0 fs-5">Add links to view them here.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-between w-100">
            <div>
              <Button
                className="app-primary-btn me-2"
                onClick={() => {
                  addFormFields();
                }}>
                Add
              </Button>
            </div>
            <div>
              <Button className="app-secondary-btn me-2" onClick={props.onHide}>
                Close
              </Button>
              <Button
                className="app-primary-btn"
                onClick={async () => {
                  let linksList = formValues?.map((elem) => {
                    return {
                      name: elem.name,
                      link:
                        elem.link.includes("https:") ||
                        elem.link.includes("http:")
                          ? elem.link
                          : `https://${elem.link}`,
                    };
                  });
                  await setFolderLinks(linksList);
                  setFieldValue({
                    ...fieldName,
                    app_info: linksList,
                  });
                  props.onHide();
                }}>
                Add Links
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <div className="add-app-form">
      <span className="w-100 d-flex justify-content-between mb-3">
        <h4 className="fw-bold mt-2">Add New Application</h4>
        <span>
          <ImageSvgs name="progress_1" />
        </span>
      </span>
      <Form>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Application Name</Form.Label>
            <div className="app-primary-input-field">
              <Form.Control
                className="form-text-field"
                placeholder="Enter Application Name"
                value={fieldName.app_name}
                name="app_name"
                onChange={(e) => (
                  // eslint-disable-next-line
                  setNameErr(false),
                  getAppName(e.target.value),
                  handleChange(e),
                  !!errors ? setErrors(fieldName.app_name) : ""
                )}
                isInvalid={!!errors.app_name}
              />
            </div>
            {errors.app_name ? (
              <p className="m-0 text-danger" style={{ fontSize: "14.5px" }}>
                Required*
              </p>
            ) : nameErr === true ? (
              <p className="m-0 text-danger" style={{ fontSize: "14.5px" }}>
                Name already exists
              </p>
            ) : null}
          </Form.Group>

          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>Application Link</Form.Label>
            <div className="app-primary-input-field">
              <Form.Control
                className="form-text-field"
                placeholder="Enter Application Link"
                value={fieldName.app_link}
                name="app_link"
                onChange={(e) => (
                  // eslint-disable-next-line
                  handleChange(e), !!errors ? setErrors(fieldName.app_link) : ""
                )}
                isInvalid={!!errors.app_link}
              />
            </div>
            {errors.app_link ? (
              <p className="m-0 text-danger" style={{ fontSize: "14.5px" }}>
                Required*
              </p>
            ) : null}
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Application Description</Form.Label>
            <div className="app-primary-input-field">
              <Form.Control
                className="form-text-field"
                as="textarea"
                rows={5}
                placeholder="Enter Application Description"
                value={fieldName.app_desc}
                name="app_desc"
                onChange={(e) => (
                  // eslint-disable-next-line
                  handleChange(e), !!errors ? setErrors(fieldName.app_desc) : ""
                )}
                isInvalid={!!errors.app_desc}
              />
            </div>
            {errors.app_desc ? (
              <p className="m-0 text-danger" style={{ fontSize: "14.5px" }}>
                Required*
              </p>
            ) : null}
          </Form.Group>

          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Select Corporate Function</Form.Label>
            <div className="app-primary-dropdown">
              <MultipleSelectDropdown
                multi={true}
                options={
                  orgsList &&
                  orgsList?.map((item) => ({
                    name: item.name,
                    id: item.id.toString(),
                  }))
                }
                onChange={handleAppOrgChange}
                values={appOrg}
                className="dropdown-select"
              />
            </div>
            {errors.app_org ? (
              <p className="m-0 text-danger" style={{ fontSize: "14.5px" }}>
                Required*
              </p>
            ) : null}

            <Form.Label className="w-100">
              <span className="w-100 d-flex justify-content-between mt-4">
                <p className="mb-0">Further Information</p>
                <p
                  className="app-tertiary-btn"
                  onClick={() => {
                    setModalShow(true);
                  }}>
                  Add New
                </p>
              </span>
            </Form.Label>
            <div className="app-primary-input-field">
              <Form.Control
                disabled
                value={folderLinks && folderLinks.map((link) => link.name)}
                placeholder="Further Information"
              />
            </div>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} className="mb-4" controlId="validationCustom01">
            <Form.Label className="d-flex justify-content-between">
              <p className="my-auto">Select Owners</p>
              <Button
                className="app-icon-primary-btn"
                onClick={() => {
                  setShowModal(true);
                }}
                style={{ cursor: "pointer" }}>
                +
              </Button>
            </Form.Label>
            <div className="app-primary-input-field">
              <Form.Control
                required
                disabled
                type="input"
                placeholder="Select..."
                className="text-field"
                value={
                  appOwners?.length.toString() === 0
                    ? ""
                    : appOwners?.length > 0 && appOwners?.length <= 3
                    ? `${appOwners && appOwners?.map((item) => item.name)}`
                    : appOwners?.length > 3
                    ? `${
                        appOwners &&
                        appOwners?.slice(0, 3)?.map((item) => item.name)
                      } + ${appOwners?.length - 3} more...`
                    : null
                }
              />
            </div>
            {errors.app_owners ? (
              <p className="m-0 text-danger" style={{ fontSize: "14.5px" }}>
                Required*
              </p>
            ) : null}
          </Form.Group>

          <Form.Group as={Col} className="mb-4" controlId="validationCustom01">
            <Form.Label className="d-flex justify-content-between">
              <p className="my-auto">Select Deputies</p>
              <Button
                className="app-icon-primary-btn"
                onClick={() => {
                  setDeputyModal(true);
                }}
                style={{ cursor: "pointer" }}>
                +
              </Button>
            </Form.Label>
            <div className="app-primary-input-field">
              <Form.Control
                disabled
                required
                type="input"
                placeholder="Select..."
                className="text-field"
                value={
                  appDeputy?.length === 0
                    ? ""
                    : appDeputy?.length > 0 && appDeputy?.length <= 3
                    ? `${appDeputy && appDeputy?.map((item) => item.name)}`
                    : appDeputy?.length > 3
                    ? `${
                        appDeputy &&
                        appDeputy?.slice(0, 3)?.map((item) => item.name)
                      } + ${appDeputy?.length - 3} more...`
                    : null
                }
              />
            </div>
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Upload Application Image</Form.Label>
            <ImageUpload
              className="main-container"
              editImage={null}
              setIsDeleted={setIsDeleted}
              isAppImage={true}
              onDrop={(files) => {
                imageData.current = files[0];
              }}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Upload Dashboard Image</Form.Label>
            <ImageUpload
              className="main-container"
              editImage={null}
              setIsPreviewDeleted={setIsPreviewDeleted}
              isAppImage={false}
              onDrop={(files) => {
                previewData.current = files[0];
              }}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>Access Authorization</Form.Label>
            <div className="app-primary-input-field">
              <Form.Control
                className="form-text-field"
                placeholder="Enter Access Authorization Link"
                value={fieldName.app_access}
                name="app_access"
                onChange={(e) => (
                  // eslint-disable-next-line
                  handleChange(e),
                  !!errors ? setErrors(fieldName.app_access) : ""
                )}
              />
            </div>
          </Form.Group>
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Processes Connections</Form.Label>
            <div className="app-primary-dropdown">
              <MultipleSelectDropdown
                multi={true}
                options={
                  proData &&
                  proData
                    ?.sort((a, b) =>
                      a.attributes["title"].localeCompare(b.attributes["title"])
                    )
                    .map((item) => ({
                      type: "process",
                      name: `${item.attributes["title"]}`,
                      id: item.id.toString(),
                    }))
                }
                onChange={handleProChange}
                values={appProcess}
                className="dropdown-select"
              />
            </div>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Community Connections</Form.Label>
            <div className="app-primary-dropdown">
              <MultipleSelectDropdown
                multi={true}
                options={
                  comData &&
                  comData
                    ?.sort((a, b) =>
                      a.attributes["community-name"].localeCompare(
                        b.attributes["community-name"]
                      )
                    )
                    .map((item) => ({
                      type: "community",
                      name: `${item.attributes["community-name"]}`,
                      id: item.id.toString(),
                    }))
                }
                onChange={handleComChange}
                values={appComs}
                className="dropdown-select"
              />
            </div>
          </Form.Group>

          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>Training Connections</Form.Label>
            <div className="app-primary-dropdown">
              <MultipleSelectDropdown
                multi={true}
                options={
                  trainData &&
                  trainData
                    ?.sort((a, b) =>
                      a.attributes["training-name"].localeCompare(
                        b.attributes["training-name"]
                      )
                    )
                    .map((item) => ({
                      type: "training",
                      name: `${item.attributes["training-name"]}`,
                      id: item.id.toString(),
                    }))
                }
                onChange={handleTrainChange}
                values={appTrainings}
                className="dropdown-select"
              />
            </div>
          </Form.Group>
        </Row>

        <div className="w-100 d-flex justify-content-end">
          <Button
            className="app-primary-btn"
            variant="primary"
            type="submit"
            onClick={async (e) => {
              await handleSubmit(e);
            }}>
            Next Step
          </Button>
        </div>
      </Form>

      {modalShow ? (
        <FolderLinksModal show={modalShow} onHide={() => setModalShow(false)} />
      ) : null}

      {deputyModal === true ? (
        <EmployeeModal
          show={setDeputyModal}
          handleClose={() => {
            setDeputyModal(false);
          }}
          getMembers={appDeputy}
          getExisting={appOwners}
          title="Select Application Deputies"
          setMember={setAppDeputy}
          type="owners"
          buttonTitle={"Add Deputies"}
          is_owners={false}
          role="is-owners"
        />
      ) : null}

      {showModal === true ? (
        <EmployeeModal
          show={showModal}
          handleClose={() => {
            setShowModal(false);
          }}
          getMembers={appOwners}
          getExisting={appDeputy}
          title="Select Application Owners"
          setMember={setAppOwners}
          type="owners"
          buttonTitle={"Add Owners"}
          is_owners={true}
          role="is-owners"
        />
      ) : null}
    </div>
  );
};

export const DeleteAppModal = (props) => {
  const [loading, setLoading] = useState(false);

  const deleteApp = async (id) => {
    try {
      setLoading(true);
      // eslint-disable-next-line
      const res = await deleteData(`${getHostUrl()}application/${id}`);
      setLoading(false);
      props.onHide();
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Delete Application
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Name: {props.appdata.attributes["app-title"]}</h5>
        <p>
          Are you sure you want to delete this application? All the connections
          of this application within FIN GS would be lost.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button id="common-favbutton" onClick={props.onHide}>
          Cancel
        </Button>
        <Button
          id="common-favbutton"
          onClick={() => {
            deleteApp(props.appdata.id);
          }}>
          {loading ? "Deleting..." : "Delete"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
