import React from "react";
import { createRoot } from "react-dom/client";
import NotificationAlert from "./NotificationAlert";

export function showNotificationAlert(type, message) {
  const alertEl = document.createElement("div");
  document.body.appendChild(alertEl);

  function destroy() {
    if (alertEl.parentNode) {
      alertEl.parentNode.removeChild(alertEl);
    }
  }

  const root = createRoot(alertEl);
  root.render(
    <NotificationAlert type={type} message={message} destroy={destroy} />
  );
}
