import React from "react";
import { useState, useEffect } from "react";
import Avatar from "react-avatar";
import { Stack, Carousel } from "react-bootstrap";
import ConnectionsComponent from "./ConnectionsComponent";
import { nextArrow, prevArrow } from "../../utils/iconUtils";
import ImageSvgs from "../ImageSvgs";
import Loader from "../Loader/Loader";
import { getHostUrl, getBaseUrl } from "../../config/environment";
import { getData } from "../../services/apiService";
import { chunk } from "lodash";
import { Link } from "react-router-dom";
import moment from "moment-timezone";

const EmployeeRelations = () => {
  var tzlookup = require("tz-lookup");
  const [empData, setEmpData] = useState();
  const [cosmosLink, setCosmosLink] = useState("");
  const [time, setTime] = useState();
  const [orgFlag, setOrgFlag] = useState();
  const [location, setLocation] = useState();

  let tempAppsData =
    empData?.data.relationships.apps.data.length > 0
      ? empData?.data.relationships.apps.data.map((elem) => {
          return empData?.included.filter(
            // eslint-disable-next-line
            (app) =>
              app.type === "application" &&
              app.id.toString() === elem.id.toString()
          )[0];
        })
      : [];

  let appsData =
    tempAppsData.length > 0
      ? chunk(
          tempAppsData.sort((a, b) =>
            a.attributes["app-title"].localeCompare(b.attributes["app-title"])
          ),
          5
        )
      : [];

  let tempToolsData =
    empData?.data.relationships.tools.data.length > 0
      ? empData?.data.relationships.tools.data.map((elem) => {
          return empData?.included.filter(
            // eslint-disable-next-line
            (tool) =>
              tool.type === "tool" && tool.id.toString() === elem.id.toString()
          )[0];
        })
      : [];

  let toolsData =
    tempToolsData.length > 0
      ? chunk(
          tempToolsData.sort((a, b) =>
            a.attributes["tool-name"].localeCompare(b.attributes["tool-name"])
          ),
          5
        )
      : [];

  let tempCommData =
    empData?.data.relationships["community-member"].data.length > 0
      ? empData?.data.relationships["community-member"].data.map((elem) => {
          return empData?.included.filter(
            // eslint-disable-next-line
            (com) =>
              com.type === "community" &&
              com.id.toString() === elem.id.toString()
          )[0];
        })
      : [];

  let commData =
    tempCommData.length > 0
      ? chunk(
          tempCommData.sort((a, b) =>
            a.attributes["community-name"].localeCompare(
              b.attributes["community-name"]
            )
          ),
          5
        )
      : [];

  let tempProData =
    empData?.data.relationships.processes.data.length > 0
      ? empData?.data.relationships.processes.data.map((elem) => {
          return empData?.included.filter(
            // eslint-disable-next-line
            (process) =>
              process.type === "process" &&
              process.id.toString() === elem.id.toString()
          )[0];
        })
      : [];

  let proData =
    tempProData.length > 0
      ? chunk(
          tempProData.sort((a, b) =>
            a.attributes["title"].localeCompare(b.attributes["title"])
          ),
          5
        )
      : [];

  let tempTrainData =
    empData?.data.relationships["trainers-data"].data.length > 0
      ? empData?.data.relationships["trainers-data"].data.map((elem) => {
          return empData?.included.filter(
            // eslint-disable-next-line
            (train) =>
              train.type === "training" &&
              train.id.toString() === elem.id.toString()
          )[0];
        })
      : [];

  let trainData =
    tempTrainData.length > 0
      ? chunk(
          tempTrainData.sort((a, b) =>
            a.attributes["training-name"].localeCompare(
              b.attributes["training-name"]
            )
          ),
          5
        )
      : [];

  let tempTopicData =
    empData?.data.relationships.topics.data.length > 0
      ? empData?.data.relationships.topics.data.map((elem) => {
          return empData?.included.filter(
            // eslint-disable-next-line
            (topic) =>
              topic.type === "topics" &&
              topic.id.toString() === elem.id.toString()
          )[0];
        })
      : [];

  let topicData =
    tempTopicData.length > 0
      ? chunk(
          tempTopicData.sort((a, b) =>
            a.attributes["topics-name"].localeCompare(
              b.attributes["topics-name"]
            )
          ),
          5
        )
      : [];

  useEffect(() => {
    (async () => {
      const url = new URL(window.location.href);
      const param = `${getHostUrl()}employee/${url.searchParams.get(
        "id"
      )}?include=apps,trainers_data,community_head,community_member,processes,tools,topics,location`;
      const usersRes = await getData(param);
      await setOrgFlag(
        usersRes.data.data.attributes["employee-org-master"].data.attributes[
          "org-chart-flag"
        ]
      );
      await setEmpData(usersRes.data);

      const locationData = usersRes.data.included?.filter(
        (elem) =>
          elem.type === "location" &&
          elem.id?.toString() ===
            usersRes.data.data.relationships.location.data.id?.toString()
      )[0];
      setLocation(locationData);

      const [latitude, longitude] = locationData?.attributes?.["office-gps"]
        .split(";")
        .map((part) => part.trim());

      const latValue = parseFloat(latitude.split(" ")[1]);
      const lonValue = parseFloat(longitude.split(" ")[1]);

      const lat = latitude.startsWith("N") ? latValue : -latValue;
      const long = longitude.startsWith("E") ? lonValue : -lonValue;

      const timezone = tzlookup(lat?.toString(), long?.toString());

      const localTime = moment.tz(timezone && timezone).format("HH:mm");
      await setTime(localTime);

      const res = await getData(
        `${getBaseUrl()}/configurations/cosmosLink.json`
      );
      await setCosmosLink(res.data.link);
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {empData ? (
        <div className="d-flex flex-column mt-3">
          <div className="d-flex flex-row mb-3">
            <div className="mt-1 d-flex">
              {empData?.data.attributes["profile-picture"] ? (
                <img
                  src={`${getBaseUrl()}${
                    empData?.data.attributes["profile-picture"]
                  }`}
                  style={{
                    height: "5rem",
                    width: "5rem",
                    border: "1.5px solid black",
                    borderRadius: "7rem",
                    objectFit: "contain",
                  }}
                  alt="User Img"
                />
              ) : (
                <Avatar
                  name={empData?.data.attributes["preferred-name"]}
                  size="80"
                  round={true}
                  textSizeRatio={3}
                />
              )}
            </div>
            <div className="d-flex flex-column w-100 ms-3">
              <div className="d-flex">
                <p className="fs-4 me-2 fw-bold mb-0">
                  {empData?.data.attributes["preferred-name"]}
                </p>
                {empData?.data.attributes["job-title"] ? (
                  <p className="fs-7 mt-2 mb-0 me-2 text-muted">
                    ({empData?.data.attributes["job-title"]})
                  </p>
                ) : null}
                {empData?.data.attributes["employee-org-master"] ? (
                  <p
                    style={
                      orgFlag && orgFlag === true
                        ? {
                            cursor: "pointer",
                            color: "#8900e5",
                            textDecoration: "underline",
                            textUnderlineOffset: "2px",
                          }
                        : {
                            cursor: "auto",
                            color: "#6c757d",
                            textDecoration: "none",
                            textUnderlineOffset: "0px",
                          }
                    }
                    className="fs-7 mt-2 mb-0"
                  >
                    {orgFlag && orgFlag === true ? (
                      <Link
                        style={{ textDecoration: "none", color: "#8900e5" }}
                        to={`/landscape/organization?id=${empData?.data.attributes[
                          "org-id"
                        ].toString()}`}
                        state={{ prevPath: window.location.href }}
                      >
                        {`(${empData?.data.attributes["employee-org-master"].data.attributes["org-title"]})`}
                      </Link>
                    ) : (
                      <>{`(${empData?.data.attributes["employee-org-master"].data.attributes["org-title"]})`}</>
                    )}
                  </p>
                ) : null}
              </div>
              <div className="d-flex flex-row">
                <div className="d-flex flex-column justify-content-between w-50">
                  <div className="d-flex">
                    <p className="text-muted mb-0 me-1">GID: </p>
                    <p className="mb-0" style={{ color: "#8A00E5" }}>
                      {empData?.data.attributes["gid"]
                        ? `${empData?.data.attributes["gid"]}`
                        : "-"}
                    </p>
                  </div>
                  <div className="d-flex mb-1">
                    <p className="text-muted mb-0 me-1">Email: </p>
                    <p className="mb-0" style={{ color: "#8A00E5" }}>
                      {empData?.data.attributes["email-id"]}
                    </p>
                    <span
                      className="ms-2"
                      style={{ cursor: "pointer" }}
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Copy email"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          empData?.data.attributes["email-id"]
                        );
                      }}
                    >
                      <ImageSvgs name="expertCopy" />
                    </span>
                    <a
                      className="ms-2"
                      style={{ cursor: "pointer" }}
                      href={`mailto:${empData?.data.attributes["email-id"]}`}
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Mail to"
                    >
                      <ImageSvgs name="expertMail" />
                    </a>
                  </div>
                  <div className="d-flex">
                    <p className="text-muted mb-0 me-1">Contact Info: </p>
                    <p className="mb-0" style={{ color: "#8A00E5" }}>
                      {empData?.data.attributes["contact-info"]
                        ? `${empData?.data.attributes["contact-info"]}`
                        : "-"}
                    </p>
                  </div>
                </div>
                <div className="d-flex flex-column justify-content-between w-50">
                  <div className="d-flex">
                    <p className="text-muted mb-0 me-1">Cosmos Link: </p>
                    {cosmosLink ? (
                      <a
                        href={`${cosmosLink && cosmosLink}${
                          empData?.data.attributes["gid"]
                        }`}
                        target="_blank"
                        rel="noreferrer"
                        className="mb-0"
                        style={{ color: "#8A00E5" }}
                      >
                        Visit Profile
                      </a>
                    ) : (
                      "-"
                    )}
                  </div>
                  <div className="d-flex">
                    <p className="text-muted mb-0 me-1">Location: </p>
                    <p className="mb-0" style={{ color: "#8A00E5" }}>
                      {location ? location.attributes["location-code"] : "-"}
                    </p>
                  </div>
                  <div className="d-flex">
                    <p className="text-muted mb-0 me-1">Local Time: </p>
                    <p className="mb-0" style={{ color: "#8A00E5" }}>
                      {time ? `${time}` : "-"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column mt-3">
            <h6>
              <b>Connections in FIN GS :-</b>
            </h6>
            <div className="p-3 page-connections-section">
              <div className="mt-2">
                {commData?.length > 0 ? (
                  <h6 className="fw-bold ms-4 d-flex">Communities</h6>
                ) : (
                  <h6 className="fw-bold ms-4 d-flex">
                    Communities:<p className="mb-0 ms-2 text-muted">N/A</p>
                  </h6>
                )}
                <div className="d-flex flex-row justify-content-center">
                  {commData?.length > 0 ? (
                    <Carousel
                      nextIcon={nextArrow}
                      prevIcon={prevArrow}
                      interval={null}
                      wrap={false}
                      indicators={false}
                    >
                      {commData?.map((record, i) => (
                        <Carousel.Item key={i}>
                          <Stack
                            direction="horizontal"
                            className="h-100 align-items-center"
                            gap={2}
                          >
                            {record.map((elem, j) => {
                              if (j < record.length - 1) {
                                return (
                                  <>
                                    <ConnectionsComponent data={elem} key={j} />
                                    <div className="divider"></div>
                                  </>
                                );
                              } else {
                                return (
                                  <ConnectionsComponent data={elem} key={j} />
                                );
                              }
                            })}
                          </Stack>
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  ) : null}
                </div>
              </div>
              <div className="mt-4">
                {trainData?.length > 0 ? (
                  <h6 className="fw-bold ms-4 d-flex">Trainings</h6>
                ) : (
                  <h6 className="fw-bold ms-4 d-flex">
                    Trainings:<p className="mb-0 ms-2 text-muted">N/A</p>
                  </h6>
                )}
                <div className="d-flex flex-row justify-content-center">
                  {trainData?.length > 0 ? (
                    <Carousel
                      nextIcon={nextArrow}
                      prevIcon={prevArrow}
                      interval={null}
                      wrap={false}
                      indicators={false}
                    >
                      {trainData?.map((record, i) => (
                        <Carousel.Item key={i}>
                          <Stack
                            direction="horizontal"
                            className="h-100  align-items-center"
                            gap={2}
                          >
                            {record.map((elem, j) => {
                              if (j < record.length - 1) {
                                return (
                                  <>
                                    <ConnectionsComponent data={elem} key={j} />
                                    <div className="divider"></div>
                                  </>
                                );
                              } else {
                                return (
                                  <ConnectionsComponent data={elem} key={j} />
                                );
                              }
                            })}
                          </Stack>
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  ) : null}
                </div>
              </div>
              <div className="mt-4">
                {proData?.length > 0 ? (
                  <h6 className="fw-bold ms-4 d-flex">Processes</h6>
                ) : (
                  <h6 className="fw-bold ms-4 d-flex">
                    Processes:<p className="mb-0 ms-2 text-muted">N/A</p>
                  </h6>
                )}
                <div className="d-flex flex-row justify-content-center">
                  {proData?.length > 0 ? (
                    <Carousel
                      nextIcon={nextArrow}
                      prevIcon={prevArrow}
                      interval={null}
                      wrap={false}
                      indicators={false}
                    >
                      {proData?.map((record, i) => (
                        <Carousel.Item key={i}>
                          <Stack
                            direction="horizontal"
                            className="h-100  align-items-center"
                            gap={2}
                          >
                            {record.map((elem, j) => {
                              if (j < record.length - 1) {
                                return (
                                  <>
                                    <ConnectionsComponent data={elem} key={j} />
                                    <div className="divider"></div>
                                  </>
                                );
                              } else {
                                return (
                                  <ConnectionsComponent data={elem} key={j} />
                                );
                              }
                            })}
                          </Stack>
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  ) : null}
                </div>
              </div>
              <div className="mt-4">
                {toolsData?.length > 0 ? (
                  <h6 className="fw-bold ms-4 d-flex">Tools</h6>
                ) : (
                  <h6 className="fw-bold ms-4 d-flex">
                    Tools:<p className="mb-0 ms-2 text-muted">N/A</p>
                  </h6>
                )}
                <div className="d-flex flex-row justify-content-center">
                  {toolsData?.length > 0 ? (
                    <Carousel
                      nextIcon={nextArrow}
                      prevIcon={prevArrow}
                      interval={null}
                      wrap={false}
                      indicators={false}
                    >
                      {toolsData?.map((record, i) => (
                        <Carousel.Item key={i}>
                          <Stack
                            direction="horizontal"
                            className="h-100  align-items-center"
                            gap={2}
                          >
                            {record.map((elem, j) => {
                              if (j < record.length - 1) {
                                return (
                                  <>
                                    <ConnectionsComponent data={elem} key={j} />
                                    <div className="divider"></div>
                                  </>
                                );
                              } else {
                                return (
                                  <ConnectionsComponent data={elem} key={j} />
                                );
                              }
                            })}
                          </Stack>
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  ) : null}
                </div>
              </div>
              <div className="mt-4">
                {appsData?.length > 0 ? (
                  <h6 className="fw-bold ms-4 d-flex">Applications</h6>
                ) : (
                  <h6 className="fw-bold ms-4 d-flex">
                    Applications:<p className="mb-0 ms-2 text-muted">N/A</p>
                  </h6>
                )}
                <div className="d-flex flex-row justify-content-center">
                  {appsData?.length > 0 ? (
                    <Carousel
                      nextIcon={nextArrow}
                      prevIcon={prevArrow}
                      interval={null}
                      wrap={false}
                      indicators={false}
                    >
                      {appsData?.map((record, i) => (
                        <Carousel.Item key={i}>
                          <Stack
                            direction="horizontal"
                            className="h-100  align-items-center"
                            gap={2}
                          >
                            {record.map((elem, j) => {
                              let finalData = empData?.included.filter(
                                // eslint-disable-next-line
                                (app) =>
                                  app.type === "application" &&
                                  app.id.toString() === elem.id.toString()
                              )[0];
                              if (j < record.length - 1) {
                                return (
                                  <>
                                    <ConnectionsComponent
                                      data={finalData}
                                      key={j}
                                    />
                                    <div className="divider"></div>
                                  </>
                                );
                              } else {
                                return (
                                  <ConnectionsComponent
                                    data={finalData}
                                    key={j}
                                  />
                                );
                              }
                            })}
                          </Stack>
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  ) : null}
                </div>
              </div>
              <div className="mt-4">
                {topicData.length > 0 ? (
                  <h6 className="fw-bold ms-4 d-flex">Topics</h6>
                ) : (
                  <h6 className="fw-bold ms-4 d-flex">
                    Topics:
                    <p className="mb-0 ms-2 text-muted">N/A</p>
                  </h6>
                )}
                <div className="d-flex flex-row justify-content-center">
                  {topicData.length > 0 ? (
                    <Carousel
                      nextIcon={nextArrow}
                      prevIcon={prevArrow}
                      interval={null}
                      wrap={false}
                      indicators={false}
                    >
                      {topicData?.map((record, i) => (
                        <Carousel.Item key={i}>
                          <Stack
                            direction="horizontal"
                            className="h-100 align-items-center"
                            gap={2}
                          >
                            {record.map((elem, j) => {
                              if (j < record.length - 1) {
                                return (
                                  <>
                                    <ConnectionsComponent data={elem} key={j} />
                                    <div className="divider"></div>
                                  </>
                                );
                              } else {
                                return (
                                  <ConnectionsComponent data={elem} key={j} />
                                );
                              }
                            })}
                          </Stack>
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ maxWidth: "100%", height: "35rem" }}>
          <Loader />
        </div>
      )}
    </>
  );
};

export default EmployeeRelations;
