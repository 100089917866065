import React, { useEffect, useState, useRef, useCallback } from "react";
import { Alert, CloseButton } from "react-bootstrap";
import "./NotificationAlert.scss";

const NotificationAlert = ({ type = "success", message, destroy }) => {
  const [show, setShow] = useState(true);
  const timeoutRef = useRef(null);
  const remainingTimeRef = useRef(5000);
  const startTimeRef = useRef(Date.now());

  const handleClose = useCallback(() => {
    setShow(false);
    clearTimeout(timeoutRef.current);
    setTimeout(destroy, 200);
  }, [destroy]);

  const startTimeout = useCallback(() => {
    startTimeRef.current = Date.now();
    timeoutRef.current = setTimeout(handleClose, remainingTimeRef.current);
  }, [handleClose]);

  useEffect(() => {
    startTimeout();
    return () => clearTimeout(timeoutRef.current);
  }, [startTimeout]);

  const pauseTimeout = () => {
    clearTimeout(timeoutRef.current);
    remainingTimeRef.current -= Date.now() - startTimeRef.current;
  };

  return (
    show && (
      <Alert
        variant={type}
        className="notification-alert"
        onMouseEnter={pauseTimeout}
        onMouseLeave={startTimeout}>
        <CloseButton onClick={handleClose} className="close-btn" />
        <div className="notification-content">{message}</div>
      </Alert>
    )
  );
};

export default NotificationAlert;
