import React, { useState, useEffect } from "react";
import {
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  Divider,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { withStyles } from "@material-ui/core/styles";
import { useLocation, useNavigate } from "react-router-dom";
import "./FinNestedList.scss";

const styles = (theme) => ({
  listItem: {
    "&,&:focus": {
      backgroundColor: theme.palette.background.paper,
    },
    border: "none",
  },
  important: {
    color: "rgb(138, 0, 229)",
    fontWeight: "bold",
  },
  selectedItem: {
    borderLeft: "2px solid rgb(137, 0, 229) !important",
  },
  selectedIcon: {
    fill: "rgb(138, 0, 229) !important",
  },
});

const resetImportantProperty = (data, hierarchy) => {
  return data.map((item) => ({
    ...item,
    important: false,
    children:
      hierarchy?.filter(
        (elem) => elem?.attributes?.["parent-hierarchy"]?.data?.id === item?.id
      ).length > 0
        ? resetImportantProperty(
            hierarchy?.filter(
              (elem) =>
                elem?.attributes?.["parent-hierarchy"]?.data?.id === item?.id
            ),
            hierarchy
          )
        : [],
  }));
};

const updateImportantProperty = (data, currentItem, hierarchy) => {
  return data.map((item) => {
    if (item?.id === currentItem?.id) {
      return {
        ...item,
        important: true,
        children: item.children
          ? resetImportantProperty(item.children, hierarchy)
          : [],
      };
    } else {
      return {
        ...item,
        important: false,
        children: item.children
          ? updateImportantProperty(item.children, currentItem, hierarchy)
          : [],
      };
    }
  });
};

const NestedList = ({
  items,
  classes,
  hierarchy,
  setParentsId,
  parentsId,
  padding = 1,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState({});
  const [listData, setListData] = useState(
    resetImportantProperty(items, hierarchy)
  );

  useEffect(() => {
    function extractParentHierarchyData() {
      let dataArray = [];
      let current = hierarchy?.filter(
        (elem) => elem?.id?.toString() === parentsId?.toString()
      )[0];

      let defaultElem = hierarchy?.filter(
        (elem) =>
          elem?.attributes?.["type-data"]?.data?.attributes?.[
            "type-field-name"
          ] === "Finance Fields"
      )[0];

      if (parentsId?.toString() === defaultElem?.id?.toString()) {
        toggleOpen(defaultElem.attributes["parent-hierarchy"].data);
        toggleOpen(defaultElem);
      } else {
        while (current && current?.attributes?.["parent-hierarchy"]) {
          if (current?.attributes?.["parent-hierarchy"].data) {
            dataArray.push(current?.attributes?.["parent-hierarchy"].data);
          }
          current = current?.attributes?.["parent-hierarchy"].data;
        }
      }
      return dataArray;
    }

    const dataArray = extractParentHierarchyData(hierarchy);
    setListData(resetImportantProperty(items, hierarchy));
    dataArray.forEach((elem) => {
      toggleOpen(elem);
    });

    // eslint-disable-next-line
  }, []);

  const toggleOpen = (item) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [item?.attributes?.["is-leaf"] === true
        ? item?.attributes?.skills?.data?.attributes?.["skill-title"]
        : item?.attributes?.["is-leaf"] === false &&
          item?.attributes?.["type-data"]?.data?.attributes?.type ===
            "FinanceField"
        ? item?.attributes?.["type-data"]?.data?.attributes?.[
            "finance-fields-data"
          ]?.data?.attributes?.["finance-fields-title"]
        : item?.attributes?.["type-data"]?.data?.attributes?.[
            "type-field-name"
          ]]:
        !prevOpen[
          item?.attributes?.["is-leaf"] === true
            ? item?.attributes?.skills?.data?.attributes?.["skill-title"]
            : item?.attributes?.["is-leaf"] === false &&
              item?.attributes?.["type-data"]?.data?.attributes?.type ===
                "FinanceField"
            ? item?.attributes?.["type-data"]?.data?.attributes?.[
                "finance-fields-data"
              ]?.data?.attributes?.["finance-fields-title"]
            : item?.attributes?.["type-data"]?.data?.attributes?.[
                "type-field-name"
              ]
        ],
    }));
  };

  const handleExpandClick = (e, item) => {
    e.stopPropagation();
    toggleOpen(item);
  };

  const handleItemClick = async (item) => {
    navigate(`/landscape/finergy/learning/${item?.id}${location?.search}`);
    setListData((prevData) =>
      updateImportantProperty(prevData, item, hierarchy)
    );
  };

  return (
    <List component="nav" aria-labelledby="nested-list-subheader">
      {listData
        ?.sort((a, b) =>
          (a?.attributes?.["is-leaf"] === true
            ? a?.attributes?.skills?.data?.attributes?.["skill-title"]
            : a?.attributes?.["is-leaf"] === false &&
              a?.attributes?.["type-data"]?.data?.attributes?.type ===
                "FinanceField"
            ? a?.attributes?.["type-data"]?.data?.attributes?.[
                "finance-fields-data"
              ]?.data?.attributes?.["finance-fields-title"]
            : a?.attributes?.["type-data"]?.data?.attributes?.[
                "type-field-name"
              ]
          ).localeCompare(
            b?.attributes?.["is-leaf"] === true
              ? b?.attributes?.skills?.data?.attributes?.["skill-title"]
              : b?.attributes?.["is-leaf"] === false &&
                b?.attributes?.["type-data"]?.data?.attributes?.type ===
                  "FinanceField"
              ? b?.attributes?.["type-data"]?.data?.attributes?.[
                  "finance-fields-data"
                ]?.data?.attributes?.["finance-fields-title"]
              : b?.attributes?.["type-data"]?.data?.attributes?.[
                  "type-field-name"
                ]
          )
        )
        ?.map((item) => (
          <React.Fragment key={item?.id}>
            <ListItemButton
              onClick={(e) => {
                setParentsId(item?.id);
                handleItemClick(item);
              }}
              className={`${classes.listItem} ${
                parentsId?.toString() === item?.id?.toString()
                  ? classes.selectedItem
                  : ""
              }`}
              divider
              sx={{ pl: padding }}
            >
              <ListItemText
                primary={
                  item?.attributes?.["is-leaf"] === true
                    ? item?.attributes?.skills?.data?.attributes?.[
                        "skill-title"
                      ]
                    : item?.attributes?.["is-leaf"] === false &&
                      item?.attributes?.["type-data"]?.data?.attributes
                        ?.type === "FinanceField"
                    ? item?.attributes?.["type-data"]?.data?.attributes?.[
                        "finance-fields-data"
                      ]?.data?.attributes?.["finance-fields-title"]
                    : item?.attributes?.["type-data"]?.data?.attributes?.[
                        "type-field-name"
                      ]
                }
                classes={{
                  primary:
                    parentsId?.toString() === item?.id?.toString()
                      ? classes.important
                      : "",
                }}
                sx={{ pl: padding }}
              />

              {item.children && item.children.length > 0 && (
                <div onClick={(e) => handleExpandClick(e, item)}>
                  {open[
                    item?.attributes?.["is-leaf"] === true
                      ? item?.attributes?.skills?.data?.attributes?.[
                          "skill-title"
                        ]
                      : item?.attributes?.["is-leaf"] === false &&
                        item?.attributes?.["type-data"]?.data?.attributes
                          ?.type === "FinanceField"
                      ? item?.attributes?.["type-data"]?.data?.attributes?.[
                          "finance-fields-data"
                        ]?.data?.attributes?.["finance-fields-title"]
                      : item?.attributes?.["type-data"]?.data?.attributes?.[
                          "type-field-name"
                        ]
                  ] ? (
                    <ExpandLess
                      className={
                        parentsId?.toString() === item?.id?.toString()
                          ? classes.selectedIcon
                          : ""
                      }
                    />
                  ) : (
                    <ExpandMore
                      className={
                        parentsId?.toString() === item?.id?.toString()
                          ? classes.selectedIcon
                          : ""
                      }
                    />
                  )}
                </div>
              )}
            </ListItemButton>
            <Divider className="divider" />
            {item.children && item.children.length > 0 && (
              <Collapse
                in={
                  open[
                    item?.attributes?.["is-leaf"] === true
                      ? item?.attributes?.skills?.data?.attributes?.[
                          "skill-title"
                        ]
                      : item?.attributes?.["is-leaf"] === false &&
                        item?.attributes?.["type-data"]?.data?.attributes
                          ?.type === "FinanceField"
                      ? item?.attributes?.["type-data"]?.data?.attributes?.[
                          "finance-fields-data"
                        ]?.data?.attributes?.["finance-fields-title"]
                      : item?.attributes?.["type-data"]?.data?.attributes?.[
                          "type-field-name"
                        ]
                  ]
                }
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  <NestedList
                    items={item.children}
                    classes={classes}
                    hierarchy={hierarchy}
                    setParentsId={setParentsId}
                    parentsId={parentsId}
                    padding={padding + 1}
                  />
                </List>
              </Collapse>
            )}
          </React.Fragment>
        ))}
    </List>
  );
};

const FinNestedList = withStyles(styles)(
  ({ classes, hierarchy, setParentsId, setList, parentsId }) => {
    return (
      <NestedList
        items={hierarchy?.filter((elem) => elem?.id === 1)}
        classes={classes}
        hierarchy={hierarchy}
        setParentsId={setParentsId}
        parentsId={parentsId}
        setList={setList}
      />
    );
  }
);

export default FinNestedList;
