import React from "react";
import { useState, useEffect } from "react";
import { getData } from "../../services/apiService";
import { getHostUrl } from "../../config/environment";
import AppsTable from "../../components/AppAdminComponents/AppsTable";
import AddLayers from "../../components/AppAdminComponents/AddLayers";
import EditLayers from "../../components/AppAdminComponents/EditLayers";
import { AddApp, EditApp } from "../../utils/applicationUtils";
import PreviewPage from "../../components/AppAdminComponents/PreviewPage";
import ImageSvgs from "../../components/ImageSvgs";
import { useNavigate } from "react-router";
import Loader from "../../components/Loader/Loader";
import { Button } from "react-bootstrap";

const ApplicationsAdmin = () => {
  const url = new URL(window.location.href);
  const navigate = useNavigate();
  const [included, setIncluded] = useState();
  const [data, setData] = useState();
  // eslint-disable-next-line
  const [tableData, setTableData] = useState([]);
  // eslint-disable-next-line
  const [level, setLevel] = useState(url.searchParams.get("page"));
  const [isDeleted, setIsDeleted] = useState(false);
  const [comm, setComm] = useState();
  const [train, setTrain] = useState();
  const [process, setProcess] = useState();
  const [isAdmin, setIsAdmin] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      await setData();
      await setTableData();
      setIsDeleted(false);

      const adRes = await getData(
        `${getHostUrl()}user/check/${
          JSON.parse(localStorage.getItem("userData")).id
        }`
      );
      setIsAdmin(adRes.data);

      if (adRes.data) {
        const res = await getData(
          `${getHostUrl()}application?include=app_info,owners,communities,process,trainings&use_pagination=false`
        );
        setData(res.data.data);
        setTableData(res.data.data);
        setIncluded(res.data.included);

        const comRes = await getData(
          `${getHostUrl()}community?use_pagination=false`
        );
        setComm(comRes.data.data);

        const trainRes = await getData(
          `${getHostUrl()}training?use_pagination=false`
        );
        setTrain(trainRes.data.data);

        const proRes = await getData(
          `${getHostUrl()}process?use_pagination=false`
        );
        setProcess(proRes.data.data);
      }

      setLoading(false);
    };

    fetchData();
  }, [level, isDeleted]);

  useEffect(() => {
    addQueryParam("page", level);
  }, [level]);

  const addQueryParam = (key, value) => {
    const url = new URL(window.location.href);
    url.searchParams.set(key, value);
    window.history.pushState({}, "", url.toString());
  };

  const keys = ["app-title"];
  // eslint-disable-next-line
  const Search = (query) => {
    setTableData(
      data?.filter((item) =>
        keys.some((key) => item.attributes[key].toLowerCase().includes(query))
      )
    );
  };

  return (
    <div className="main-manage">
      {isAdmin !== null ? (
        <>
          {isAdmin ? (
            <>
              <button
                className="fw-bold border-0 bg-transparent"
                onClick={() => {
                  if (level === "table") {
                    navigate("/manage_users");
                  } else {
                    setLevel("table");
                    sessionStorage.removeItem("appDetails");
                    sessionStorage.removeItem("appConfig");
                    sessionStorage.removeItem("appOwners");
                    sessionStorage.removeItem("appDeputy");
                    sessionStorage.removeItem("appComs");
                    sessionStorage.removeItem("appNodes");
                    sessionStorage.removeItem("appProcess");
                    sessionStorage.removeItem("appTrainings");
                    sessionStorage.removeItem("editData");
                    sessionStorage.removeItem("newEditData");
                  }
                }}>
                <ImageSvgs name="goBack" /> Back
              </button>

              <div>
                <>
                  {data ? (
                    <>
                      {data && tableData ? (
                        <>
                          {level === "table" ? (
                            <>
                              <div className="sub-bar d-flex justify-content-between">
                                <p className="main-title">
                                  Applications ({tableData?.length})
                                </p>
                                <div className="search-div">
                                  <Button
                                    className="app-secondary-btn"
                                    onClick={() => {
                                      setLevel("addApp");
                                    }}>
                                    Add New Application
                                  </Button>
                                </div>
                              </div>
                              <div className="user-table">
                                {tableData &&
                                tableData.length > 0 &&
                                loading === false ? (
                                  <AppsTable
                                    data={tableData && tableData}
                                    included={included ? included : null}
                                    setLevel={setLevel}
                                    setIsDeleted={setIsDeleted}
                                  />
                                ) : (
                                  <div
                                    style={{
                                      maxWidth: "100%",
                                      height: "100vh",
                                    }}>
                                    <Loader />
                                  </div>
                                )}
                              </div>
                            </>
                          ) : level === "addApp" ? (
                            <AddApp
                              appList={tableData && tableData}
                              comData={comm && comm}
                              trainData={train && train}
                              proData={process && process}
                              setLevel={setLevel}
                            />
                          ) : level === "addLayer" ? (
                            <AddLayers setLevel={setLevel} />
                          ) : level === "editLayer" ? (
                            <EditLayers setLevel={setLevel} />
                          ) : level === "addPreview" ? (
                            <PreviewPage
                              setLevel={setLevel}
                              included={included}
                            />
                          ) : level === "editApp" ? (
                            <EditApp
                              appList={tableData && tableData}
                              comData={comm && comm}
                              trainData={train && train}
                              proData={process && process}
                              setLevel={setLevel}
                              included={included}
                            />
                          ) : level === "editLayer" ? (
                            <AddLayers setLevel={setLevel} />
                          ) : null}
                        </>
                      ) : (
                        <div style={{ maxWidth: "100%", height: "100vh" }}>
                          <Loader />
                        </div>
                      )}
                    </>
                  ) : (
                    <div style={{ maxWidth: "100%", height: "100vh" }}>
                      <Loader />
                    </div>
                  )}
                </>
              </div>
            </>
          ) : (
            <div className="non-admin">
              <ImageSvgs name="non_admin" />
              <h1 className="text-danger">Unauthorized user!</h1>
              <h4>You do not have admin access rights.</h4>
              <button
                id="common-button"
                onClick={() => {
                  navigate("/");
                }}>
                Go to Home
              </button>
            </div>
          )}
        </>
      ) : (
        <div style={{ maxWidth: "100%", height: "100vh" }}>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default ApplicationsAdmin;
