import React from "react";
import { Stack, Carousel } from "react-bootstrap";
import Loader from "../../Loader/Loader";
import { getBaseUrl, getYammerUrl } from "../../../config/environment";
import GenericCard from "../../Cards/GenericCard";
import OwnerProfile from "../../CommonComponents/OwnerProfile";
import "./FinergyAnnouncements.scss";

export default function FinergyAnnouncements(props) {
  const base64 = require("base-64");

  const cardFooter = (footerData) => {
    return (
      footerData?.length > 0 && (
        <div className="my-auto">
          <OwnerProfile owner={footerData?.[0]} isOwnerHasLink={true} />
        </div>
      )
    );
  };

  const handleCardClick = (url) => {
    window.open(url, "_blank");
  };

  return (
    <>
      <div className="d-flex fs-4 fw-bold pb-3">{props.title}</div>
      <div className="newscomp-carousel banner-carousel" id="news-carousel">
        {props?.data?.length <= 0 ? (
          <div className="carousel-loader">
            <Loader />
          </div>
        ) : (
          <>
            <Carousel
              interval={null}
              wrap={false}
              controls={false}
              className="announcement-carousel"
            >
              {props?.data?.map((group, i) => (
                <Carousel.Item key={i}>
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="carousel-item-stack w-100"
                  >
                    {group?.map((record, j) => (
                      <GenericCard
                        key={j}
                        header={record?.["channel_id"]}
                        description={record?.desc}
                        overlayTriggers={{
                          header: record?.["channel_id"],
                          description: record?.desc,
                        }}
                        imageSrc={
                          record?.image !== null
                            ? `${getBaseUrl()}/saba-trainings/groups/${
                                record?.image
                              }`
                            : `${getBaseUrl()}/images/default-images/default-news.jpg`
                        }
                        footer={cardFooter(record?.sender?.data)}
                        onClick={() => {
                          handleCardClick(
                            `${getYammerUrl()}threads/${base64.encode(
                              JSON.stringify({
                                _type: "Thread",
                                id: `${record?.thread}`,
                              })
                            )}`
                          );
                        }}
                      />
                    ))}
                  </Stack>
                </Carousel.Item>
              ))}
            </Carousel>
          </>
        )}
      </div>
    </>
  );
}
