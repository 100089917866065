import React, { useEffect, useState } from "react";
import { getHostUrl } from "../../config/environment";
import { getData } from "../../services/apiService";
import GraphComp from "./GraphComp";
import TableComp from "./TableComp";
import Loader from "../Loader/Loader";
import { Carousel } from "react-bootstrap";
import "./MainComp.scss";

const MainComp = ({ fullSize, height }) => {
  const [data, setData] = useState();
  const [meta, setMeta] = useState();

  useEffect(() => {
    (async () => {
      const res = await getData(
        `${getHostUrl()}training/external/training/data`
      );

      await setData(res?.data.data);
      await setMeta(res?.data.meta);
    })();
  }, []);

  return (
    <div className="trainings-dashboard">
      {data && meta ? (
        <div
          className="d-flex flex-column main-comp "
          style={{
            height: height ? height : "auto",
          }}>
          <div className="title-container">
            <div className="d-flex title">
              <p className="fw-bold fs-4 " style={{ color: "#1E1E1E" }}>
                Your Trainings
              </p>
            </div>
            {fullSize ? (
              <div className="d-flex graph">
                <div
                  className="w-50 border mx-1"
                  style={{ backgroundColor: "white" }}>
                  <GraphComp mainData={data && data} metaData={meta && meta} />
                </div>
                <div
                  className="w-50 border mx-1"
                  style={{ backgroundColor: "white" }}>
                  <TableComp mainData={data && data} />
                </div>
              </div>
            ) : (
              <div className="banner-carousel trainings-carousel w-100">
                <Carousel controls={false} wrap={false} interval={null}>
                  <Carousel.Item style={{ backgroundColor: "white" }}>
                    <GraphComp
                      mainData={data && data}
                      metaData={meta && meta}
                    />
                  </Carousel.Item>
                  <Carousel.Item
                    style={{ backgroundColor: "white", height: "23.9rem" }}>
                    <TableComp mainData={data && data} />
                  </Carousel.Item>
                </Carousel>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div style={{ maxWidth: "100%", height: "30rem" }}>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default MainComp;
