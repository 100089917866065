export const graphUrl = "https://graph.microsoft.com/v1.0";

export const baseUrl = {
  local: "https://assets.finance-center.mosaic-dev.siemens-energy.cloud",
  dev: "https://assets.finance-center.mosaic-dev.siemens-energy.cloud",
  qa: "https://assets.finance-center.mosaic-qa.siemens-energy.cloud",
  prod: "https://assets.finance-center.mosaic.siemens-energy.cloud",
};

export const hostUrl = {
  local: "https://api.finance-center.mosaic-dev.siemens-energy.cloud/api/v1/",
  dev: "https://api.finance-center.mosaic-dev.siemens-energy.cloud/api/v1/",
  qa: "https://api.finance-center.mosaic-qa.siemens-energy.cloud/api/v1/",
  prod: "https://api.finance-center.mosaic.siemens-energy.cloud/api/v1/",
};

export const sharePointUrl = {
  local: "https://siemensenergyag.sharepoint.com",
  dev: "https://siemensenergyag.sharepoint.com",
  qa: "https://siemensenergyag.sharepoint.com",
  prod: "https://siemensenergyag.sharepoint.com",
};

export const yammerUrl = {
  local: "https://web.yammer.com/main/org/siemensenergyag.onmicrosoft.com/",
  dev: "https://web.yammer.com/main/org/siemensenergyag.onmicrosoft.com/",
  qa: "https://web.yammer.com/main/org/siemensenergyag.onmicrosoft.com/",
  prod: "https://web.yammer.com/main/org/siemensenergyag.onmicrosoft.com/",
};

export const wernerUrl = {
  local:
    "https://api.finance-center.mosaic-dev.siemens-energy.cloud/werner-virtual-assist/api/v1/",
  dev: "https://api.finance-center.mosaic-dev.siemens-energy.cloud/werner-virtual-assist/api/v1/",
  qa: "https://api.finance-center.mosaic-qa.siemens-energy.cloud/werner-virtual-assist/api/v1/",
  prod: "https://api.finance-center.mosaic.siemens-energy.cloud/werner-virtual-assist/api/v1/",
};

export const redirectUrl = {
  local: "http://localhost:3000/",
  dev: "https://finance-center.mosaic-dev.siemens-energy.cloud/",
  qa: "https://finance-center.mosaic-qa.siemens-energy.cloud/",
  prod: "https://finance-center.mosaic.siemens-energy.cloud/",
};

export const authConfig = {
  local: {
    clientId: "8ab4e897-fad6-4e32-bcab-e154b5d5747e",
    authority:
      "https://login.microsoftonline.com/254ba93e-1f6f-48f3-90e6-e2766664b477/",
    redirectUri: "/",
    postLogoutRedirectUri: "/",
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
    useLogger: true,
    resource: "https://api.yammer.com",
  },
  dev: {
    clientId: "8ab4e897-fad6-4e32-bcab-e154b5d5747e",
    authority:
      "https://login.microsoftonline.com/254ba93e-1f6f-48f3-90e6-e2766664b477/",
    redirectUri: "https://finance-center.mosaic-dev.siemens-energy.cloud/",
    postLogoutRedirectUri: "/",
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
    useLogger: true,
    resource: "https://api.yammer.com",
  },
  qa: {
    clientId: "8ab4e897-fad6-4e32-bcab-e154b5d5747e",
    authority:
      "https://login.microsoftonline.com/254ba93e-1f6f-48f3-90e6-e2766664b477/",
    redirectUri: "https://finance-center.mosaic-qa.siemens-energy.cloud/",
    postLogoutRedirectUri: "/",
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
    useLogger: false,
    resource: "https://api.yammer.com",
  },
  prod: {
    clientId: "36062ff5-2cc8-4ad7-88f6-fa4693e8149f",
    authority:
      "https://login.microsoftonline.com/254ba93e-1f6f-48f3-90e6-e2766664b477/",
    redirectUri: "https://finance-center.mosaic.siemens-energy.cloud/",
    postLogoutRedirectUri: "/",
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
    useLogger: false,
    resource: "https://api.yammer.com",
  },
};

export const tokenExpiredMsg = "Invalid JWT token. The token is expired.";

export const signatureInvaliddMsg = "Invalid Signature Error";

export const sessionExpiredMsg =
  "Your session has expired. You need to login again.";

export const defaultUserConfig = {
  configuration: {
    row: "2",
    homeConfig: [
      { i: "fin gs", x: 0, y: 0, w: 2, h: 6 },
      { i: "company", x: 0, y: 6, w: 1, h: 6 },
      { i: "yammer", x: 1, y: 6, w: 1, h: 6 },
      { i: "stocks", x: 1, y: 12, w: 1, h: 6 },
      { i: "lunch", x: 0, y: 12, w: 1, h: 6 },
      { i: "favorites", x: 0, y: 18, w: 2, h: 6 },
      { i: "trainings", x: 0, y: 24, w: 2, h: 6 },
      { i: "sac", x: 0, y: 30, w: 2, h: 6 },
    ],
    lunchData: "Erlangen S SP 4",
    sacLinks: [],
  },
};

export const CATEGORIES = [
  { label: "All", value: "all" },
  { label: "Applications", value: "application" },
  { label: "Communities", value: "communities" },
  { label: "Organizations", value: "organization" },
  // { label: "Employee", value: "employee" }, //for future use
  {
    label: "FINergy",
    value: "finLearnings",
    children: [
      { label: "All", value: "finergy" },
      { label: "Learnings", value: "learnings" },
      { label: "Recordings", value: "recordings" },
    ],
  },
  { label: "Processes", value: "process" },
  { label: "Tools", value: "tools" },
  // { label: "Topics", value: "topics" }, // for future use
  { label: "News", value: "news" },
  { label: "Handbooks", value: "handbooks" },
];
