import {
  getBaseUrl,
  getSharePointUrl,
  getHostUrl,
} from "../config/environment";
import { getData } from "../services/apiService";

export default function getComponentsData(sections) {
  return sections.map(async (section) => {
    if (section["card-type"] === "yammer-tile") {
      const url = `${getHostUrl()}${section["source-url"]}`;
      const response = await getData(url, "idToken");
      return { section: section, data: response.data };
    } else {
      const url =
        section.source === "sharepoint"
          ? `${getSharePointUrl()}${section["source-url"]}`
          : `${getBaseUrl()}${section["source-url"]}`;
      const tokenType =
        section.source === "sharepoint" ? "sharePointAccessToken" : "idToken";
      const response = await getData(url, tokenType);
      return { section: section, data: response.data };
    }
  });
}
