// OwnerProfile.jsx
import React from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import { deepPurple, red, blue, green, orange } from "@mui/material/colors";
import { getBaseUrl } from "../../config/environment";
import "./OwnerProfile.scss";
import Tooltip from "@mui/material/Tooltip";

const colors = [deepPurple[500], red[500], blue[500], green[500], orange[500]];

const stringToColor = (string) => {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  return colors[Math.abs(hash) % colors.length];
};

const OwnerProfile = ({ owner, isOwnerHasLink }) => {
  const preferredName =
    owner?.attributes?.employee?.data?.attributes["preferred-name"];
  const initials = preferredName
    ? preferredName
        .split(" ")
        .slice(0, 2)
        .map((name) => name[0])
        .join("")
    : "";

  const bgColor = stringToColor(initials);

  const navigate = useNavigate();
  const handleClick = (e) => {
    e.stopPropagation();
    if (isOwnerHasLink) {
      navigate(`/employee_relation?id=${owner.attributes["employee-id"]}`);
    }
  };

  const tooltiptext = `${preferredName}<br />${
    owner?.attributes?.employee?.data?.attributes["employee-org-master"] != null
      ? owner?.attributes?.employee?.data?.attributes["employee-org-master"]
          .data.attributes["org-title"]
      : ""
  }`;

  return (
    <span
      className="owner-profile mx-1"
      data-testid="owner-profile"
      onClick={handleClick}
    >
      {owner?.attributes?.employee?.data.attributes["profile-picture"] ? (
        <Tooltip
          title={<span dangerouslySetInnerHTML={{ __html: tooltiptext }} />}
          arrow
        >
          <img
            className="profile-img my-auto"
            src={`${getBaseUrl()}${
              owner?.attributes?.employee?.data.attributes["profile-picture"]
            }`}
            alt="User Img"
            data-testid="profile-img"
          />
        </Tooltip>
      ) : (
        <Tooltip
          title={<span dangerouslySetInnerHTML={{ __html: tooltiptext }} />}
          arrow
        >
          <Avatar sx={{ bgcolor: bgColor, width: 32, height: 32 }}>
            {initials}
          </Avatar>
        </Tooltip>
      )}
    </span>
  );
};

export default OwnerProfile;
