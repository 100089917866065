import { useEffect, useRef, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import ReactGridLayout from "react-grid-layout";
import { getData } from "../../services/apiService";
import { patchData } from "../../services/apiService";
import { getHostUrl } from "../../config/environment";
import StockPlaceholder from "../PlaceholderComponents/StocksPlaceholder";
import LunchPlaceholder from "../PlaceholderComponents/LunchPlaceholder";
import NewsFeedPlaceholder from "../PlaceholderComponents/NewsFeedPlaceholder";
import FavPlaceholder from "../PlaceholderComponents/FavPlaceholder";
import FinGsNewsPlaceholder from "../PlaceholderComponents/FinGsNewsPlaceholder";
import YammerFeedPlaceholder from "../PlaceholderComponents/YammerFeedPlaceholder";
import TrainingsDashboardPlaceholder from "../PlaceholderComponents/TrainingsDashboardPlaceholder";
import Loader from "../Loader/Loader";
import "./CustomizableHomePage.scss";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import { defaultUserConfig } from "../../config/Constants";
import SacPlaceholder from "../PlaceholderComponents/SacPlaceholder";

export default function CustomizableHomeModal(props) {
  const param = `${getHostUrl()}user/config`;
  const [layouts, setLayouts] = useState([]);
  const [newsComp, setNewsComp] = useState(false);
  const [finGs, setFinGs] = useState(false);
  const [yammer, setYammer] = useState(false);
  const [favFeed, setFavFeed] = useState(false);
  const [lunchFeed, setLunchFeed] = useState(false);
  const [stockFeed, setStockFeed] = useState(false);
  const [trainings, setTrainings] = useState(false);
  const [sacWidget, setSacWidget] = useState(false);
  const [enableDelete, setEnableDelete] = useState(false);
  const gridLayoutParentRef = useRef(null);
  const [gridLayoutWidth, setGridLayoutWidth] = useState(1500);

  useEffect(() => {
    layoutConfig();
    // eslint-disable-next-line
  }, []);

  const layoutConfig = async () => {
    try {
      const layoutData = await getData(param);
      setLayouts(layoutData.data.data.attributes.configuration.homeConfig);
    } catch (error) {
      console.log(error);
    }
  };

  const [currentLayout, setCurrentLayout] = useState([]);
  const [loading, setLoading] = useState(false);

  const onDragStart = (e, data) => {
    e.dataTransfer.setData("data", JSON.stringify(data));
  };

  const onDrop = (layout, layoutItem, _event) => {
    const newCard = JSON.parse(_event.dataTransfer.getData("data"));
    const newLayout = [
      ...layout.filter((card) => card.i !== "__dropping-elem__"),
      {
        i: `${newCard.type}`,
        x: layoutItem.x,
        y: layoutItem.y,
        w: layoutItem.w,
        h: 6,
      },
    ];

    setLayouts(newLayout);
  };

  const handleLayoutChange = (layout, _event) => {
    setCurrentLayout([
      ...layout?.map((item) => ({
        i: item.i,
        x: item.x,
        y: item.y,
        w: item.w,
        h: 6,
      })),
    ]);
  };

  const components = [
    {
      type: "company",
      cardType: "cards",
      id: 1,
      cardText: "COMPANY NEWS FEED",
      image: "company_feed",
    },
    {
      type: "fin gs",
      cardType: "cards",
      id: 2,
      cardText: "FIN GS NEWS FEED",
      image: "fin_gs_feed",
    },
    {
      type: "yammer",
      cardType: "cards",
      id: 3,
      cardText: "VIVA ENGAGE NEWS FEED",
      image: "yammer_feed",
    },
    {
      type: "favorites",
      cardType: "cards",
      id: 4,
      cardText: "MY FAVOURITES",
      image: "fav_feed",
    },
    {
      type: "stocks",
      cardType: "cards",
      id: 5,
      cardText: "STOCKS",
    },
    {
      type: "lunch",
      cardType: "cards",
      id: 6,
      cardText: "YOUR LUNCH",
    },
    {
      type: "trainings",
      cardType: "cards",
      id: 7,
      cardText: "YOUR TRAININGS",
    },
    {
      type: "sac",
      cardType: "cards",
      id: 8,
      cardText: "SAC DASHBOARD",
    },
  ];

  const handleHalfWidth = (itemId) => {
    const updatedLayout = currentLayout.map((item) => ({
      ...item,
      w: item.i === itemId ? 1 : item.w,
    }));

    setLayouts(updatedLayout);
  };

  const handleFullWidth = (itemId) => {
    const updatedLayout = currentLayout.map((item) => ({
      ...item,
      w: item.i === itemId ? 2 : item.w,
    }));

    setLayouts(updatedLayout);
  };

  const deleteCard = (id) => {
    if (layouts?.length > 1) {
      setLayouts((prevItems) => prevItems.filter((item) => item.i !== id));
      setEnableDelete(false);
    } else {
      if (layouts?.length === 1) {
        setEnableDelete(true);
      }
    }
  };

  const filteredComponents = components.filter((component) => {
    return !layouts?.some((layout) => layout.i === component.type);
  });

  const saveLayout = async (layout) => {
    const rowValue = JSON.parse(localStorage.getItem("userConfig"));
    const userId = JSON.parse(localStorage.getItem("userData"));
    const payLoad = {
      data: {
        type: "user",
        id: userId.userId,
        attributes: {
          configuration: {
            row: rowValue.row
              ? rowValue.row
              : defaultUserConfig?.configuration.row,
            homeConfig: layout
              ? layout
              : defaultUserConfig?.configuration.homeConfig,
            lunchData: rowValue?.lunchData
              ? rowValue.lunchData
              : defaultUserConfig?.configuration.lunchData,
            sacLinks: rowValue?.sacLinks
              ? rowValue?.sacLinks
              : defaultUserConfig?.configuration.sacLinks,
          },
        },
      },
    };

    const url = `${getHostUrl()}user/config`;

    try {
      setLoading(true);
      // eslint-disable-next-line
      const homeConfig = await patchData(url, payLoad);
      if (homeConfig.status === 200) {
        if (window.location.pathname === "/") {
          localStorage.setItem(
            "userConfig",
            JSON.stringify(payLoad?.data?.attributes?.configuration)
          );
          setLoading(false);
          props.handleClose();
          window.location.reload(true);
        } else {
          localStorage.setItem(
            "userConfig",
            JSON.stringify(payLoad?.data?.attributes?.configuration)
          );
          setLoading(false);
          props.handleClose();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  function reCalculateWidth() {
    if (gridLayoutParentRef && gridLayoutParentRef.current) {
      setGridLayoutWidth(gridLayoutParentRef.current?.clientWidth);
    }
  }

  useEffect(() => {
    window.addEventListener("resize", reCalculateWidth);
    return () => window.removeEventListener("resize", reCalculateWidth);
  }, []);

  useEffect(() => {
    reCalculateWidth();
  }, [gridLayoutParentRef]);

  return (
    <Modal
      {...props}
      size="lg"
      onHide={props.handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="user-config-modal my-auto">
      <Modal.Header
        className="custom-modal-scss modal-fullscreen-sm-down "
        closeButton>
        <div className="d-flex justify-content-around">
          {" "}
          <Modal.Title className="modal-left-title">
            {props.leftTitle}
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body className="config-modal-content">
        <div className="d-flex flex-row">
          <div className="leftSide">
            <div className="left-side-inner-div">
              <div className="d-flex flex-column">
                <div className="pt-5">
                  {filteredComponents?.map((data) => (
                    <div
                      className="card-div"
                      draggable={true}
                      onDragStart={(e) => {
                        onDragStart(e, {
                          type: data.type,
                          cardType: data.cardType,
                          id: data.id,
                        });
                      }}>
                      {data.cardText}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div
            ref={gridLayoutParentRef}
            className="rightSide right-side-div pt-4 w-100">
            {layouts?.length > 0 ? (
              <div className="react-grid-layout-div">
                <ReactGridLayout
                  className="layout"
                  onDrop={onDrop}
                  layout={layouts}
                  isDroppable={true}
                  cols={2}
                  rowHeight={43}
                  compactType="vertical"
                  onLayoutChange={handleLayoutChange}
                  resizeHandles={["se"]}
                  width={gridLayoutWidth}
                  style={{ minHeight: "1300px" }}
                  isResizable={false}>
                  {layouts?.map((data) => {
                    return (
                      <div key={data.i} className="responsive-div">
                        {data?.i === "company" ? (
                          <div className="main-card">
                            {newsComp === true ? (
                              <div className="main-card-50">
                                <NewsFeedPlaceholder
                                  title={" Your Company News Feed"}
                                  deleteCard={() => deleteCard(data.i)}
                                  setNewsComp={setNewsComp}
                                  newsComp={newsComp}
                                  data={data}
                                  handleHalfWidth={() =>
                                    handleHalfWidth("company")
                                  }
                                  handleFullWidth={() =>
                                    handleFullWidth("company")
                                  }
                                />
                              </div>
                            ) : (
                              <div
                                className={
                                  data.w === 1 ? "main-card-50" : "main-card"
                                }>
                                <NewsFeedPlaceholder
                                  title={" Your Company News Feed"}
                                  deleteCard={() => deleteCard(data.i)}
                                  setNewsComp={setNewsComp}
                                  newsComp={newsComp}
                                  data={data}
                                  handleHalfWidth={() =>
                                    handleHalfWidth("company")
                                  }
                                  handleFullWidth={() =>
                                    handleFullWidth("company")
                                  }
                                />
                              </div>
                            )}
                          </div>
                        ) : data.i === "fin gs" ? (
                          <div className="main-card">
                            {finGs === true ? (
                              <div className="main-card-50">
                                <FinGsNewsPlaceholder
                                  title={"Your SE Finance News"}
                                  deleteCard={() => deleteCard(data.i)}
                                  setFinGs={setFinGs}
                                  finGs={finGs}
                                  data={data}
                                  enableDelete={enableDelete}
                                  handleHalfWidth={() =>
                                    handleHalfWidth("fin gs")
                                  }
                                  handleFullWidth={() =>
                                    handleFullWidth("fin gs")
                                  }
                                />
                              </div>
                            ) : (
                              <div
                                className={
                                  data.w === 1 ? "main-card-50" : "main-card"
                                }>
                                <FinGsNewsPlaceholder
                                  title={"Your SE Finance News"}
                                  deleteCard={() => deleteCard(data.i)}
                                  setFinGs={setFinGs}
                                  finGs={finGs}
                                  data={data}
                                  enableDelete={enableDelete}
                                  handleHalfWidth={() =>
                                    handleHalfWidth("fin gs")
                                  }
                                  handleFullWidth={() =>
                                    handleFullWidth("fin gs")
                                  }
                                />
                              </div>
                            )}
                          </div>
                        ) : data.i === "yammer" ? (
                          <div className="main-card">
                            {yammer === true ? (
                              <div className="main-card-50">
                                <YammerFeedPlaceholder
                                  title={"Your Viva Engage Feed"}
                                  deleteCard={() => deleteCard(data.i)}
                                  setYammer={setYammer}
                                  yammer={yammer}
                                  data={data}
                                  enableDelete={enableDelete}
                                  handleHalfWidth={() =>
                                    handleHalfWidth("yammer")
                                  }
                                  handleFullWidth={() =>
                                    handleFullWidth("yammer")
                                  }
                                />
                              </div>
                            ) : (
                              <div
                                className={
                                  data.w === 1 ? "main-card-50" : "main-card"
                                }>
                                <YammerFeedPlaceholder
                                  title={"Your Viva Engage Feed"}
                                  deleteCard={() => deleteCard(data.i)}
                                  setYammer={setYammer}
                                  yammer={yammer}
                                  data={data}
                                  enableDelete={enableDelete}
                                  handleHalfWidth={() =>
                                    handleHalfWidth("yammer")
                                  }
                                  handleFullWidth={() =>
                                    handleFullWidth("yammer")
                                  }
                                />
                              </div>
                            )}
                          </div>
                        ) : data.i === "favorites" ? (
                          <div className="main-card">
                            {favFeed === true ? (
                              <div className="main-card-50">
                                <FavPlaceholder
                                  title={"Your Favourites"}
                                  deleteCard={() => deleteCard(data.i)}
                                  setFavFeed={setFavFeed}
                                  favFeed={favFeed}
                                  data={data}
                                  enableDelete={enableDelete}
                                  handleHalfWidth={() =>
                                    handleHalfWidth("favorites")
                                  }
                                  handleFullWidth={() =>
                                    handleFullWidth("favorites")
                                  }
                                />
                              </div>
                            ) : (
                              <div
                                className={
                                  data.w === 1 ? "main-card-50" : "main-card"
                                }>
                                <FavPlaceholder
                                  title={"Your Favourites"}
                                  deleteCard={() => deleteCard(data.i)}
                                  setFavFeed={setFavFeed}
                                  favFeed={favFeed}
                                  data={data}
                                  enableDelete={enableDelete}
                                  handleHalfWidth={() =>
                                    handleHalfWidth("favorites")
                                  }
                                  handleFullWidth={() =>
                                    handleFullWidth("favorites")
                                  }
                                />
                              </div>
                            )}
                          </div>
                        ) : data.i === "stocks" ? (
                          <div className="main-card">
                            {stockFeed === true ? (
                              <div className="main-card-50">
                                <StockPlaceholder
                                  title={"Your Stocks"}
                                  deleteCard={() => deleteCard(data.i)}
                                  setStockFeed={setStockFeed}
                                  stockFeed={stockFeed}
                                  data={data}
                                  enableDelete={enableDelete}
                                  handleHalfWidth={() =>
                                    handleHalfWidth("stocks")
                                  }
                                  handleFullWidth={() =>
                                    handleFullWidth("stocks")
                                  }
                                />
                              </div>
                            ) : (
                              <div
                                className={
                                  data.w === 1 ? "main-card-50" : "main-card"
                                }>
                                <StockPlaceholder
                                  title={"Your Stocks"}
                                  deleteCard={() => deleteCard(data.i)}
                                  setStockFeed={setStockFeed}
                                  stockFeed={stockFeed}
                                  data={data}
                                  enableDelete={enableDelete}
                                  handleHalfWidth={() =>
                                    handleHalfWidth("stocks")
                                  }
                                  handleFullWidth={() =>
                                    handleFullWidth("stocks")
                                  }
                                />
                              </div>
                            )}
                          </div>
                        ) : data.i === "trainings" ? (
                          <div className="main-card">
                            {trainings === true ? (
                              <div className="main-card-50">
                                <TrainingsDashboardPlaceholder
                                  title={"Your Trainings"}
                                  deleteCard={() => deleteCard(data.i)}
                                  setTrainings={setTrainings}
                                  trainings={trainings}
                                  data={data}
                                  enableDelete={enableDelete}
                                  handleHalfWidth={() =>
                                    handleHalfWidth("trainings")
                                  }
                                  handleFullWidth={() =>
                                    handleFullWidth("trainings")
                                  }
                                />
                              </div>
                            ) : (
                              <div
                                className={
                                  data.w === 1 ? "main-card-50" : "main-card"
                                }>
                                <TrainingsDashboardPlaceholder
                                  title={"Your Trainings"}
                                  deleteCard={() => deleteCard(data.i)}
                                  setTrainings={setTrainings}
                                  trainings={trainings}
                                  data={data}
                                  enableDelete={enableDelete}
                                  handleHalfWidth={() =>
                                    handleHalfWidth("trainings")
                                  }
                                  handleFullWidth={() =>
                                    handleFullWidth("trainings")
                                  }
                                />
                              </div>
                            )}
                          </div>
                        ) : data.i === "lunch" ? (
                          <div className="main-card">
                            {lunchFeed === true ? (
                              <div className="main-card-50">
                                <LunchPlaceholder
                                  title={"Your Lunch"}
                                  deleteCard={() => deleteCard(data.i)}
                                  setLunchFeed={setLunchFeed}
                                  lunchFeed={lunchFeed}
                                  data={data}
                                  enableDelete={enableDelete}
                                  handleHalfWidth={() =>
                                    handleHalfWidth("lunch")
                                  }
                                  handleFullWidth={() =>
                                    handleFullWidth("lunch")
                                  }
                                />
                              </div>
                            ) : (
                              <div
                                className={
                                  data.w === 1 ? "main-card-50" : "main-card"
                                }>
                                <LunchPlaceholder
                                  title={"Your Lunch"}
                                  deleteCard={() => deleteCard(data.i)}
                                  setLunchFeed={setLunchFeed}
                                  lunchFeed={lunchFeed}
                                  data={data}
                                  enableDelete={enableDelete}
                                  handleHalfWidth={() =>
                                    handleHalfWidth("lunch")
                                  }
                                  handleFullWidth={() =>
                                    handleFullWidth("lunch")
                                  }
                                />
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="main-card">
                            {sacWidget === true ? (
                              <div className="main-card-50">
                                <SacPlaceholder
                                  title={"SAC Dashboard"}
                                  deleteCard={() => deleteCard(data.i)}
                                  setSacWidget={setSacWidget}
                                  sacWidget={sacWidget}
                                  data={data}
                                  enableDelete={enableDelete}
                                  handleHalfWidth={() => handleHalfWidth("sac")}
                                  handleFullWidth={() => handleFullWidth("sac")}
                                />
                              </div>
                            ) : (
                              <div
                                className={
                                  data.w === 1 ? "main-card-50" : "main-card"
                                }>
                                <SacPlaceholder
                                  title={"SAC Dashboard"}
                                  deleteCard={() => deleteCard(data.i)}
                                  setSacWidget={setSacWidget}
                                  sacWidget={sacWidget}
                                  data={data}
                                  enableDelete={enableDelete}
                                  handleHalfWidth={() => handleHalfWidth("sac")}
                                  handleFullWidth={() => handleFullWidth("sac")}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </ReactGridLayout>
              </div>
            ) : (
              <div style={{ maxWidth: "100%", height: "100vh" }}>
                <Loader />
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex pt-1 pb-3">
          <Button
            onClick={props.handleClose}
            className="app-secondary-btn me-3">
            Cancel
          </Button>
          <Button
            onClick={(_) => saveLayout(currentLayout)}
            className="app-primary-btn">
            {loading === true ? "Saving..." : "Save"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
