import React, { useState, useEffect, useRef } from "react";
import wernerIcon from "../../assets/images/wernerIcon.svg";
import maxChatBot from "../../assets/images/maxChatBot.svg";
import minChatBot from "../../assets/images/minChatBot.svg";
import Clearchatfilled from "../../assets/images/Clearchatfilled.svg";
import { getBaseUrl, getWernerUrl } from "../../config/environment";
import DislikeModal from "./DislikeModal";
import FeedbackButtons from "./FeedbackButtons";
import "./FinChatBot.scss";
import FormatString from "./FormatString";
import { CATEGORIES } from "../../config/Constants";
import Tooltip from "@mui/material/Tooltip";
import ImageSvgs from "../ImageSvgs";
import TypingLoader from "./TypingLoader";

const closeIcon =
  "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgMzU3IDM1NyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzU3IDM1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnIGlkPSJjbGVhciI+CgkJPHBvbHlnb24gcG9pbnRzPSIzNTcsMzUuNyAzMjEuMywwIDE3OC41LDE0Mi44IDM1LjcsMCAwLDM1LjcgMTQyLjgsMTc4LjUgMCwzMjEuMyAzNS43LDM1NyAxNzguNSwyMTQuMiAzMjEuMywzNTcgMzU3LDMyMS4zICAgICAyMTQuMiwxNzguNSAgICIgZmlsbD0iI0ZGRkZGRiIvPgoJPC9nPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=";

const FinChatBot = () => {
  let past_conversations = [];

  const profileImg = JSON.parse(
    localStorage.getItem("userData")
  ).profilePicture;

  const [feedbackData, setFeedbackData] = useState(null);
  const [feedbackList, setFeedbackList] = useState(
    JSON.parse(localStorage.getItem("feedbackList")).data
  );

  const [msgList, setMsgList] = useState(
    JSON.parse(localStorage.getItem("chatHistory")).data
  );
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const messagesEndRef = useRef(null);
  const [isStreamingDone, setIsStreamingDone] = useState(false);
  const [streamData, setStreamData] = useState("");
  const initialState = JSON.parse(localStorage.getItem("chatState")) || {
    spanColors: CATEGORIES.map((category) =>
      category.value === "all" ? "#f3e0ff" : "white"
    ),
    currentCategory: null,
    parentCategory: null,
    searchArea: "all",
  };
  const [chatState, setChatState] = useState(initialState);
  const [fullScreen, setFullScreen] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [selectedModel, setSelectedModel] = useState("Finance_center");
  const [placeholderText, setPlaceholderText] = useState(
    "Chat with all content..."
  );

  useEffect(() => {
    setPlaceholderText(getPlaceholderText());
    chatState.searchArea === ""
      ? setSelectedModel("llm")
      : setSelectedModel("Finance_center");
    // eslint-disable-next-line
  }, [chatState.searchArea]);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "chatHistory") {
        const updatedChatHistory = JSON.parse(event.newValue);
        setMsgList(updatedChatHistory.data);
      } else if (event.key === "chatState") {
        const updatedChatState = JSON.parse(event.newValue);
        setChatState(updatedChatState);
      }
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleQuickSearch = (index, value) => {
    const selectedCategory =
      CATEGORIES.find((cat) => cat.value === value) ||
      chatState.currentCategory?.children.find(
        (child) => child.value === value
      );

    if (selectedCategory.children) {
      if (value === "finLearnings") {
        const firstChildValue = selectedCategory.children[0].value;
        const newSpanColors = selectedCategory.children.map((cat) =>
          cat.value === firstChildValue ? "#f3e0ff" : "white"
        );
        const newChatState = {
          ...chatState,
          parentCategory: chatState.currentCategory,
          currentCategory: selectedCategory,
          spanColors: newSpanColors,
          searchArea: firstChildValue,
        };
        setChatState(newChatState);
        localStorage.setItem("chatState", JSON.stringify(newChatState));
      } else {
        const newChatState = {
          ...chatState,
          parentCategory: chatState.currentCategory,
          currentCategory: selectedCategory,
          spanColors: selectedCategory.children.map((cat) =>
            cat.value === value ? "#f3e0ff" : "white"
          ),
          searchArea: value,
        };
        setChatState(newChatState);
        localStorage.setItem("chatState", JSON.stringify(newChatState));
      }
    } else {
      const newSpanColors = chatState.spanColors.map((color, i) =>
        i === index ? (color === "#f3e0ff" ? "white" : "#f3e0ff") : "white"
      );
      const newSearchArea = chatState.searchArea === value ? "" : value;
      const newChatState = {
        ...chatState,
        spanColors: newSpanColors,
        searchArea: newSearchArea,
      };
      setChatState(newChatState);
      localStorage.setItem("chatState", JSON.stringify(newChatState));
    }
  };

  const handleBackClick = () => {
    const newChatState = {
      ...chatState,
      currentCategory: chatState.parentCategory,
      parentCategory: null,
      spanColors: CATEGORIES.map((category) =>
        category.value === "finLearnings" ? "#f3e0ff" : "white"
      ),
    };
    setChatState(newChatState);
    localStorage.setItem("chatState", JSON.stringify(newChatState));
  };

  const clearChatHistory = () => {
    setMsgList([]);
    past_conversations = [];
    const updatedChatHistory = JSON.parse(localStorage.getItem("chatHistory"));
    updatedChatHistory.data = [
      {
        id: "1",
        sender: "bot",
        message: "Welcome to the **SE Finance Center!** How may I help you?",
        links: [],
        reference: null,
      },
    ];
    localStorage.setItem("chatHistory", JSON.stringify(updatedChatHistory));
    localStorage.setItem("feedbackList", JSON.stringify({ data: [] }));
    setFeedbackList([]);
    setMsgList(JSON.parse(localStorage.getItem("chatHistory")).data);
  };

  const handleSendMessage = async () => {
    if (input.trim() === "") return;

    setMsgList([...msgList, { sender: "user", message: input }]);
    setInput("");
    setIsLoading(true);
    setFeedbackData(null);
    let tempMsgList = msgList && msgList;

    const chatData = tempMsgList.slice(-6);
    for (let i = 0; i < chatData.length; i++) {
      if (chatData[i].sender === "bot" && chatData[i].userInfoWithQuestion) {
        past_conversations.push({
          human: chatData[i].userInfoWithQuestion,
          ai: chatData[i].message,
        });
        i++;
      }
    }

    const payload = {
      data: {
        type: "werner",
        attributes: {
          filters: {
            index_name: selectedModel,
            search_space:
              chatState.searchArea === "all" || chatState.searchArea === ""
                ? []
                : chatState.searchArea === "recordings" ||
                  chatState.searchArea === "learnings"
                ? ["finergy"]
                : [chatState.searchArea],
            finergy_space:
              chatState.searchArea === "recordings" ||
              chatState.searchArea === "learnings"
                ? [chatState.searchArea]
                : [],
          },
          question: input,
          "past-conversations": past_conversations,
        },
      },
    };
    const newMessage = {
      id: (tempMsgList.length + 1).toString(),
      message: input,
      direction: "outgoing",
      links: [],

      sender: "user",
    };
    tempMsgList.push(newMessage);

    try {
      //! This is the old way of sending the request
      // const chatRes = await postData(`${getWernerUrl()}werner`, payload);
      //! This is the new way of sending the request
      const chatRes = await fetch(`${getWernerUrl()}werner/stream`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("idToken")}`,
          "Content-Type": "application/json",
          Accept: "text/event-stream",
        },
        body: JSON.stringify(payload),
      });

      if (!chatRes.body) {
        throw new Error("Response body is empty");
      }
      //!Read the response body as a stream
      const reader = chatRes.body.getReader();
      const decoder = new TextDecoder();
      let streamResult = "";

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });
        setIsStreamingDone(true);

        streamResult += chunk;
        setStreamData(streamResult);

        if (chunk.includes("streaming=done")) {
          setIsStreamingDone(false);
          break;
        }
      }

      if (chatRes.status === 200 && isStreamingDone === false) {
        const jsonResponse = streamResult.split("streaming=done")[1]?.trim();

        if (jsonResponse) {
          let parsedJson = JSON.parse(jsonResponse);

          const {
            answer,
            Links,
            user,
            "chat-completion-model-name": chatModel,
          } = parsedJson.data.attributes;

          localStorage.setItem("chatModel", chatModel);

          const chatGPTResponse = {
            id: (tempMsgList.length + 1).toString(),
            message: answer,
            links: Links,
            userInfoWithQuestion: user,
            chatModel,
            direction: "incoming",
            sender: "bot",
          };

          tempMsgList.push(chatGPTResponse);

          setMsgList(tempMsgList);
          localStorage.setItem(
            "chatHistory",
            JSON.stringify({
              data: tempMsgList,
            })
          );
        }
      }
    } catch (error) {
      const chatGPTResponse = {
        id: (tempMsgList.length + 1).toString(),
        message: "Sorry, that took longer than expected. Please try again.",
        links: [],
        chatModel: null,
        direction: "incoming",
        sender: "bot",
      };
      tempMsgList.push(chatGPTResponse);
      setMsgList((prevMessages) => [
        ...prevMessages,
        {
          sender: "bot",
          id: chatGPTResponse.id,
          message: chatGPTResponse.message,
          links: chatGPTResponse.links,
          chatModel: chatGPTResponse.chatModel,
          feedback: null,
        },
      ]);

      localStorage.setItem(
        "chatHistory",
        JSON.stringify({
          data: tempMsgList,
        })
      );
    } finally {
      setIsLoading(false);
      scrollToBottom();
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [msgList, isOpen]);

  const profileImgURL =
    profileImg === "null" || profileImg === ""
      ? "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Windows_10_Default_Profile_Picture.svg/2048px-Windows_10_Default_Profile_Picture.svg.png"
      : `${getBaseUrl()}${profileImg}`;

  const DefaultMsg = () => {
    return (
      <p className="mb-0 mx-auto text-muted default-msg">
        Werner uses AI
        {localStorage.getItem("chatModel")
          ? ` including ${localStorage.getItem("chatModel")}`
          : ""}
        . Verify responses before making decisions.
      </p>
    );
  };

  const categoriesToDisplay = chatState.currentCategory
    ? chatState.currentCategory.children
    : CATEGORIES;

  const getPlaceholderText = () => {
    if (chatState.searchArea === "all") {
      return "Chat with all content...";
    } else if (chatState.searchArea === "finergy") {
      return "Chat with FINergy content...";
    } else if (chatState.searchArea === "") {
      setSelectedModel("llm");
      return "Chat with Language Model...";
    } else {
      return `Chat with ${chatState.searchArea} content...`;
    }
  };

  return (
    <>
      {/* Floating Button */}
      {!isOpen && (
        <div className="floatingButton" onClick={() => setIsOpen(true)}>
          <img className="icon" src={wernerIcon} alt="Title Icon" />
        </div>
      )}

      {/* Chatbot Window */}
      {isOpen && (
        <div>
          <div className="floatingButton" onClick={() => setIsOpen(false)}>
            <img
              className="close-icon"
              src={closeIcon}
              alt="Close Icon"
              data-testid="close-bot"
            />
          </div>
          <div
            className={`chatbot-container ${fullScreen ? "fullScreen" : ""}`}>
            <div className="d-flex flex-row chatbot-header">
              <>
                <img className="icon" src={wernerIcon} alt="Title Icon" />
              </>
              <span className="d-flex justify-content-between w-100">
                <span className="mr-auto my-auto">
                  <p className="fw-bold mb-0 fs-5">Werner</p>
                  <p className="fw-normal mb-0 fs-6">Your virtual assistant</p>
                </span>
                <span className="d-flex ms-auto">
                  <button
                    data-placement="top"
                    title={"Clear Chat"}
                    className="me-1 chatbot-header-icon"
                    onClick={clearChatHistory}>
                    <Tooltip title="Clear Chat" arrow>
                      <img
                        src={Clearchatfilled}
                        alt="Close Icon"
                        data-testid="clear-chat"
                      />
                    </Tooltip>
                  </button>
                  <button
                    data-placement="top"
                    title={fullScreen ? "Minimize" : "Maximize"}
                    className="chatbot-header-icon"
                    onClick={() => {
                      setFullScreen(!fullScreen);
                    }}>
                    <img
                      src={fullScreen ? minChatBot : maxChatBot}
                      alt="header-icon"
                    />
                  </button>
                </span>
              </span>
            </div>
            <div className="messagesContainer">
              {msgList.map((msg, index) => (
                <React.Fragment key={index}>
                  <div
                    key={index}
                    className={`message ${
                      msg.sender === "user" ? "userMessage" : "botMessage"
                    }`}>
                    {msg.sender === "bot" && (
                      <img
                        className="msg-icon"
                        src={wernerIcon}
                        alt="Title Icon"
                      />
                    )}
                    {msg.sender === "user" && (
                      <img
                        className="msg-icon"
                        src={profileImgURL}
                        alt="Title Icon"
                      />
                    )}
                    <div className="message-bg me-2">
                      {FormatString(msg.message)}

                      {msg?.links?.length > 0 && (
                        <div className="d-flex mt-2">
                          <b className="me-2">References:</b>
                          <span className="d-flex flex-wrap">
                            {msg?.links.map((elem, index) => (
                              <span key={index} className="reference-link">
                                <a
                                  className="werner-links me-2 px-1 fw-bold"
                                  href={elem.split(" ").join("")}
                                  target="_blank"
                                  rel="noreferrer"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={elem.split(" ").join("")}>
                                  {index + 1}
                                </a>
                              </span>
                            ))}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  {msg.sender === "bot" && (
                    <div>
                      <FeedbackButtons
                        id={msg.id}
                        feedbackList={feedbackList}
                        setFeedbackList={setFeedbackList}
                        msgList={msgList}
                        setModalShow={setModalShow}
                        setFeedbackData={setFeedbackData}
                      />
                    </div>
                  )}
                </React.Fragment>
              ))}
              {isLoading && (
                <div className="message botMessage my-5">
                  <img className="msg-icon" src={wernerIcon} alt="Title Icon" />
                  {isStreamingDone ? (
                    <div className="message-bg">
                      <p> {FormatString(streamData)} </p>
                    </div>
                  ) : (
                    <div className="message-bg">
                      <TypingLoader />
                    </div>
                  )}
                </div>
              )}

              <div className="center-text">
                {localStorage.getItem("chatModel") && <DefaultMsg />}
              </div>
              <div ref={messagesEndRef} />
            </div>

            <div className="chatbot-footer">
              <div className="categories inputContainer">
                {chatState.currentCategory && (
                  <span onClick={handleBackClick} className="back-button">
                    <ImageSvgs name="goBack" />
                  </span>
                )}
                {categoriesToDisplay.map((category, index) => (
                  <span
                    key={category.value}
                    className={`badge rounded-pill category-badge text-bg-${chatState.spanColors[index]}`}
                    onClick={() => handleQuickSearch(index, category.value)}
                    style={{
                      backgroundColor: chatState.spanColors[index],
                    }}>
                    {category.label}
                  </span>
                ))}
              </div>

              <div className="inputContainer">
                <input
                  type="text"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
                  placeholder={placeholderText}
                  className="input w-75"
                />
                <button onClick={handleSendMessage} className="sendButton m-0">
                  <img
                    src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgNTM1LjUgNTM1LjUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUzNS41IDUzNS41OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPGcgaWQ9InNlbmQiPgoJCTxwb2x5Z29uIHBvaW50cz0iMCw0OTcuMjUgNTM1LjUsMjY3Ljc1IDAsMzguMjUgMCwyMTYuNzUgMzgyLjUsMjY3Ljc1IDAsMzE4Ljc1ICAgIiBmaWxsPSIjY2JjYmNiIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg=="
                    alt="Send"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="feedback-modal">
        {modalShow ? (
          <DislikeModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            feedbackData={feedbackData && feedbackData}
            feedbackList={feedbackList}
            setFeedbackList={setFeedbackList}
          />
        ) : null}
      </div>
    </>
  );
};

export default FinChatBot;
