import React, { useState, useEffect, useCallback } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { ButtonGroup, Button } from "react-bootstrap";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { getBaseUrl, getHostUrl } from "../../config/environment";
import { getData } from "../../services/apiService";
import Loader from "../Loader/Loader";
import "./FinergyDashboard.scss";
import { showNotificationAlert } from "../CommonComponents/NotificationAlert/showNotificationAlert";

const URL_TO_FETCH_FINERGY_SUBNAVIAGATION_JSON = `${getBaseUrl()}/configurations/FiNergy/SubNavigation.json`;

const FinergyDashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [parentId, setParentId] = useState();
  const [activeTab, setActiveTab] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedDropdownItem, setSelectedDropdownItem] = useState(null);
  const [subNavigation, setSubNavigation] = useState("");

  useEffect(() => {
    (async () => {
      let param = `${getHostUrl()}hierarchy`;
      const res = await getData(param);
      try {
        const response = await fetch(URL_TO_FETCH_FINERGY_SUBNAVIAGATION_JSON);
        const data = await response.json();
        setSubNavigation(data || "");
      } catch (error) {
        setSubNavigation("Error fetching the introduction.");
      }

      if (res.status === 200) {
        setParentId(
          res?.data?.data?.filter(
            (elem) =>
              elem?.attributes?.["type-data"]?.data?.attributes?.[
                "type-field-name"
              ] === "Finance Fields"
          )[0]?.id
        );
        setLoading(false);
      }
      // eslint-disable-next-line
    })();

    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    if (
      location.pathname === "/landscape/finergy" ||
      location.pathname.includes("overview")
    ) {
      setActiveTab("overview");
      navigate("/landscape/finergy/overview");
    } else if (location.pathname.includes("learning")) {
      setActiveTab("learning");
    }
    // eslint-disable-next-line
  }, [activeTab, location.pathname]);

  const handleTabChange = useCallback(
    (tab) => {
      setActiveTab(tab);
      if (tab === "overview") {
        navigate("/landscape/finergy/overview");
      } else if (tab === "learning") {
        navigate(`/landscape/finergy/learning/${parentId}`);
      }
    },
    [navigate, parentId]
  );

  const renderDropdownButtons = () => {
    if (!subNavigation || typeof subNavigation !== "object") {
      return true;
    }

    return Object.keys(subNavigation).map((key) => {
      const items = subNavigation[key];
      const title = key?.replace(/_/g, " ");

      return (
        <DropdownButton
          as={ButtonGroup}
          variant="primary"
          title={title}
          className={`tools-tab-dropdown-btn ${
            activeTab === title.toLowerCase() ? "active" : ""
          }`}
          id={`bg-nested-dropdown-${key}`}
          key={key}
          onSelect={(eventKey) => setSelectedDropdownItem(eventKey)}
          onClick={() => handleTabChange(title.toLowerCase())}>
          {items?.map((item) => (
            <Dropdown.Item
              key={item.eventKey}
              eventKey={item.eventKey}
              as="a"
              href={item.href}
              target="_blank"
              rel="noopener noreferrer"
              className={
                selectedDropdownItem === item.eventKey ? "selected" : ""
              }>
              {item.label}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      );
    });
  };

  return (
    <div className="finDashboard">
      {loading === false ? (
        <div className="main-internal-page mx-auto mt-4">
          <div className="page-header d-flex flex-column flex-md-row align-items-center border-bottom tabs-section">
            <div className="w-25">
              <h2 className="fw-bold">FINergy Academy</h2>
            </div>
            <div className="w-75 d-flex flex-wrap justify-content-end">
              <ButtonGroup
                aria-label="First group"
                className="d-flex flex-wrap justify-content-start">
                <Button
                  className={`tools-tab-btn ${
                    activeTab === "overview" ? "active" : ""
                  }`}
                  variant="primary"
                  onClick={() => handleTabChange("overview")}>
                  Overview
                </Button>
                <Button
                  className={`tools-tab-btn ${
                    activeTab === "learning" ? "active" : ""
                  }`}
                  variant="primary"
                  onClick={() => handleTabChange("learning")}>
                  Learning
                </Button>
                {renderDropdownButtons()}
                <Button
                  className={`tools-tab-btn ${
                    activeTab === "" ? "active" : ""
                  }`}
                  variant="primary"
                  onClick={() =>
                    showNotificationAlert("info", <>Coming soon.. </>)
                  }>
                  Short-term Assignments
                </Button>
              </ButtonGroup>
            </div>
          </div>
          <div className="mt-3">
            <div className="finergy-route">
              <Outlet />
            </div>
          </div>
        </div>
      ) : (
        <div className="loader">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default FinergyDashboard;
