import React, { useState } from "react";
import ReactGridLayout from "react-grid-layout";
import NewsTileComponent from "../components/NewsTileComponent/NewsTileComponent";
import YammerComp from "../components/YammerComp/YammerComp";
import Favorite from "../components/Favorite/Favorite";
import "../components/CommonComponents/CustomizableHomePage.scss";
import { getHostUrl } from "../config/environment";
import { useEffect, useRef } from "react";
import { getData } from "../services/apiService";
import { getBaseUrl } from "../config/environment";
import { defaultUserConfig } from "../config/Constants";
import { patchData } from "../services/apiService";
import { sortBy } from "lodash";
import Loader from "../components/Loader/Loader";
import getComponentsData from "../utils/retireveSectionData";
import ImageCarousel from "../components/ImageCarousel/ImageCarousel";
import MainStocksComp from "../components/YourStocks/MainStocksComp";
import MainLunchComp from "../components/YourLunch/MainLunchComp";
import MainComp from "../components/TrainingsDashboard/MainComp";
import "../../src/components/CommonComponents/CustomizableHomePage.scss";
import SacDashboard from "../components/SacDashboard/SacDashboard";

const Home = () => {
  const [layouts, setLayouts] = useState([]);
  const [homeData, setHomeData] = useState(null);
  const [bannerdata, setBannerData] = useState(null);
  const [favoritedata, setFavoriteData] = useState(null);
  const [propsData, setPropsData] = useState([]);
  // eslint-disable-next-line
  const [showPopup, setShowPopup] = useState(false);
  const url = `${getBaseUrl()}/configurations/home/home.json`;
  const param = `${getHostUrl()}user/config`;
  const gridLayoutParentRef = useRef(null);
  const [gridLayoutWidth, setGridLayoutWidth] = useState(
    window?.innerWidth - 20
  );

  const defaultLayout = [
    { i: "fin gs", x: 0, y: 0, w: 2, h: 6 },
    { i: "company", x: 0, y: 6, w: 1, h: 6 },
    { i: "yammer", x: 1, y: 6, w: 1, h: 6 },
    { i: "stocks", x: 1, y: 12, w: 1, h: 6 },
    { i: "lunch", x: 0, y: 12, w: 1, h: 6 },
    { i: "favorites", x: 0, y: 18, w: 2, h: 6 },
    { i: "trainings", x: 0, y: 24, w: 2, h: 6 },
    { i: "sac", x: 0, y: 30, w: 2, h: 6 },
  ];
  const setComponentsData = (propsData) => {
    propsData.forEach((data) => {
      if (data.section["card-type"] === "banner") {
        setBannerData(data);
      } else if (data.section["card-type"] === "tile") {
        setFavoriteData(data);
      } else if (data.section["card-type"] === "news-tile") {
        return null;
      }
    });
  };

  const createComponents = async () => {
    try {
      const resp = await getData(url);
      setHomeData(resp.data.sections);
      const sections = resp.data.sections;
      const sortedSections = sortBy(sections, "order");
      const propsData = await getComponentsData(sortedSections);
      setPropsData(await Promise.all(propsData));
    } catch (error) {
      console.log(error.response);
    }
  };

  const layoutConfig = async () => {
    try {
      const layoutData = await getData(param);
      if (layoutData.data.data.attributes.configuration != null) {
        setLayouts(layoutData.data.data.attributes.configuration.homeConfig);
      } else {
        layoutUpdate();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const rowValue = JSON.parse(localStorage.getItem("userConfig"));
  const userId = JSON.parse(localStorage.getItem("userData"));

  const layoutUpdate = async () => {
    const payLoad = {
      data: {
        type: "user",
        id: userId.userId,
        attributes: {
          configuration: {
            row: rowValue?.row
              ? rowValue?.row
              : defaultUserConfig?.configuration.row,
            homeConfig: defaultLayout
              ? defaultLayout
              : defaultUserConfig?.configuration.homeConfig,
            lunchData: rowValue?.lunchData
              ? rowValue?.lunchData
              : defaultUserConfig?.configuration.lunchData,
          },
          sacLinks: rowValue?.sacLinks
            ? rowValue?.sacLinks
            : defaultUserConfig?.configuration.sacLinks,
        },
      },
    };

    try {
      const res = await patchData(`${getHostUrl()}user/config`, payLoad);
      localStorage.setItem(
        "userConfig",
        payLoad?.data.attributes.configuration
      );

      setLayouts(res?.data.data.attributes.configuration.homeConfig);
    } catch (error) {
      console.log(error);
    }
  };

  function reCalculateWidth() {
    if (gridLayoutParentRef && gridLayoutParentRef.current) {
      setGridLayoutWidth(gridLayoutParentRef.current?.clientWidth);
    }
  }

  useEffect(() => {
    window.addEventListener("resize", reCalculateWidth);

    return () => window.removeEventListener("resize", reCalculateWidth);
  }, [gridLayoutWidth]);

  useEffect(() => {
    reCalculateWidth();
  }, [gridLayoutParentRef]);

  useEffect(() => {
    layoutConfig();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (propsData.length) {
      setComponentsData(propsData);
    } else {
      createComponents();
    }
    // eslint-disable-next-line
  }, [propsData]);

  useEffect(() => {
    if (!localStorage.getItem("has_cookie_consent")) {
      setTimeout(() => {
        setShowPopup(true);
      }, 2000);
    }
  }, []);

  if (!bannerdata && !favoritedata) {
    return (
      <div style={{ maxWidth: "100%", height: "100vh" }}>
        <Loader />
      </div>
    );
  }

  return (
    <div className="grid-container">
      <ImageCarousel
        props={
          homeData?.filter((section) =>
            section.name.toLowerCase().includes("banner")
          )[0]
        }
      />
      <div ref={gridLayoutParentRef}>
        {layouts?.length > 0 ? (
          <>
            <ReactGridLayout
              className="layout"
              cols={2}
              rowHeight={85}
              width={gridLayoutWidth}
              compactType={"vertical"}
              layout={layouts}
              isDroppable={false}
              isResizable={false}
              isDraggable={false}
              resizeHandle={null}
              style={{ minHeight: "300px" }}>
              {layouts.map((data) => {
                return (
                  <div key={data.i} style={{ padding: "0.3rem" }}>
                    <div
                      key={data.i}
                      className="grid-item"
                      style={{
                        backgroundColor: "#F6F4FF",
                        minHeight: "33rem",
                        padding: "0.5rem",
                      }}>
                      {" "}
                      {data?.i === "company" ? (
                        <div
                          className="w-100 "
                          key="1"
                          data-grid={{ w: data.w, h: 6, x: data.x, y: data.y }}>
                          <NewsTileComponent
                            props={
                              homeData?.filter((section) =>
                                section.name.toLowerCase().includes("company")
                              )[0]
                            }
                            fullSize={data.w === 1 ? false : true}
                          />
                        </div>
                      ) : data?.i === "fin gs" ? (
                        <div className="w-100">
                          <NewsTileComponent
                            props={
                              homeData?.filter((section) =>
                                section.name
                                  .toLowerCase()
                                  .includes("finance news")
                              )[0]
                            }
                            fullSize={data.w === 1 ? false : true}
                          />
                        </div>
                      ) : data?.i === "yammer" ? (
                        <div className="w-100">
                          <YammerComp
                            props={
                              homeData?.filter((section) =>
                                section.name.toLowerCase().includes("yammer")
                              )[0]
                            }
                            fullSize={data.w === 1 ? false : true}
                          />
                        </div>
                      ) : data?.i === "favorites" ? (
                        <div className="w-100">
                          <Favorite
                            props={
                              homeData?.filter((section) =>
                                section.name.toLowerCase().includes("favorites")
                              )[0]
                            }
                            fullSize={data.w === 1 ? false : true}
                          />
                        </div>
                      ) : data.i === "stocks" ? (
                        <div className="w-100">
                          {
                            <MainStocksComp
                              fullSize={data.w === 1 ? false : true}
                            />
                          }
                        </div>
                      ) : data.i === "trainings" ? (
                        <div className="w-100">
                          {<MainComp fullSize={data.w === 1 ? false : true} />}
                        </div>
                      ) : data.i === "lunch" ? (
                        <div className="w-100">
                          <MainLunchComp
                            fullSize={data.w === 1 ? false : true}
                          />
                        </div>
                      ) : (
                        <div className="w-100">
                          <SacDashboard
                            fullSize={data?.w === 1 ? false : true}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </ReactGridLayout>
          </>
        ) : (
          <div style={{ maxWidth: "100%", height: "100vh" }}>
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
