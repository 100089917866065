import { msalInstance, loginRequest } from "../authConfig";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import axios from "axios";

export const apiClient = axios.create({});
let tokenPromise = null;
const getValidAccessToken = async () => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length === 0) {
    clearTokens();
    // throw new Error("No user accounts found");
  }

  const request = { account: accounts[0], ...loginRequest };
  try {
    return await msalInstance.acquireTokenSilent(request);
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      if (!tokenPromise) {
        tokenPromise = msalInstance
          .acquireTokenRedirect(request)
          .then((response) => {
            tokenPromise = null;
            return response;
          })
          .catch((popupError) => {
            tokenPromise = null;
            clearTokens();
            // throw popupError;
          });
      }
      return tokenPromise;
    } else {
      clearTokens();
    }
  }
};
apiClient.interceptors.request.use(
  async (config) => {
    try {
      const token = await getValidAccessToken();
      if (token) {
        localStorage.setItem("idToken", token?.idToken);
        localStorage.setItem("accessToken", token?.accessToken);
        config.headers.Authorization = `Bearer ${token?.idToken}`;
        if (config.url.includes("yammer")) {
          config.headers.yammerToken = `${token.accessToken}`;
          config.headers.Authorization = `Bearer ${token.idToken}`;
        }
        if (
          config.url.includes("sharepoint.com") &&
          localStorage.getItem("sharePointAccessToken")
        ) {
          config.headers.Authorization = `Bearer ${localStorage.getItem(
            "sharePointAccessToken"
          )}`;
        }
        return config;
      } else {
        clearTokens();
      }
    } catch (error) {
      console.error("Error acquiring token:", error);
      clearTokens();
      return Promise.reject(error);
    }
  },
  (error) => Promise.reject(error)
);

const keepSessionAlive = () => {
  setInterval(async () => {
    try {
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length === 0) return;

      const token = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });
      localStorage.setItem("idToken", token?.idToken);
      localStorage.setItem("accessToken", token?.accessToken);
      // console.log("Session refreshed successfully");
    } catch (error) {
      clearTokens();
      console.error("Auto-refresh failed:", error);
    }
  }, 30 * 60 * 1000); // Refresh every 30 minutes
};

// Start auto-refresh logic
keepSessionAlive();
// Function to clear tokens from localStorage
const clearTokens = () => {
  localStorage.clear();
};

// Example logout function
const logout = () => {
  clearTokens();
  msalInstance.logoutRedirect();
};

export { clearTokens, logout };
