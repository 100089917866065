import { useState } from "react";
import { Table } from "react-bootstrap";
import { UserConfirmationModal } from "../../utils/userManagementUtils";
import ImageSvgs from "../ImageSvgs";
import Avatar from "react-avatar";
import { getBaseUrl } from "../../config/environment";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import "./AdminScreen.scss";

const UserTable = ({ data, included, getRes }) => {
  const navigate = useNavigate();
  const [showUserModal, setShowUserModal] = useState(false);
  const [userData, setUserData] = useState(null);
  return (
    <>
      <Table className="main-table" striped>
        <thead className="user-table-head">
          <tr>
            <th>User Name</th>
            <th>Email</th>
            <th>Is Admin</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {!getRes ? (
            data?.length > 0 ? (
              data?.map((item) => (
                <tr
                  key={item.id}
                  className="table-striped-bg"
                  style={{ height: "3.5rem" }}>
                  <td className="my-auto">
                    <span
                      className="me-2"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (
                          included?.filter(
                            (user) =>
                              user.type === "employee" &&
                              user.attributes["email-id"] ===
                                item.attributes["unique-name"]
                          ).length > 0
                        ) {
                          navigate(
                            `/employee_relation?id=${
                              included?.filter(
                                (user) =>
                                  user.type === "employee" &&
                                  user.attributes["email-id"] ===
                                    item.attributes["unique-name"]
                              )[0].id
                            }`
                          );
                        }
                      }}>
                      {included?.filter(
                        (user) =>
                          user.type === "employee" &&
                          user.attributes["email-id"] ===
                            item.attributes["unique-name"]
                      ).length > 0 &&
                      included?.filter(
                        (user) =>
                          user.type === "employee" &&
                          user.attributes["email-id"] ===
                            item.attributes["unique-name"]
                      )[0].attributes["profile-picture"] ? (
                        <img
                          className="my-auto"
                          src={`${getBaseUrl()}${
                            included?.filter(
                              (user) =>
                                user.type === "employee" &&
                                user.attributes["email-id"] ===
                                  item.attributes["unique-name"]
                            )[0].attributes["profile-picture"]
                          }`}
                          style={{
                            height: "2.3rem",
                            width: "2.3rem",
                            border: "1.5px solid black",
                            borderRadius: "7rem",
                            objectFit: "contain",
                          }}
                          alt="User"
                        />
                      ) : (
                        <Avatar
                          name={
                            item.attributes["first-name"] +
                            " " +
                            item.attributes["last-name"]
                          }
                          size="38"
                          round={true}
                        />
                      )}
                    </span>

                    {item.attributes["user-id"] === "-1" ? (
                      <>
                        <span className="me-1">
                          {item.attributes["first-name"] +
                            " " +
                            item.attributes["last-name"]}
                        </span>
                        <span
                          className="img"
                          data-toggle="tooltip"
                          data-placement="top"
                          title={`${
                            item.attributes["first-name"] +
                            " " +
                            item.attributes["last-name"]
                          } has not yet logged into the application.`}></span>
                      </>
                    ) : (
                      <>
                        <span className="me-1">
                          {item.attributes["first-name"] +
                            " " +
                            item.attributes["last-name"]}
                        </span>
                      </>
                    )}
                  </td>
                  <td className="d-flex my-auto">
                    <span
                      className="img me-2"
                      onClick={() =>
                        navigator.clipboard.writeText(
                          item.attributes["unique-name"]
                        )
                      }
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Copy email">
                      <ImageSvgs name="copy" />
                    </span>
                    <span
                      className="img me-2"
                      onClick={() =>
                        navigator.clipboard.writeText(
                          item.attributes["unique-name"]
                        )
                      }
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Mail to">
                      <a href={`mailto:${item.attributes["unique-name"]}`}>
                        <ImageSvgs name="mail" />
                      </a>
                    </span>
                    <span className="text-overflow">
                      {item.attributes["unique-name"]}
                    </span>
                  </td>
                  <td>
                    {item.attributes["is-admin"] ? (
                      <b className="text-success fs-4">
                        {<ImageSvgs name="isAdminCheck" />}
                      </b>
                    ) : (
                      <b className="text-danger mt-2">
                        {<ImageSvgs name="notAdminCheck" />}
                      </b>
                    )}
                  </td>
                  <td>
                    {item.attributes["is-admin"] ? (
                      <span
                        className="is-admin-btn"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Remove as admin"
                        onClick={() => {
                          setShowUserModal(true);
                          setUserData(item);
                        }}>
                        <span className="me-2  mt-2 d-flex">
                          <ImageSvgs name="remove_admin" />
                          <span className="app-tertiary-btn px-2">
                            Remove as Admin
                          </span>
                        </span>
                      </span>
                    ) : (
                      <span
                        className="is-admin-btn"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Set as admin"
                        onClick={() => {
                          setShowUserModal(true);
                          setUserData(item);
                        }}>
                        <span className="me-2 mt-2 d-flex">
                          <ImageSvgs name="add_admin" />
                          <span className="app-tertiary-btn px-2">
                            Set as Admin
                          </span>
                        </span>
                      </span>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <div className="d-flex justify-content-center no-data-check">
                <ImageSvgs name="no_data" />
              </div>
            )
          ) : (
            <div style={{ maxWidth: "100%", height: "30rem" }}>
              <Loader />
            </div>
          )}
        </tbody>
      </Table>

      {userData ? (
        <UserConfirmationModal
          data={userData}
          show={showUserModal}
          onHide={() => setShowUserModal(false)}
        />
      ) : null}
    </>
  );
};

export default UserTable;
