import React from "react";
import { useEffect, useState } from "react";
// import { getData } from "../../../services/apiService";
import { getBaseUrl } from "../../../config/environment";
import { Stack, Carousel, OverlayTrigger, Tooltip } from "react-bootstrap";
import GenericCard from "../../Cards/GenericCard";
import GenericTags from "../../CommonComponents/GenericTags/GenericTags";
import OwnerProfile from "../../CommonComponents/OwnerProfile";
import { UserModal } from "../../../utils/toolsUtils";
import { Rating } from "@mui/material";
import { FaChartBar } from "react-icons/fa";
import "./TrendingLearnings.scss";

const TrendingLearnings = ({ data }) => {
  const [showUserModal, setShowUserModal] = useState(false);
  const [ownerData, setOwnerData] = useState(false);

  useEffect(() => {
    (async () => {})();
  }, []);

  const handleCardClick = (url) => {
    window.open(url, "_blank");
  };

  const tags = (deliveryType) => {
    const deliveryTags =
      deliveryType?.data?.length > 0
        ? deliveryType?.data?.map((item) => ({
            id: item.id,
            title: item.attributes["delivery-name"],
            link: null,
            itemId: null,
          }))
        : [];

    if (deliveryTags.length === 0) {
      return <div className="empty-tags" />;
    }

    return (
      <div className="d-flex mt-1 mb-1">
        <GenericTags
          data={deliveryTags}
          className="learning-tags"
          modalTitle="Delivery Types"
        />
      </div>
    );
  };

  const cardFooter = (rating, owner, count) => {
    return (
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mt-2">
        <div className="d-flex flex-wrap">
          {owner?.slice(0, 1)?.map((value, index) => (
            <OwnerProfile key={index} owner={value} isOwnerHasLink={true} />
          ))}
          {owner?.length > 1 ? (
            <button
              className="more-user-btn"
              onClick={async (e) => {
                e.stopPropagation();
                setOwnerData(owner);
                setShowUserModal(true);
              }}
            >
              + {owner?.length - 1} more
            </button>
          ) : null}
        </div>

        <div className="d-flex justify-content-end align-items-center mt-3 mt-md-0">
          <div className="d-flex me-3">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`tooltip-header`}>{`${
                  rating === null ? "0.0" : rating?.toFixed(1)
                } / 5.0`}</Tooltip>
              }
            >
              <Stack spacing={1} className="rating-stack me-2">
                {rating === 0 || rating === null ? (
                  <Rating
                    name="size-large"
                    defaultValue={1}
                    size="large"
                    className="disabled-rating"
                  />
                ) : (
                  <Rating name="size-large" defaultValue={1} size="large" />
                )}
              </Stack>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`tooltip-header`}>{`${
                  count ? count : 0
                } users completed this course recently`}</Tooltip>
              }
            >
              <Stack spacing={1} className="rating-stack">
                <FaChartBar className="mt-2" />
              </Stack>
            </OverlayTrigger>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="d-flex flex-column">
      <div className="mb-3">
        <p className="fw-bold fs-4 m-0">Trending Learnings </p>
      </div>
      <div className="trending-learnings">
        <Carousel
          interval={null}
          wrap={false}
          controls={false}
          className="trending-learnings-carousel"
        >
          {data?.map((elem, i) => (
            <Carousel.Item key={i}>
              <Stack
                direction="horizontal"
                className="h-100 align-items-center"
                gap={2}
              >
                {elem?.map((record, i) => (
                  <GenericCard
                    id={record?.id?.toString()}
                    header={record?.attributes["courses-title"]}
                    tags={tags(record?.attributes["class-delivery-name-data"])}
                    description={record?.attributes["courses-description"]}
                    overlayTriggers={{
                      header: record?.attributes["courses-title"],
                      description: record?.attributes["courses-description"],
                    }}
                    imageSrc={
                      record?.attributes?.["course-image"]
                        ? `${getBaseUrl()}${
                            record?.attributes?.["course-image"]
                          }`
                        : `${getBaseUrl()}/images/default-images/default-news.jpg`
                    }
                    imageAlt={`${getBaseUrl()}/images/default-images/default-news.jpg`}
                    isHover={true}
                    onClick={() =>
                      handleCardClick(
                        record?.attributes?.["courses-redirect-url"]
                      )
                    }
                    footer={cardFooter(
                      record?.attributes["courses-average-rating"],
                      record?.attributes.owners.data,
                      record?.attributes["completed-training-count"]
                    )}
                  />
                ))}
              </Stack>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
      {showUserModal === true ? (
        <UserModal
          title={"Learning Owners:-"}
          listdata={ownerData}
          show={showUserModal}
          onHide={() => setShowUserModal(false)}
        />
      ) : null}
    </div>
  );
};

export default TrendingLearnings;
