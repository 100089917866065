import { ApiClient } from "jsonapi-react";
import { schema } from "../schemas/schema";
import { getHostUrl } from "../config/environment";

export function getJsonApiClient() {
  return new ApiClient({
    url: getHostUrl(),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("idToken")}`,
    },
    schema,
  });
}
