import React, { useState, useEffect, useRef } from "react";
import "./ImageUpload.scss";
import ImageSvgs from "../ImageSvgs";
import { getBaseUrl } from "../../config/environment";

function removeItems(arr, item) {
  for (var i = 0; i < item; i++) {
    arr.pop();
  }
}

function useFiles({ initialState = [], maxFiles }) {
  const [state, setstate] = useState(initialState);
  function withBlobs(files) {
    const destructured = [...files];
    if (destructured.length > maxFiles) {
      const difference = destructured.length - maxFiles;
      removeItems(destructured, difference);
    }
    const blobs = destructured
      .map((file) => {
        if (file.type.includes("image")) {
          file.preview = URL.createObjectURL(file);
          return file;
        }
        return null;
      })
      .filter((elem) => elem !== null);

    setstate(blobs);
  }
  return [state, withBlobs];
}

function ImageUpload({
  onDrop,
  maxFiles = 1,
  setIsDeleted,
  setIsPreviewDeleted,
  isAppImage,
  ...props
}) {
  const [over, setover] = useState(false);
  const [files, setfiles] = useFiles({ maxFiles });
  const [editImage, setEditImage] = useState(props.editImage);
  const validTypes = ["image/jpg", "image/jpeg", "image/png"];
  const $input = useRef(null);
  useEffect(() => {
    if (onDrop) {
      onDrop(files);
    }
    // eslint-disable-next-line
  }, [files]);

  return (
    <div className={props.className}>
      <div className="upload_image_container d-flex justify-content-start">
        <div
          onClick={() => {
            $input.current.click();
          }}
          onDrop={(e) => {
            e.preventDefault();
            e.persist();
            setfiles(e.dataTransfer.files);
            setEditImage(null);
            setover(false);
          }}
          onDragOver={(e) => {
            e.preventDefault();
            setover(true);
          }}
          onDragLeave={(e) => {
            e.preventDefault();
            setover(false);
          }}
          className={over ? "upload-container over" : "upload-container"}>
          <p>{<ImageSvgs name="upload_image" />}</p>

          <input
            style={{ display: "none" }}
            type="file"
            accept="image/*"
            disabled={props.disabled}
            ref={$input}
            onChange={(e) => {
              setfiles(e.target.files);
              setEditImage(null);
            }}
            multiple={maxFiles > 1}
          />
          {files[0] || editImage ? (
            <p>
              {" "}
              <strong>Preview Image:</strong>
            </p>
          ) : null}
        </div>
        <div className="blob-container">
          {editImage && !files[0] ? (
            <div style={{ position: "relative" }}>
              <img
                // key={file.name + "file"}
                src={`${getBaseUrl()}${editImage}`}
                alt="your file"
              />
              <button
                onClick={() => {
                  setEditImage(null);
                  if (isAppImage) {
                    setIsDeleted(true);
                  } else {
                    setIsPreviewDeleted(true);
                  }
                }}
                disabled={props.disabled}
                className="fw-bold"
                style={{
                  backgroundColor: "white",
                  border: "1px solid black",
                  borderRadius: "7rem",
                  color: "#8900E5",
                  height: "1.3rem",
                  width: "1.3rem",
                  fontSize: "12px",
                  padding: "0px",
                  position: "absolute",
                  top: "8px",
                }}>
                X
              </button>
            </div>
          ) : (
            <>
              {files.map((file) => (
                <>
                  {file.size <= 2000000 && validTypes.includes(file["type"]) ? (
                    <div style={{ position: "relative" }}>
                      <img
                        key={file.name + "file"}
                        src={file.preview}
                        alt="your file"
                      />
                      <button
                        onClick={() => {
                          setfiles([]);
                          if (isAppImage) {
                            setIsDeleted(true);
                          } else {
                            setIsPreviewDeleted(true);
                          }
                        }}
                        className="fw-bold"
                        style={{
                          backgroundColor: "white",
                          border: "1px solid black",
                          borderRadius: "7rem",
                          color: "#8900E5",
                          height: "1.3rem",
                          width: "1.3rem",
                          fontSize: "12px",
                          padding: "0px",
                          position: "absolute",
                          top: "8px",
                        }}>
                        X
                      </button>
                    </div>
                  ) : (
                    <p className="text-danger">
                      File size is too large/Invalid image type.
                    </p>
                  )}
                </>
              ))}
            </>
          )}
        </div>
      </div>

      <p className="upload-text">
        {" "}
        <small>
          <strong> Upload or drop an image here - (less than 2MB)</strong>
        </small>{" "}
      </p>
      <p className="upload-text para">
        {" "}
        <small>Uploaded image should be only in .png, .jpeg format.</small>{" "}
      </p>
    </div>
  );
}

export { ImageUpload };
