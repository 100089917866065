import React, { useState, useEffect } from "react";
import GenericCard from "./../../Cards/GenericCard";
import ImageSvgs from "./../../ImageSvgs";
import OwnerProfile from "../../CommonComponents/OwnerProfile";
import ProcessDetailsPage from "./ProcessDetailPage";
import { useLocation } from "react-router-dom";
import GenericTags from "../../CommonComponents/GenericTags/GenericTags";
import "./ProcessRender.scss";

const ProcessRender = ({
  processDetails,
  activePro,
  handleProcessData,
  navigate,
  activeBtn,
  handleEmployeeModalClick,
  included,
  groupQuery,
  orgQuery,
  allLoading,
  setDeleteFlag,
}) => {
  const location = useLocation();
  const [showDetail, setShowDetail] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const processId = parseInt(queryParams.get("process_id"), 10);

    if (processId && processId !== 0) {
      setShowDetail(true);
    } else {
      setShowDetail(false);
    }
    // eslint-disable-next-line
  }, [location]);

  const handleProcessClick = (processId) => {
    sessionStorage.removeItem("processPayload");
    sessionStorage.removeItem("processEditData");
    sessionStorage.removeItem("updateId");
    sessionStorage.removeItem("processFlag");
    sessionStorage.removeItem("processIncluded");
    handleProcessData(processId);
    const queryParams = new URLSearchParams({
      process_id: processId,
      view: activeBtn,
    });

    let selectedProcessFilter = {};

    if (orgQuery?.length > 0) {
      selectedProcessFilter.orgQuery = orgQuery;
    }

    if (groupQuery?.length > 0) {
      selectedProcessFilter.groupQuery = groupQuery;
    }

    if (Object.keys(selectedProcessFilter).length > 0) {
      const encodedFilter = encodeURIComponent(
        JSON.stringify(selectedProcessFilter)
      );
      queryParams.append("selectedProcessFilter", encodedFilter);
    }

    navigate(`/landscape/process?${queryParams.toString()}`, {
      state: { prevPath: window.location.href },
    });
    setShowDetail(true);
  };

  const handleClose = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("process_id", 0);
    navigate(`/landscape/process?${queryParams.toString()}`, {
      state: { prevPath: window.location.href },
    });
    setShowDetail(false);
  };

  const tags = (orgIds) => {
    const filteredOrgs = orgIds
      .map((orgId) => {
        const org =
          included?.length > 0 &&
          included?.find(
            (orgItem) => orgItem.id === orgId.id && orgItem.type === "orgs"
          );

        return org
          ? {
              id: org.id,
              title:
                org?.attributes?.["org-master"]?.data?.attributes?.[
                  "org-title"
                ],
              link: `/landscape/organization?id=${org.attributes["org-id"]}`,
            }
          : null;
      })
      .filter((org) => org !== null);

    if (filteredOrgs.length === 0) {
      return <div className="empty-tags-fallback" />;
    }

    return (
      <GenericTags
        data={filteredOrgs}
        className="link-item"
        modalTitle="Associated Org Codes"
      />
    );
  };

  return (
    <>
      <div className="grid-container">
        {processDetails?.length > 0 ? (
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-5 row-cols-xl-5 row-cols-xxl-7 ">
            {processDetails.map((process) => {
              const isActive =
                process.id?.toString() === activePro?.id?.toString();

              const renderOwners = () => {
                const owners = process?.relationships?.owners?.data || [];
                return (
                  <div className="owner-profiles">
                    {owners.slice(0, 3).map((elem, index) => {
                      const owner = included?.find(
                        (item) =>
                          item.type === "owners" &&
                          item.id.toString() === elem.id.toString()
                      );

                      return (
                        <OwnerProfile
                          key={index}
                          owner={owner}
                          isOwnerHasLink={true}
                        />
                      );
                    })}
                    {owners.length > 3 && (
                      <button
                        className="more-user-btn"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEmployeeModalClick(process);
                        }}
                      >
                        + {owners.length - 3} more
                      </button>
                    )}
                  </div>
                );
              };

              return (
                <div className="col" key={process.id}>
                  <GenericCard
                    id={process.id.toString()}
                    isActive={isActive}
                    header={process.attributes.title}
                    description={process.attributes.desc}
                    tags={tags(process.relationships.orgs.data)}
                    onClick={() => handleProcessClick(process.id)}
                    overlayTriggers={{
                      header: process.attributes.title,
                      description: process.attributes.desc,
                    }}
                    customClass="process-cards"
                    footer={renderOwners()}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <div className="d-flex justify-content-center no-data-check">
            <ImageSvgs name="no_data" />
          </div>
        )}
      </div>
      {showDetail && (
        <ProcessDetailsPage
          hide={handleClose}
          show={true}
          activeData={true}
          activeBtn={true}
          deleted={false}
          setIsDeleted={true}
          allLoading={allLoading}
          setDeleteFlag={setDeleteFlag}
        />
      )}
    </>
  );
};

export default ProcessRender;
