import { getAuthConfig } from "./config/environment";
import { LogLevel } from "@azure/msal-browser";
import { PublicClientApplication } from "@azure/msal-browser";

const config = getAuthConfig();

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: config.clientId,
    authority: config.authority,
    redirectUri: config.redirectUri,
    postLogoutRedirectUri: config.postLogoutRedirectUri,
  },
  cache: {
    cacheLocation: config.cacheLocation,
    storeAuthStateInCookie: config.storeAuthStateInCookie,
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker

    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};
export const msalInstance = new PublicClientApplication(msalConfig);

export const loginRequest = {
  scopes: ["https://api.yammer.com/user_impersonation"],
};
