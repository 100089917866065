import "../NavbarComp/NavbarComp.scss";
import React, { useContext, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { FiLogOut } from "react-icons/fi";
import { UserContext } from "../../contexts/UserContext";
import { NavLink, useNavigate } from "react-router-dom";
import ConfirmationBox from "../CustomTable/ConfirmationBox";
import { useClient } from "jsonapi-react";
import { profileIcon } from "../../utils/iconUtils";
import ImageSvgs from "../ImageSvgs";
import logo from "../../assets/images/se-header-logo.png";
import { adminIcon } from "../../utils/iconUtils";
import { getBaseUrl } from "../../config/environment";
import empImg from "../../assets/images/EmployeesBar.svg";
import appImg from "../../assets/images/ApplicationsBar.svg";
import proImg from "../../assets/images/ProcessBar.svg";
import trainImg from "../../assets/images/TrainingsBar.svg";
import toolImg from "../../assets/images/ToolsBar.svg";
import handImg from "../../assets/images/HandbookBar.svg";
import orgImg from "../../assets/images/OrganizationBar.svg";
import commImg from "../../assets/images/CommunitiesBar.svg";
import fiNergyImg from "../../assets/images/CommunitiesBar.svg";
import { FiEdit } from "react-icons/fi";
import CustomizableHomeModal from "../CommonComponents/CustomizableHomeModal";
import UltimoCalendar from "../CommonComponents/Calendars/UltimoCalendar/UltimoCalendar";
import { logout } from "../../services/apiClient";
const editIcon = (
  <span>
    <FiEdit />
  </span>
);
const logoutIcon = (
  <span>
    <FiLogOut />
  </span>
);
const imageMap = {
  emp: empImg,
  apps: appImg,
  comm: commImg,
  hand: handImg,
  org: orgImg,
  process: proImg,
  tools: toolImg,
  train: trainImg,
  fiNergy: fiNergyImg,
};
export default function NavbarComp({ setExpiryTime }) {
  const { user } = useContext(UserContext);
  const initials = `${user.givenName.charAt(0)}${user.surname.charAt(0)}`;
  const client = useClient();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [url, setUrl] = useState();
  const navigate = useNavigate();
  const empId = JSON.parse(localStorage.getItem("userData")).employeeId;
  const [imgKey, setImgKey] = useState("emp");
  const [showEditModal, setShowEditModal] = useState(false);
  const profileImg = JSON.parse(
    localStorage.getItem("userData")
  ).profilePicture;

  const imageSrc = imageMap[imgKey] || fiNergyImg;

  const handleClick = (event) => {
    event.preventDefault();
    if (sessionStorage.getItem("flagCheck") === "true") {
      setIsModalOpen(true);
    } else {
      navigate(event.currentTarget.attributes.href.nodeValue);
    }
    setUrl(event.currentTarget.attributes.href.nodeValue);
  };

  const changeOrder = async () => {
    const folderList = JSON.parse(sessionStorage.getItem("updatedList"));

    let counter = folderList.length;

    folderList.forEach((favorite) => {
      if (favorite.order !== counter) {
        // eslint-disable-next-line
        const resData = client.mutate(["user-favorites", favorite.id], {
          is_folder: favorite["is-folder"],
          order: counter,
        });
      }
      counter--;
    });

    setIsModalOpen(false);
    sessionStorage.setItem("flagCheck", false);
    navigate(url);
  };

  const onOkClick = () => {
    setIsModalOpen(false);
    sessionStorage.setItem("flagCheck", false);
    navigate(url);
  };

  const onCancelClick = () => {
    setIsModalOpen(false);
    sessionStorage.setItem("flagCheck", true);
  };

  const handleSignOut = () => {
    logout();
  };

  return (
    <>
      <Navbar id="main-navbar" className="color-nav" expand="lg">
        <Container>
          <Navbar.Brand
            href="https://siemensenergyag.sharepoint.com/sites/intranet-landing"
            target="_blank">
            <img
              src={logo}
              width="85"
              height="30"
              className="d-inline-block align-top"
              alt="SE Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav id="demo-nav" className="me-auto">
              <NavLink
                className="nav-hover nav-link"
                to="/"
                onClick={handleClick}
                end>
                Your Home
              </NavLink>

              <NavDropdown
                className="ms-3"
                title="FIN Landscape"
                id="basic-nav-dropdown"
                onClick={() => setImgKey("emp")}>
                <div className="d-flex">
                  <>
                    <img
                      src={imageSrc}
                      className="p-2"
                      style={{ height: "14rem", width: "14rem" }}
                      alt="dropdown"
                    />
                  </>
                  <div className="d-flex flex-column">
                    <NavDropdown.Item
                      onMouseOver={() => {
                        setImgKey("apps");
                      }}
                      onClick={() => {
                        navigate("/landscape/applications?orgId=0&appId=0", {
                          state: { prevPath: window.location.href },
                        });
                      }}>
                      <div
                        className="dropdown-items w-100"
                        style={{ textDecoration: "none", display: "block" }}>
                        Applications
                      </div>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onMouseOver={() => {
                        setImgKey("comm");
                      }}
                      onClick={() => {
                        navigate("/landscape/communities?page=0", {
                          state: { prevPath: window.location.href },
                        });
                      }}>
                      <div
                        className="dropdown-items w-100"
                        style={{ textDecoration: "none", display: "block" }}>
                        Communities
                      </div>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={() => {
                        window.open(
                          "https://siemensenergyag.sharepoint.com/sites/finance-center/Handbooks/Forms/AllItems.aspx",
                          "_blank"
                        );
                      }}
                      onMouseOver={() => {
                        setImgKey("hand");
                      }}>
                      Handbooks
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onMouseOver={() => {
                        setImgKey("org");
                      }}
                      onClick={() => {
                        navigate("/landscape/organization?id=0", {
                          state: { prevPath: window.location.href },
                        });
                      }}>
                      <div
                        className="dropdown-items w-100"
                        style={{ textDecoration: "none", display: "block" }}>
                        Organizations
                      </div>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onMouseOver={() => {
                        setImgKey("process");
                      }}
                      onClick={() => {
                        navigate(
                          "/landscape/process?process_id=0&view=cardView",
                          { state: { prevPath: window.location.href } }
                        );
                      }}>
                      <div
                        className="dropdown-items w-100"
                        style={{ textDecoration: "none", display: "block" }}>
                        Processes
                      </div>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onMouseOver={() => {
                        setImgKey("tools");
                      }}
                      onClick={() => {
                        navigate("/landscape/tools?layerId=1&toolId=0", {
                          state: { prevPath: window.location.href },
                        });
                      }}>
                      <div
                        className="dropdown-items w-100"
                        style={{ textDecoration: "none", display: "block" }}>
                        Tools
                      </div>
                    </NavDropdown.Item>
                    {/* <NavDropdown.Item
                      onMouseOver={() => {
                        setImgKey("train");
                      }}
                      onClick={() => {
                        navigate("/landscape/trainings?id=0", {
                          state: { prevPath: window.location.href },
                        });
                      }}
                    >
                      <div
                        className="dropdown-items w-100"
                        style={{ textDecoration: "none", display: "block" }}
                      >
                        Trainings
                      </div>
                    </NavDropdown.Item> */}

                    <NavDropdown.Item
                      onMouseOver={() => {
                        setImgKey("train");
                      }}
                      onClick={() => {
                        navigate("/landscape/finergy/overview", {
                          state: { prevPath: window.location.href },
                        });
                      }}>
                      <div
                        className="dropdown-items w-100"
                        style={{ textDecoration: "none", display: "block" }}>
                        FINergy
                      </div>
                    </NavDropdown.Item>

                    {/* <NavDropdown.Item
                      onMouseOver={() => {
                        setImgKey("werner");
                      }}
                      onClick={() => {
                        navigate("/landscape/explorewerner", {
                          state: { prevPath: window.location.href },
                        });
                      }}>
                      <div
                        className="dropdown-items w-100"
                        style={{ textDecoration: "none", display: "block" }}>
                        Explore werner
                      </div>
                    </NavDropdown.Item> */}
                  </div>
                </div>
              </NavDropdown>
            </Nav>
            <Nav className="justify-content-end">
              <Nav.Link className="nav-side ms-auto mx-2 ">
                <div className="d-flex flex-column">
                  <div className="fw-bold" style={{ color: "#8a00e5" }}>
                    {" "}
                    SE Finance Center
                  </div>
                  <div
                    style={{
                      fontStyle: "italic",
                      fontSize: "small",
                      color: "#969696",
                    }}>
                    {" "}
                    Integrate to Innovate
                  </div>
                </div>
              </Nav.Link>
              <div className="d-flex my-auto">
                <UltimoCalendar />
              </div>
              <a
                className="my-auto ms-3 "
                style={{
                  marginTop: "1rem",
                  marginRight: "1rem",
                }}
                href="https://forms.office.com/e/he9DtXAGpz"
                target="_blank"
                rel="noreferrer">
                <ImageSvgs name="supportIcon" />
              </a>
              <NavDropdown
                title={
                  profileImg === null ||
                  profileImg === "null" ||
                  profileImg === "" ? (
                    <div id="circle" data-initials={initials}></div>
                  ) : (
                    <img
                      style={{
                        height: "2.3rem",
                        width: "2.3rem",
                        border: "1.5px solid black",
                        borderRadius: "7rem",
                      }}
                      src={`${getBaseUrl()}${profileImg}`}
                      alt="Profile"
                    />
                  )
                }
                id="collapsible-nav-dropdown"
                className="mt-1 navbar-dropdown"
                align={{ end: true }}>
                {empId && (
                  <NavDropdown.Item
                    onClick={() => {
                      let tempId = window.location.href.substring(
                        window.location.href.lastIndexOf("=") + 1
                      );
                      if (
                        window.location.href.includes("employee_relation") &&
                        tempId.toString() === empId.toString()
                      ) {
                      } else if (
                        window.location.href.includes("employee_relation") &&
                        tempId.toString() !== empId.toString()
                      ) {
                        window.location.assign(
                          `${window.location.href.substring(
                            0,
                            window.location.href.lastIndexOf("=") + 1
                          )}${empId}`
                        );
                      } else {
                        navigate(`/employee_relation?id=${empId}`);
                      }
                    }}>
                    <span className="fs-5 me-2">{profileIcon}</span>
                    My Profile
                  </NavDropdown.Item>
                )}

                {localStorage.getItem("adminCheck") === "true" ? (
                  <NavDropdown.Item onClick={() => navigate("/manage_users")}>
                    <span className="fs-5 me-2">{adminIcon}</span>Admin Panel
                  </NavDropdown.Item>
                ) : null}

                <NavDropdown.Item onClick={() => setShowEditModal(true)}>
                  <span className="fs-5 me-2"> {editIcon}</span>
                  Edit Widgets
                </NavDropdown.Item>

                <NavDropdown.Item onClick={() => handleSignOut()}>
                  <span className="fs-5 me-2">{logoutIcon}</span>Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <ConfirmationBox
        showDialog={isModalOpen}
        cancelNavigation={onCancelClick}
        confirmNavigation={onOkClick}
        saveNavigation={changeOrder}
      />
      {showEditModal ? (
        <CustomizableHomeModal
          show={showEditModal}
          handleClose={() => setShowEditModal(false)}
          leftTitle={"Configure Widgets"}
        />
      ) : (
        ""
      )}
    </>
  );
}
