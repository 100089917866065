import { useState } from "react";

export const useDebouncedEffect = (callback, delay) => {
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const debouncedCallback = (value) => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    const newTimeout = setTimeout(() => {
      callback(value);
    }, delay);
    setDebounceTimeout(newTimeout);
  };

  return debouncedCallback;
};
