import React, { useState, useEffect, useRef } from "react";
import TanstackReactFinTable from "../CommonComponents/TanstackReactFCTable/TanstackReactFinTable";
import ResponsivePagination from "react-responsive-pagination";
import { Button } from "react-bootstrap";
import ImageSvgs from "../ImageSvgs";
import { Nav } from "react-bootstrap";
import { getHostUrl } from "../../config/environment";
import { getData, deleteData } from "../../services/apiService";
import "./FinergyLearningAdminPage.scss";
import { FaStar } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import OwnerProfile from "../CommonComponents/OwnerProfile";
import Loader from "../Loader/Loader";
import { debounce } from "lodash";
import LearningDeleteModal from "./LearningDeletModal/LearningDeleteModal";
import { UserModal } from "../../utils/toolsUtils";
import GenericTags from "../CommonComponents/GenericTags/GenericTags";
import GenericFilter from "../CommonComponents/GenericFilter/GenericFilter";
import { useDebouncedEffect } from "../CommonComponents/useDebounceEffect";

const FinergyLearningAdminPage = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [learnings, setLearnings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [recordCount, setRecordCount] = useState(0);
  const [search, setSearch] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [courseTitle, setCourseTitle] = useState(null);
  const [courseId, setCourseId] = useState(null);
  const [isDeleted, setIsDeleted] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [ownerData, setOwnerData] = useState(false);
  const latestRequestRef = useRef(0);
  const [ownerQuery, setOwnerQuery] = useState("");
  const [deliveryType, setDeliveryType] = useState([]);
  const [ratings, setRatings] = useState([]);
  const [language, setLanguage] = useState([]);
  const [deliveryTypeOptions, setDeliveryTypeOptions] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [dropdownShow, setDropdownShow] = useState(false);
  const [filterCount, setFilterCount] = useState();
  const [finalQueries, setFinalQueries] = useState({
    deliveryType: [],
    ratings: [],
    language: [],
    owner: [],
    source: [],
  });
  const ratingOptions = [1, 2, 3, 4, 5].map((rating) => ({
    id: rating,
    name: String.fromCharCode(11088).repeat(rating),
  }));
  const sourceOptions = ["SELP", "SEFC"].map((source, i) => ({
    id: i,
    name: source,
  }));
  const [owner, setOwner] = useState([]);
  const [ownerOptions, setOwnerOptions] = useState([]);
  const [source, setSource] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const language = `${getHostUrl()}courses/lang`;
      const deliveryType = `${getHostUrl()}courses/delivery`;
      const deliveryRes = await getData(deliveryType);
      const languageRes = await getData(language);

      if (deliveryRes.status === 200 && languageRes.status === 200) {
        setLanguageOptions(languageRes.data.data);
        setDeliveryTypeOptions(deliveryRes.data.data);
      }
    }
    fetchData();
  }, []);
  const buildParams = () => {
    const params = [];

    const addParam = (key, value) => {
      if (value) {
        params.push(`${key}=${encodeURIComponent(value)}`);
      }
    };
    addParam("search", search);
    addParam("delivery_name", finalQueries.deliveryType);
    addParam("average_rating", finalQueries.ratings[0]?.id);
    addParam("lang", finalQueries.language);
    addParam("owner_id", finalQueries.owner);
    if (finalQueries.source?.length > 0) {
      addParam(
        "created_by",
        finalQueries.source[0] === "SELP" ? "system" : "user"
      );
    }

    return params;
  };

  const buildUrl = () => {
    const baseUrl = `${getHostUrl()}courses?template=id,courses_title,created_by,courses_description,courses_average_rating,class_delivery_name_data,courses_redirect_url,course_image,owners&use_pagination=true&page=${currentPage}&per_page=10`;

    const params = buildParams();

    const validParams = params.filter(
      (param) => param.includes("=") && param.split("=")[1] !== ""
    );

    if (validParams.length > 0 || finalQueries.ratings) {
      return `${getHostUrl()}courses/search?${validParams.join("&")}&${
        baseUrl.split("?")[1]
      }`;
    }
  };
  useEffect(() => {
    const fetchData = debounce(async () => {
      const requestId = ++latestRequestRef.current;
      setLoading(true);
      try {
        const endpoint = buildUrl();
        const response = await getData(endpoint);
        if (response.status === 200 && requestId === latestRequestRef.current) {
          setLearnings(response.data.data);
          setTotalPages(response.data.meta["page-count"]);
          setRecordCount(response.data.meta["record-count"]);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        if (requestId === latestRequestRef.current) {
          setLoading(false);
        }
      }
    }, 500);

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, isDeleted, search, finalQueries]);

  useEffect(() => {
    const counter = Object.values(finalQueries).filter(
      (arr) => arr.length > 0
    ).length;

    setFilterCount(counter);
    // eslint-disable-next-line
  }, [finalQueries]);

  const columns = React.useMemo(
    () => [
      {
        accessorKey: "attributes.courses-title",
        id: "title",
        cell: (info) => <div className="title-cell">{info.getValue()}</div>,
        header: () => <span>Title</span>,
        footer: (props) => props.column.id,
        size: 300,
        enableColumnFilter: false,
      },
      {
        accessorKey: "attributes.owners.data",
        enableColumnFilter: false,
        id: "owners",
        cell: (info) => {
          const owners = info.getValue();
          if (!owners || owners.length === 0) {
            return "-";
          }
          return (
            <div className="owners-cell">
              {owners.slice(0, 3).map((owner, index) => (
                <OwnerProfile key={index} owner={owner} isOwnerHasLink={true} />
              ))}
              {owners?.length > 3 ? (
                <button
                  className="more-user-btn"
                  onClick={async (e) => {
                    e.stopPropagation();
                    setOwnerData(owners);
                    setShowUserModal(true);
                  }}>
                  + {owners?.length - 3} more
                </button>
              ) : null}
            </div>
          );
        },
        header: () => <span>Owners</span>,
        footer: (props) => props.column.id,
        size: 300,
      },
      {
        accessorKey: "attributes.courses-average-rating",
        id: "score",
        cell: (info) => {
          const value = info.getValue();
          return value === 0 || value === null ? (
            <>
              <FaStar className="rating_none" /> N/A
            </>
          ) : (
            <>
              <FaStar className="rating_full" /> {value}
            </>
          );
        },
        header: () => <span>Rating</span>,
        footer: (props) => props.column.id,
        size: 200,
        enableColumnFilter: false,
      },
      {
        accessorKey: "attributes.class-delivery-name-data.data",
        id: "tags",
        cell: (info) => {
          const value = info.getValue();
          const tagsData = {
            data: value,
          };
          return tags(tagsData);
        },
        header: () => <span>Delivery Type</span>,
        footer: (props) => props.column.id,
        size: 350,
        enableColumnFilter: false,
      },
      {
        accessorKey: "attributes.created-by",
        id: "source",
        cell: (info) => (
          <div className="source-cell">
            {info.getValue() === "system" ? "SELP" : "SEFC"}
          </div>
        ),
        header: () => <span>Source</span>,
        footer: (props) => props.column.id,
        size: 200,
        enableColumnFilter: false,
      },
      {
        id: "actions",
        header: () => <span>Actions</span>,
        cell: ({ row }) => {
          const isSEFC = row.original.attributes["created-by"] === "system";

          return (
            <div className="actions-cell">
              <span
                className="edit-icon"
                data-testid="edit-icon"
                onClick={() => handleEdit(row.original)}>
                <ImageSvgs name="edit" />
              </span>
              <span className={isSEFC ? "disabled-icon" : ""}>
                <span
                  data-testid="delete-icon"
                  className="delete-icon"
                  onClick={() => !isSEFC && handleDelete(row.original)}>
                  <ImageSvgs name="delete" />
                </span>
              </span>
            </div>
          );
        },
        footer: (props) => props.column.id,
        size: 100,
        enableColumnFilter: false,
      },
    ],
    // eslint-disable-next-line
    []
  );

  const handleRatingChange = (value) => {
    setRatings(value);
  };
  const handleSourceChange = (value) => {
    setSource(value);
  };
  const handleLanguageChange = (value) => {
    setLanguage(value);
  };
  const handleOwnerChange = (value) => {
    setOwner(value);
  };

  const handleApply = () => {
    setCurrentPage(1);
    const deliveryTypeIds = deliveryType.map((item) => item.id).join(", ");
    const languageIds = language.map((item) => item.id).join(", ");
    const ownerId = owner.map((item) => item.id);
    const sourceId = source.map((item) => item.name);

    setFinalQueries({
      deliveryType: deliveryTypeIds,
      ratings: ratings,
      language: languageIds,
      owner: ownerId,
      source: sourceId,
    });
    setDropdownShow(false);
  };

  const handleCancelApply = () => {
    setDropdownShow(false);
  };

  const handleClear = () => {
    setCurrentPage(1);
    setFinalQueries({
      deliveryType: [],
      ratings: [],
      language: [],
      owner: [],
      source: [],
    });
  };
  const handleDeliveryTypeChange = (value) => {
    setDeliveryType(value);
  };

  const onOwnerInputChange = async (text) => {
    setOwnerQuery(text);
    if (text.length === 0 || text.length < 4) {
      setOwnerOptions([]);
    } else {
      try {
        const param = `${getHostUrl()}employee/search?search=${text}&is_gid=${true}&org_data=${true}&per_page=30`;
        const res = await getData(param);
        setOwnerOptions(res?.data.data);
      } catch (error) {
        console.error("Error occurred:", error);
      }
    }
  };

  const debouncedOnOwnerInputChange = useDebouncedEffect(
    onOwnerInputChange,
    500
  );

  const filterOptions = [
    {
      inputType: "multi-select",
      options: deliveryTypeOptions
        ?.map((value) => ({
          type: value.type,
          id: value.id,
          name: value.attributes["delivery-name"],
        }))
        .sort((a, b) => a.name.localeCompare(b.name)),
      queryKey: "deliveryType",
      placeholder: "Search by Delivery Type",
      multi: true,
      onChange: handleDeliveryTypeChange,
      selected: deliveryType,
      label: "Delivery Type",
    },
    {
      inputType: "multi-select",
      options: ratingOptions,
      queryKey: "rating",
      placeholder: "Search by Ratings",
      multi: false,
      onChange: handleRatingChange,
      selected: ratings,
      label: "Rating",
    },
    {
      inputType: "multi-select",
      options: languageOptions
        ?.map((lang) => ({
          type: lang.type,
          id: lang.id,
          name: lang.attributes["lang-name"],
        }))
        .sort((a, b) => a.name.localeCompare(b.name)),
      queryKey: "language",
      placeholder: "Search by Language",
      multi: true,
      onChange: handleLanguageChange,
      selected: language,
      label: "Language",
      disabled: false,
    },
    {
      inputType: "employee-search",
      options: ownerOptions?.map((item) => ({
        type: "employee",
        name: item.attributes["preferred-name"],
        email: item.attributes["email-id"],
        gid: item.attributes["gid"],
        attributes: item.attributes,
        image: item.attributes["profile-picture"],
        id: item.id,
        orgCode:
          item.attributes["employee-org-master"].data.attributes["org-title"],
      })),
      queryKey: "owner",
      placeholder: "Search by name, email or GID",
      multi: true,
      onChange: handleOwnerChange,
      selected: owner,
      label: "Owner",
      disabled: false,
      onInputChange: debouncedOnOwnerInputChange,
      ownerQuery: ownerQuery,
    },
    {
      inputType: "multi-select",
      options: sourceOptions,
      queryKey: "source",
      placeholder: "Search by source",
      multi: false,
      onChange: handleSourceChange,
      selected: source,
      label: "Source",
    },
  ];

  const handleSearch = (e) => {
    const value = e.target.value.trim();
    if (value) {
      setCurrentPage(1);
      setSearch(value);
    }
  };

  const handleEdit = (row) => {
    navigate(`/manage_learnings/edit_learning/${row.id}`);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const tags = (deliveryType) => {
    const deliveryTags =
      deliveryType?.data?.length > 0
        ? deliveryType?.data?.map((item) => ({
            id: item.id,
            title: item.attributes["delivery-name"],
            link: null,
            itemId: null,
          }))
        : [];

    if (deliveryTags.length === 0) {
      return <div className="empty-tags" />;
    }

    return (
      <div className="d-flex mt-1 mb-1">
        <GenericTags
          data={deliveryTags}
          className="learning-tags"
          modalTitle="Delivery Types"
        />
      </div>
    );
  };

  const deleteCourse = async () => {
    try {
      const res = await deleteData(`${getHostUrl()}courses/${courseId}`);
      if (res.status === 204) {
        setShowDeleteModal(false);
        setIsDeleted(!isDeleted);
      }
    } catch (error) {
      console.error("Error deleting the course: ", error);
    }
  };

  const handleDelete = (row) => {
    setShowDeleteModal(true);
    setCourseTitle(row.attributes["courses-title"]);
    setCourseId(row.id);
  };

  const onRowClick = (row) => {
    // Implement your row click logic here
  };
  const handleBackClick = () => {
    navigate("/manage_users");
  };

  return (
    <>
      <div className="p-5">
        <div className="responsive-container">
          <div className="back-icon d-flex">
            <Nav.Link onClick={handleBackClick} data-testid="back-button">
              <ImageSvgs name="goBack" />
            </Nav.Link>
            <span className="ps-1"> Back </span>
          </div>

          <div className="sub-bar d-flex justify-content-between">
            <p className="main-title" data-testid="main-title">
              Manage Learnings ({recordCount})
            </p>

            <div className="d-flex">
              <Button
                className="app-secondary-btn me-2 my-1"
                onClick={() => {
                  navigate("/manage_learnings/add_learning");
                }}
                data-testid="add-button">
                Add New Learning
              </Button>
              <div className="app-primary-input-field custom-search pt-1 ms-3">
                <input
                  type="search"
                  id="message"
                  placeholder="Search by Title"
                  onChange={handleSearch}
                />
              </div>
              <GenericFilter
                show={dropdownShow}
                onToggle={() => setDropdownShow(false)}
                onSelect={() => setDropdownShow(!dropdownShow)}
                filterOptions={filterOptions}
                onApply={handleApply}
                onCancel={handleCancelApply}
                onClear={handleClear}
                filterCount={filterCount && filterCount}
                title="Advanced Filters"
              />
            </div>
          </div>
          <div data-testid="learning-table" className="learning-table">
            {loading ? (
              <div className="learning-admin-loader">
                <Loader />
              </div>
            ) : learnings.length > 0 ? (
              <TanstackReactFinTable
                data={learnings}
                columns={columns}
                onRowClick={(row) => onRowClick(row.original)}
                isManualPagination={true}
                // columnsFilteredData={handleColumnsFilteredData}
              />
            ) : (
              <div className="d-flex justify-content-center no-data-check">
                <ImageSvgs name="no_data" />
              </div>
            )}
          </div>
        </div>

        {!loading && (
          <div
            className="w-100 mt-5 pb-2 d-flex justify-content-end"
            data-testid="pagination">
            <ResponsivePagination
              current={currentPage}
              total={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        )}
        {showDeleteModal && (
          <LearningDeleteModal
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
            title={courseTitle}
            modalContentWithTitle={"Learning Title"}
            confimationMessage={"Are you sure you want to delete this Course?"}
            deleteAction={deleteCourse}
          />
        )}
        {showUserModal === true ? (
          <UserModal
            title={"Learning Owners:-"}
            listdata={ownerData}
            show={showUserModal}
            onHide={() => setShowUserModal(false)}
          />
        ) : null}
      </div>
    </>
  );
};

export default FinergyLearningAdminPage;
