import { React, useState, useEffect } from "react";
import { Dropdown, Button } from "react-bootstrap";
import ImageSvgs from "../../ImageSvgs";
import { MultipleSelectDropdown } from "../MultipleSelect";
import { Typeahead } from "react-bootstrap-typeahead";
import { useQueryParams } from "./queryParams";
import { useLocation } from "react-router-dom";
import Badge from "@mui/material/Badge";
import Avatar from "react-avatar";
import { getBaseUrl } from "../../../config/environment";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "./GenericFilter.scss";
const pooperConfig = {
  modifiers: [
    {
      name: "flip",
      options: {
        fallbackPlacements: [], // Prevents any fallback to other placements
      },
    },
    {
      name: "offset",
      options: {
        offset: [-475, 1], // Adjusts the dropdown's position slightly for spacing
      },
    },
  ],
};
export default function GenericFilter({
  show,
  onToggle,
  onSelect,
  filterOptions,
  onApply,
  onCancel,
  title,
  filterCount,
}) {
  const { filters, setFilters } = useQueryParams(filterOptions);
  const [tempFilters, setTempFilters] = useState(filters);
  const location = useLocation();

  const decodeFilterOptions = (searchParams) => {
    const decodedParams = {};
    for (const [key, value] of searchParams.entries()) {
      try {
        decodedParams[key] = JSON.parse(decodeURIComponent(value));
      } catch (error) {
        decodedParams[key] = decodeURIComponent(value);
      }
    }

    return decodedParams;
  };

  useEffect(() => {
    const searchFilterValues = new URLSearchParams(location.search);
    const decodedFilterValues = decodeFilterOptions(searchFilterValues);
    setTempFilters(decodedFilterValues);
    setFilters(decodedFilterValues);
    // eslint-disable-next-line
  }, [location.search, show]);

  const handleFilterChange = (queryKey, selectedValues) => {
    setTempFilters((prevFilters) => ({
      ...prevFilters,
      [queryKey]: selectedValues,
    }));
  };

  const handleApply = () => {
    setFilters(tempFilters);
    onApply(tempFilters);
  };

  const handleCancel = () => {
    setTempFilters(filters);
    onCancel();
  };

  const handleClearAll = () => {
    setTempFilters(
      filterOptions.reduce((acc, dropdown) => {
        acc[dropdown.queryKey] = [];
        return acc;
      }, {})
    );

    filterOptions.forEach((dropdown) => {
      if (dropdown.inputType === "typeahead" && dropdown.onInputChange) {
        dropdown.onInputChange("");
      }
    });
  };

  const renderMenuItemChildren = (option) => (
    <div className="d-flex dlex-row">
      <div className="pt-3">
        {option.image !== null ? (
          <>
            <img
              className="profile-img my-auto"
              src={`${getBaseUrl()}${option?.image}`}
              alt="User Img"
              data-testid="profile-img"
            />
          </>
        ) : (
          <Avatar name={option?.attributes["preferred-name"]} size={34} round />
        )}
      </div>
      <div className="emp-details">
        <div className="user-name px-1"> {option?.name}</div>
        <div className="user-org-code px-1"> {option?.orgCode}</div>
      </div>
    </div>
  );

  const renderInputField = (dropdown) => {
    switch (dropdown.inputType) {
      case "multi-select":
        return (
          <div>
            <div className="pb-1">{dropdown.label}</div>
            <div className="app-primary-dropdown">
              <MultipleSelectDropdown
                color="#8A00E5"
                className="dropdown-select mb-2"
                multi={dropdown.multi}
                disabled={dropdown.disabled ? dropdown.disabled : false}
                placeholder={dropdown.placeholder}
                values={tempFilters[dropdown.queryKey] || dropdown.selected}
                onChange={(values) => {
                  handleFilterChange(dropdown.queryKey, values);
                  dropdown.onChange(values);
                }}
                options={dropdown.options?.map((value, index) => ({
                  type: value.type,
                  name: value.name,
                  id: value.id,
                }))}
              />
            </div>
          </div>
        );

      case "typeahead":
        return (
          <div>
            <div className="pb-2">{dropdown.label}</div>
            <div
              className={
                dropdown.multi
                  ? "app-secondary-dropdown"
                  : "app-primary-dropdown"
              }>
              <Typeahead
                labelKey="name"
                className="custom-typeahead"
                filterBy={["name"]}
                id="typeAhead"
                searchable
                emptyLabel={dropdown.emptyLabel}
                multiple={dropdown.multi}
                highlightOnlyResult={true}
                clearButton={true}
                placeholder={dropdown.placeholder}
                options={dropdown.options}
                selected={tempFilters[dropdown.queryKey] || dropdown.selected}
                onChange={(values) => {
                  handleFilterChange(dropdown.queryKey, values);
                  dropdown.onChange(values);
                }}
                onInputChange={dropdown.onInputChange}
              />
            </div>
          </div>
        );
      case "text":
        return (
          <div className="app-primary-input-field custom-search ">
            <div className="pb-2">{dropdown.label}</div>
            <input
              type="search"
              value={tempFilters[dropdown.queryKey] || dropdown.value || ""}
              onChange={(e) =>
                handleFilterChange(dropdown.queryKey, e.target.value)
              }
              placeholder={dropdown.placeholder}
            />
          </div>
        );
      case "employee-search":
        return (
          <div>
            <div className="pb-2">{dropdown.label}</div>

            <div className="app-secondary-dropdown ">
              <Typeahead
                labelKey="name"
                className="custom-typeahead"
                filterBy={["name", "email", "gid"]}
                id="typeAhead"
                searchable
                keepSelectedInList
                emptyLabel={
                  (dropdown?.ownerQuery?.length === "" ||
                    dropdown?.ownerQuery?.length < 4) &&
                  dropdown.selected.length === 0
                    ? "Keep typing..."
                    : Array.isArray(dropdown.selected) &&
                      dropdown.selected.length === 0 &&
                      dropdown?.ownerQuery?.length > 4
                    ? "No results"
                    : (tempFilters[dropdown.queryKey]?.length > 0 ||
                        dropdown?.selected?.length > 0) &&
                      (dropdown?.ownerQuery?.length < 4 ||
                        dropdown?.ownerQuery?.length === "")
                    ? "Keep typing a colleagues name, email or GID"
                    : "No results"
                }
                multiple={dropdown.multi}
                highlightOnlyResult={true}
                clearButton={true}
                placeholder={dropdown.placeholder}
                options={dropdown.options}
                selected={tempFilters[dropdown.queryKey] || dropdown.selected}
                onChange={(values) => {
                  handleFilterChange(dropdown.queryKey, values);
                  dropdown.onChange(values);
                }}
                onInputChange={(input) => {
                  const trimmedInput = input.trim();
                  dropdown.onInputChange(trimmedInput);
                }}
                renderMenuItemChildren={renderMenuItemChildren}
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <Dropdown
        show={show}
        onToggle={onToggle}
        onSelect={onSelect}
        className="custom-dropdown-main">
        <div className="position-relative d-inline-block">
          <Dropdown.Toggle onClick={onSelect}>
            <Badge badgeContent={filterCount && filterCount} color="error">
              <ImageSvgs name="filterLogo" />
            </Badge>
          </Dropdown.Toggle>
        </div>

        <Dropdown.Menu
          className="w-100 custom-dropdown"
          popperConfig={pooperConfig}>
          <div className="dropdown-title">{title}</div>
          {filterOptions?.map((input, index) => (
            <div className="pt-2" key={index}>
              {renderInputField(input)}
            </div>
          ))}

          <div className="d-flex justify-content-end font-weight-bold pt-4">
            <Button
              className="app-secondary-btn ms-3 my-auto"
              onClick={handleClearAll}>
              Clear All
            </Button>
            <Button
              className="app-secondary-btn ms-3 my-auto"
              onClick={handleCancel}>
              Cancel
            </Button>
            <Button className="app-primary-btn ms-3" onClick={handleApply}>
              Apply
            </Button>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
