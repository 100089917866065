import {
  useIsAuthenticated,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import "bootstrap/dist/css/bootstrap.min.css";
import NavbarComp from "./components/NavbarComp/NavbarComp";
import Footer from "./components/Footer/Footer";
import MainLandscape from "./pages/MainLandscape";
import FinGSLandscape from "./pages/FinGSLandscape";
import FavoritesPage from "./pages/FavoritesPage";
import UserManagement from "./pages/UserManagement";
import ApplicationsPage from "./pages/ApplicationsPage";
import EmpRelationPage from "./pages/EmpRelationPage";
import ApplicationsAdmin from "./pages/AdminScreens/ApplicationsAdmin";
import ToolsPage from "./pages/ToolsPage";
import OrganizationPage from "./pages/OrganizationPage";
import ManageTools from "./pages/ManageTools";
import { useEffect, useState, useMemo } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { getData, patchData } from "./services/apiService";
import { getBaseUrl } from "./config/environment";
import { UserContext } from "./contexts/UserContext";
import { ApiProvider } from "jsonapi-react";
import { getJsonApiClient } from "./services/jsonApiService";
import { configureTokens } from "./services/authService";
import Loader from "../src/components/Loader/Loader";
import { getHostUrl } from "./config/environment";
import CommunityPage from "./pages/CommunityPage";
import ManageCommunity from "./pages/ManageCommunity";
import ManageProcess from "./pages/ManageProcess";
import ProcessPage from "./pages/ProcessPage";
import Home from "./pages/Home";
import { defaultUserConfig } from "./config/Constants";
import FinChatBot from "./components/ChatBot/FinChatBot";
import "../src/assets/scss/common.scss";
import FinergyRoutes from "./routes/FinergyRoutes";
import { msalInstance } from "./authConfig";
import { showNotificationAlert } from "./components/CommonComponents/NotificationAlert/showNotificationAlert";
function App() {
  const isAuthenticated = useIsAuthenticated();
  const [isProfileLoaded, setProfileLoaded] = useState(false);
  const [user, setUser] = useState(null);
  const [client, setClient] = useState(null);
  const [emailId, setEmailId] = useState(null);
  const [wernerConfig, setWernerConfig] = useState([]);
  const [userConfig, setUserConfig] = useState(null);
  const userData = useMemo(() => ({ user, setUser }), [user, setUser]);
  const isIdToken = localStorage.getItem("idToken");

  async function getUserData(userId) {
    const res = await getData(`${getHostUrl()}user/check/${userId}`);
    localStorage.setItem("adminCheck", res.data);
  }

  useEffect(() => {
    (async () => {
      const resData =
        isIdToken &&
        (await getData(`${getBaseUrl()}/configurations/wernerConfig.json`));
      setWernerConfig(resData?.data);
    })();
    // eslint-disable-next-line
  }, [isIdToken]);

  useEffect(() => {
    const loadProfile = async () => {
      await configureTokens(msalInstance);

      const profile = await getData(
        `${getHostUrl()}user/profile?include=employee_data`
      );
      const finalProfile = {
        displayName: profile.data?.data?.attributes?.["name"] ?? "",
        givenName: profile.data?.data?.attributes?.["first-name"] ?? "",
        surname: profile.data?.data?.attributes?.["last-name"] ?? "",
        userPrincipalName:
          profile.data?.data?.attributes?.["unique-name"] ?? "",
        id: profile.data?.data?.attributes?.["user-id"] ?? "",
        orgId: profile.data?.included?.[0]?.attributes?.["org-id"] ?? "",
        orgFlag:
          profile.data?.included?.[0]?.attributes?.["employee-org-master"]?.data
            ?.attributes?.["org-chart-flag"] ?? null,
        userId: profile.data?.data?.id ?? "",
        employeeId: `${profile.data?.included?.[0]?.id ?? ""}`,
        profilePicture:
          profile.data?.included?.[0]?.attributes?.["profile-picture"] ?? "",
        location: profile.data?.included?.[0]?.attributes?.location ?? null,
      };

      setUser(finalProfile);
      await getUserData(finalProfile.id);
      if (!finalProfile?.employeeId) {
        showNotificationAlert(
          "warning",
          <>
            We could not find your user profile. If the issue persists, please
            reach out to &nbsp;
            <a
              href="mailto:se_finance_center.service@siemens-energy.com"
              className="mail-to"
            >
              se_finance_center.service@siemens-energy.com
            </a>
            .
          </>
        );
      }
      setEmailId(finalProfile.userPrincipalName);
      localStorage.setItem("userData", JSON.stringify(finalProfile));
      setClient(getJsonApiClient());

      const userConfigResponse = await getData(`${getHostUrl()}user/config`);
      let tempConfig = userConfigResponse?.data.data.attributes.configuration;

      if (
        !tempConfig?.row ||
        !tempConfig?.homeConfig ||
        !tempConfig?.lunchData
      ) {
        const url = `${getHostUrl()}user/config`;
        const payLoad = {
          data: {
            type: "user",
            id: finalProfile.userId,
            attributes: {
              configuration: {
                row: tempConfig?.row
                  ? tempConfig?.row
                  : defaultUserConfig?.configuration?.row,
                homeConfig: tempConfig?.homeConfig
                  ? tempConfig?.homeConfig
                  : defaultUserConfig?.configuration.homeConfig,
                lunchData: tempConfig?.lunchData
                  ? tempConfig?.lunchData
                  : defaultUserConfig?.configuration?.lunchData,
                sacLinks: tempConfig?.sacLinks
                  ? tempConfig?.sacLinks
                  : defaultUserConfig?.configuration?.sacLinks,
              },
            },
          },
        };

        try {
          const patchConfig = await patchData(url, payLoad);
          setUserConfig(patchConfig?.data.data.attributes.configuration);
          localStorage.setItem(
            "userConfig",
            patchConfig?.data.data.attributes.configuration
          );
        } catch (error) {
          console.error(error);
        }
      } else {
        sessionStorage.setItem(
          "userConfig",
          JSON.stringify(tempConfig && tempConfig)
        );
        setUserConfig(tempConfig);
      }
      setProfileLoaded(true);
    };
    if (!isAuthenticated) {
      msalInstance.loginRedirect();
    } else {
      (async function () {
        if (!userConfig) {
          await loadProfile();
        }
      })();
    }

    // eslint-disable-next-line
  }, [isIdToken]);

  return (
    <>
      <AuthenticatedTemplate>
        {isProfileLoaded ? (
          <ApiProvider client={client}>
            <Router>
              <UserContext.Provider value={userData}>
                <div className="App">
                  <NavbarComp />
                  <div>
                    <Routes>
                      <Route path="/" element={<Home />} exact />
                      <Route path="/favorites" element={<FavoritesPage />} />
                      <Route
                        path="/manage_users"
                        element={<UserManagement />}
                      />
                      <Route path="/manage_tools" element={<ManageTools />} />
                      <Route
                        path="/manage_communities"
                        element={<ManageCommunity />}
                      />

                      <Route path="/landscape" element={<MainLandscape />}>
                        <Route index element={<FinGSLandscape />} />
                        <Route
                          path="/landscape/v2/applications"
                          element={<ApplicationsPage />}
                        />
                        <Route
                          path="/landscape/applications"
                          element={<ApplicationsPage />}
                        />
                        <Route
                          path="/landscape/communities"
                          element={<CommunityPage />}
                        />
                        <Route
                          path="/landscape/v2/tools"
                          element={<ToolsPage />}
                        />
                        <Route
                          path="/landscape/tools"
                          element={<ToolsPage />}
                        />

                        <Route
                          path="/landscape/v2/organization"
                          element={<OrganizationPage />}
                        />
                        <Route
                          path="/landscape/organization"
                          element={<OrganizationPage />}
                        />
                        <Route
                          path="/landscape/process"
                          element={<ProcessPage />}
                        />
                      </Route>
                      <Route
                        path="/manage_applications"
                        element={<ApplicationsAdmin />}
                      />
                      <Route
                        path="/manage_process"
                        element={<ManageProcess />}
                      />
                      <Route
                        path="/employee_relation"
                        element={<EmpRelationPage />}
                      />
                      {FinergyRoutes()}
                      {/* {ExploreWerner()}  */}
                    </Routes>
                    {wernerConfig?.visible?.toLowerCase() === "all" ? (
                      <FinChatBot />
                    ) : wernerConfig?.visible?.toLowerCase() === "admins" &&
                      emailId &&
                      wernerConfig?.data?.includes(emailId) ? (
                      <FinChatBot />
                    ) : null}
                  </div>
                  <Footer />
                </div>
              </UserContext.Provider>
            </Router>
          </ApiProvider>
        ) : (
          <div style={{ maxWidth: "100%", height: "100vh" }}>
            <Loader />
          </div>
        )}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div style={{ maxWidth: "100%", height: "100vh" }}>
          <Loader />
          {/* <button>Login here</button> */}
        </div>
      </UnauthenticatedTemplate>
    </>
  );
}

export default App;
