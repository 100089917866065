import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { FaCalendar } from "react-icons/fa";
import { getData } from "../../../../services/apiService";
import { getBaseUrl } from "../../../../config/environment";
import { Button } from "react-bootstrap";
import "./UltimoCalendar.scss";
import "react-datepicker/dist/react-datepicker.css";

const UltimoCalendar = () => {
  // Constants
  const [ultimoDates, setUltimoDates] = useState(null);
  const [currentDate, setCurrentDate] = useState(null);
  const [loading, setLoading] = useState(false);

  // Fetch and set data
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getData(`${getBaseUrl()}/ultimo/ultimo.json`);
        if (response.status === 200) {
          setUltimoDates(response.data);
          setCurrentDate(
            response.data.filter(
              (elem) => elem.date === new Date().toISOString().slice(0, 10)
            )[0]
          );
        }
      } catch (error) {
        console.error(`Error fetching ultimo.json: ${error}`);
      }
    }
    fetchData();
  }, []);

  // ICS download function
  const downloadICSFile = async () => {
    try {
      setLoading(true);
      const response = await getData(`${getBaseUrl()}/ultimo/ultimo.ics`);
      const blob = new Blob([response.data], { type: "text/calendar" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "UltimoDates.ics");
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
      setLoading(false);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  // Calendar contents
  const renderDayContents = (day, date) => {
    let currDate = new Date(date);
    let nextDate = new Date(currDate);
    nextDate.setDate(currDate.getDate() + 1);
    let currData = ultimoDates?.filter(
      (elem) => elem.date === nextDate.toISOString().slice(0, 10)
    )[0];

    return (
      <div>
        <div>{day}</div>
        <div className="ultimos">
          {currData?.ultimo_pos ? currData.ultimo_pos : <br />}
        </div>
        <div className="ultimos">
          {currData?.ultimo_neg && currData?.ultimo_neg !== " " ? (
            currData?.ultimo_neg
          ) : (
            <br />
          )}
        </div>
      </div>
    );
  };

  // Navbar normal date
  const NavbarDateText = () => {
    return (
      <p className="mb-0 mt-1 me-1">
        {`${new Date().getDate()} ${new Date().toLocaleString("default", {
          month: "long",
        })} ${new Date().getFullYear()}`}
      </p>
    );
  };

  // Navbar ultimo dates
  const NavbarUltimoText = () => {
    return (
      <div className="ultimoText">
        {/* Ultimo Information */}
        <div>
          {currentDate?.ultimo_pos !== null && currentDate?.ultimo_pos !== " "
            ? currentDate.ultimo_pos
            : "-"}
          {currentDate?.ultimo_pos &&
          currentDate?.ultimo_pos?.toLowerCase().includes("ultimo")
            ? ""
            : currentDate?.ultimo_neg !== null &&
              currentDate?.ultimo_neg !== " "
            ? ` / ${currentDate?.ultimo_neg}`
            : " / -"}
        </div>
        {/* Fiscal Period */}
        <div>{currentDate?.period ? currentDate?.period : ""}</div>
        {/* Fiscal Year */}
        <div>
          {currentDate?.fyear ? `FY${currentDate?.fyear.slice(-2)}` : ""}
        </div>
      </div>
    );
  };

  // Main return
  return (
    <div className="ultimoCalendar">
      {ultimoDates ? (
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <NavbarDateText />
            <div className="popupCalendar">
              <DatePicker
                showPopperArrow={false}
                selected={new Date()}
                showTimeSelect={false}
                popperPlacement="bottom-end"
                dateFormat="Pp"
                calendarStartDay={1}
                shouldCloseOnSelect={false}
                minDate={new Date(ultimoDates?.[0]?.date)}
                maxDate={new Date(ultimoDates?.[ultimoDates?.length - 1]?.date)}
                customInput={<FaCalendar />}
                renderDayContents={renderDayContents}>
                <div className="w-100 d-flex justify-content-center">
                  <Button
                    className="app-tertiary-btn"
                    disabled={loading}
                    onClick={downloadICSFile}>
                    {loading ? "Dowloading Ultimo..." : "Download Ultimo"}
                  </Button>
                </div>
              </DatePicker>
            </div>
          </div>
          <NavbarUltimoText />
        </div>
      ) : null}
    </div>
  );
};

export default UltimoCalendar;
